/* eslint-disable react/jsx-pascal-case */
import React, { Component } from "react";
import { connect } from "react-redux";
import { Control, Form } from "react-redux-form";
import { Col, Button } from "react-bootstrap";
import InputFields from "../InputFields";
import BootstrapTable from "react-bootstrap-table-next";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import "react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css";
import paginationFactory from "react-bootstrap-table2-paginator";
// import Select from "react-select";
// import { toTitleCase } from "./../utils/index";
import axios from "axios";

class GeneralSettings extends Component {
  state = {
    customLink: null,
  };

  generateCustomLink = () => {
    const { user, userActions } = this.props;
    let result;
    const options = {
      headers: { "x-access-token": `${user.token}` },
    };
    try {
      axios
        .post(
          "/admin/generatelink",
          {
            email: userActions?.custom_link?.email || "",
            deal_id: userActions?.custom_link?.deal_id,
          },
          options
        )
        .then(async (res) => {
          //Now do what you want with the response;
          if (res?.data?.link) {
            this.setState({
              customLink: res?.data?.link,
            });
          }
        });
    } catch (error) {
      console.log(error);
    }
    return result;
  };

  toggleButtons = (addOption, removeOption, editOption) => {
    this.setState({
      addOption: addOption,
      removeOption: removeOption,
      editOption: editOption,
    });
  };

  render() {
    const { getDeals, handleApproveUsers } = this.props;
    const { customLink } = this.state;
    const columns = [];

    const users = [];
    getDeals?.users?.map((x) =>
      users.push({ label: `${x?.full_name} (${x?.email})`, value: x?.id })
    );

    const pagination = paginationFactory({
      text: "10th",
      value: 10,
    });

    const approveButton = (cell, row, rowIndex, formatExtraData) => {
      const verified = row?.user_verified;
      return (
        <div
          style={{
            textAlign: "center",
            cursor: "pointer",
            lineHeight: "normal",
          }}
        >
          <Button
            className={!verified ? "adminOptionBtn" : "adminOptionDenyBtn"}
            onClick={() => handleApproveUsers(row?.id, row?.email, verified)}
          >
            {!verified ? "Approve" : "Deny"}
          </Button>
        </div>
      );
    };

    let userHeader = getDeals?.users?.[0];
    userHeader = {
      Approve: "Approve",
      ...userHeader,
    };

    for (const property in userHeader) {
      if (
        property !== "linked_document_categories" &&
        property !== "linked_sub_document_categories"
      ) {
        if (property === "Approve") {
          columns.push({
            dataField: property,
            text: property?.toUpperCase()?.replaceAll("_", " "),
            sort: true,
            formatter: approveButton,
            headerClasses: "tableHeader",
            headerStyle: (column, colIndex) => {
              return { width: "50px" };
            },
          });
        } else {
          columns.push({
            dataField: property,
            text: property?.toUpperCase()?.replaceAll("_", " "),
            sort: true,
            headerClasses: "tableHeader",
            headerStyle: (column, colIndex) => {
              return { width: "50px" };
            },
          });
        }
      }
    }

    return (
      <>
        {getDeals?.dealsOptions?.length ? (
          <Col lg={12} style={{ marginTop: "60px" }}>
            <div className="adminHeaderDiv">
              <h1 className="generalHeader">Generate custom link for deal</h1>
            </div>
            <h4 className="existingFilesHeader robotoFontBold">
              Generate link
            </h4>
            <Form model="userActions.custom_link">
              <Col lg={6} className="inputCol">
                <p className="selectLabel">Select a deal</p>
                <InputFields
                  label="Select a deal"
                  customLabel={true}
                  type="select"
                  inputType="select"
                  defaultOption="Select deal"
                  options={getDeals?.dealsOptions}
                  model=".deal_id"
                />
              </Col>
              {/* <Col lg={6} className="inputCol">
                <InputFields
                  label="email"
                  type="email"
                  inputType="text"
                  model=".email"
                  standardLabel={true}
                />
              </Col> */}
              <Col lg={12} className="inputCol" style={{ flexGrow: "1" }}>
                <Control.button
                  model="userActions.custom_link"
                  className="generalButton"
                  type="submit"
                  disabled={{ valid: false }}
                  onClick={() => this.generateCustomLink()}
                >
                  GENERATE LINK
                </Control.button>
              </Col>
              {customLink ? (
                <Col lg={12} className="inputCol" style={{ flexGrow: "1" }}>
                  <p className="robotoFontBold">
                    Shareable Link:
                    <br />
                    <a href={customLink} target="_blank" rel="noreferrer">
                      {customLink}
                    </a>
                  </p>
                </Col>
              ) : null}
            </Form>
          </Col>
        ) : null}
        <Col lg={12}>
          <div className="adminHeaderDiv">
            <h1 className="generalHeader">User Info</h1>
            {/* <Button className="generalButton adminHeaderDivButton">
              Save Changes
            </Button> */}
          </div>
          <span className="headerSpacer"></span>
          <div className="adminTable robotoFontRegular">
            {columns?.length && getDeals?.users?.length && (
              <BootstrapTable
                keyField="id"
                data={getDeals?.users}
                columns={columns}
                pagination={pagination}
              />
            )}
          </div>
        </Col>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  options: state.options,
  user: state.user,
  userActions: state.userActions,
  signup: state.signup,
  getDeals: state.getDeals,
});

export default connect(mapStateToProps)(GeneralSettings);
