import React, { Component } from "react";
import { connect } from "react-redux";
import { Row, Col } from "react-bootstrap";
import SignedInNavbar from "../Navbar/loggedInNavbar";
import DealCards from "../DealCards";
import Footer from "../Footer";
import "./style.css";
import QueryString from "query-string";
import Loading from "../Loading";

class Dashboard extends Component {
  componentDidMount() {
    const { dispatch } = this.props;
    dispatch({ type: "GET_DEALS" });
    const searchParams = QueryString.parse(this.props.location.search);
    const dealID = searchParams?.dsDealID || undefined;
    const dsComplete = searchParams?.complete || undefined;
    if (dsComplete) {
      dispatch({
        type: "GET_DEAL",
        payload: { dealID, dsComplete },
      });
    }
  }

  render() {
    const { getDeals, user, deal } = this.props;

    if (!user?.login) {
      window.location.href = "/";
    } else if (!user?.email_verified || !user?.user_verified) {
      window.location.href = "/thankyou";
    }

    if (getDeals?.isLoading || deal?.isLoading) {
      return <Loading />;
    }
    return (
      <React.Fragment>
        <SignedInNavbar />
        <div className="mainContainer">
          <div className="dashboardMain">
            <h4 className="dashboardIntroHeader robotoFontRegular">
              Hi {user?.full_name?.split(" ")[0]}, check out opportunities we
              have below.
            </h4>
            <Row className="dahsboardDealCardRow">
              {getDeals?.deals?.length ? (
                getDeals?.deals?.map((x, i) => (
                  <Col lg={4} key={i} className="dashboardDealCardCol">
                    <DealCards deal={x} />
                  </Col>
                ))
              ) : (
                <Col lg={12} className="dashboardDealCardCol">
                  <h1 className="dashboardNoDealsIntroHeader robotoFontRegular">
                    No deals available
                  </h1>
                  <div style={{ height: "30vh" }} />
                </Col>
              )}
            </Row>
          </div>
        </div>
        <div className="dashboardFooterMainDiv">
          <div className="dashboardFooterBorderDiv">
            <Footer dashboard={true} />
          </div>
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  options: state.options,
  user: state.user,
  getDeals: state.getDeals,
  deal: state.deal,
});

export default connect(mapStateToProps)(Dashboard);
