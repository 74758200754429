import React, { Component } from "react";
import { connect } from "react-redux";
import { actions } from "react-redux-form";
import { Row, Col, Button } from "react-bootstrap";
import SignedInNavbar from "../Navbar/loggedInNavbar";
import "react-dropzone-uploader/dist/styles.css";
import Dropzone from "react-dropzone-uploader";
import Loading from "../Loading";
import { EditorState, convertToRaw } from "draft-js";
import draftToHtml from "draftjs-to-html";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import "./style.css";
import GeneralSettings from "./general";
import CreateDeal from "./createDeal";
import EditDeal from "./editDeal";
import DeleteDeal from "./deleteDeal";
import AuditLog from "./auditLog";

class Admin extends Component {
  componentDidMount() {
    const { dispatch } = this.props;
    dispatch({ type: "GET_DEALS", payload: { getUsers: true } });
    dispatch({ type: "GET_AUDIT" });
  }

  state = {
    generalOption: false,
    createDealOption: false,
    editDealOption: false,
    deleteDealOption: false,
    auditLogOption: false,
    editorState: EditorState.createEmpty(),
    filesArray: [],
    images: [],
    removedFiles: [],
  };

  toggleButtons(approveUsers, createDeal, editDeal, deleteDeal, auditLog) {
    this.setState({
      generalOption: approveUsers,
      createDealOption: createDeal,
      editDealOption: editDeal,
      deleteDealOption: deleteDeal,
      auditLogOption: auditLog,
    });
  }

  handleApproveUsers(id, email, verified) {
    this.props.dispatch({
      type: `${!verified ? "APPROVE_USER" : "DENY_USER"}`,
      payload: { id, email, verified },
    });
    this.setState({ generalOption: false });
  }

  handleCreateDeal() {
    this.props.dispatch({
      type: "CREATE_DEAL",
    });
    this.setState({ createDealOption: false });
  }

  handleEditDeal() {
    this.props.dispatch({
      type: "EDIT_DEAL",
    });
    this.setState({ editDealOption: false });
  }

  handleDeleteDeal() {
    this.props.dispatch({
      type: "DELETE_DEAL",
    });
    this.setState({ deleteDealOption: false });
  }

  handleNotes(auditNotes) {
    this.props.dispatch({
      type: "HANDLE_AUDIT_NOTES",
      payload: { auditNotes },
    });
  }

  onCreatorStateChange: Function = (editorState) => {
    this.setState({
      editorState,
    });
    this.props.dispatch(
      actions.change(
        "createDeal.summary",
        draftToHtml(convertToRaw(editorState.getCurrentContent()))
      )
    );
  };

  onEditorStateChange: Function = (editorState) => {
    this.setState({
      editorState,
    });
    this.props.dispatch(
      actions.change(
        "editDeal.summary",
        draftToHtml(convertToRaw(editorState.getCurrentContent()))
      )
    );
  };

  addImages(file) {
    const { createDealOption } = this.state;
    const model = createDealOption ? "createDeal" : "editDeal";
    let images = [...this.state.images];
    images.push(file);
    this.setState({ images });
    this.props.dispatch(actions.change(`${model}.images`, this.state.images));
  }

  removeImage(fileName) {
    const { createDealOption } = this.state;
    const model = createDealOption ? "createDeal" : "editDeal";
    const { createDeal, dispatch } = this.props;
    let images = createDeal?.images;
    images = images?.filter((x) => x.name !== fileName);
    this.setState({ images });
    dispatch(actions.change(`${model}.images`, images));
  }

  addFiles(file, type, subType) {
    const { dispatch, options, editDeal } = this.props;
    const { createDealOption } = this.state;

    const model = createDealOption ? "createDeal" : "editDeal";
    if (!subType || !subType?.length) {
      const existingDocType = options?.document_types?.find(
        (d) =>
          !createDealOption &&
          d?.label?.toUpperCase() === type?.toUpperCase() &&
          d?.deal_id === editDeal?.id
      );
      if (existingDocType) {
        file.existing = true;
        file.id = existingDocType?.value;
      }
      let files = this.state[type] ? [...this.state[type]] : [];
      files.push(file);
      dispatch(actions.change(`${model}.files.main.${type}`, files));
      this.setState({ [type]: files });
    } else {
      // eslint-disable-next-line array-callback-return
      subType?.map((s) => {
        const existingDocType = options?.document_types?.find(
          (d) =>
            !createDealOption &&
            d?.label?.toUpperCase() === s?.label?.toUpperCase() &&
            d?.deal_id === editDeal?.id
        );
        if (existingDocType) {
          file.existing = true;
          file.id = existingDocType?.value;
        }
        const subTypeKey = existingDocType ? s?.label : `${type}/${s?.value}`;
        let files = this.state[subTypeKey] ? [...this.state[subTypeKey]] : [];
        file.sub = true;
        file.main = type;
        files.push(file);
        dispatch(actions.change(`${model}.files.sub.${subTypeKey}`, files));
        this.setState({ [subTypeKey]: files });
      });
    }
  }

  removeFile(fileName, type, subDocumentId) {
    const { createDealOption } = this.state;
    const model = createDealOption ? "createDeal" : "editDeal";
    const { createDeal, dispatch } = this.props;
    let files;
    files = createDeal?.files[type];
    files = files?.filter((x) => x.name !== fileName);
    this.setState({ [type]: files });
    dispatch(actions.change(`${model}.files.${type}`, files));
  }

  removeExistingImage(fileName) {
    const { editDeal, dispatch } = this.props;
    const { removedFiles } = this.state;
    let files;
    files = [...removedFiles];
    files.push(fileName);
    this.setState({ removedFiles: files });
    dispatch(actions.change(`editDeal.removed_files_url`, files));
    let parseUrl = editDeal?.images_url.split(",");
    if (parseUrl?.length > 1) {
      const index = parseUrl.indexOf(fileName);
      parseUrl.splice(index, 1);
      parseUrl = parseUrl.join(",");
    } else {
      parseUrl = null;
    }
    dispatch(actions.change(`editDeal.images_url`, parseUrl));
  }

  removeExistingFile(fileName, type) {
    const { editDeal, dispatch } = this.props;
    const { removedFiles } = this.state;
    let files;
    files = [...removedFiles];
    files.push(fileName);
    this.setState({ removedFiles: files });
    dispatch(actions.change(`editDeal.removed_files_url`, files));
    const documents = editDeal?.documents?.filter(
      (d) => d?.id !== fileName?.id
    );
    dispatch(actions.change(`editDeal.documents`, documents));
  }

  handleMultiSelectChange = (selectedOption, event, type) => {
    const { dispatch } = this.props;
    const selectedValues = [];
    selectedOption?.map((s) => selectedValues?.push(s.value));

    if (event?.action?.toUpperCase() === "REMOVE-VALUE") {
      dispatch(
        actions.change(
          `${[type]}.${[event?.name]}`,
          selectedValues?.length ? selectedValues?.join(",") : null
        )
      );
    } else if (event?.action?.toUpperCase() === "SELECT-OPTION") {
      dispatch(
        actions.change(`${[type]}.${[event?.name]}`, selectedValues?.join(","))
      );
    } else if (event?.action?.toUpperCase() === "CREATE-OPTION") {
      // dispatch(
      //   actions.change(`${[type]}.${[event?.name]}`, selectedValues?.join(","))
      // );
    } else if (event?.action?.toUpperCase() === "CLEAR") {
      dispatch(actions.change(`${[type]}.${[event?.name]}`, null));
    }
  };

  render() {
    const {
      createDealOption,
      editDealOption,
      deleteDealOption,
      generalOption,
      auditLogOption,
    } = this.state;
    const {
      user,
      userActions,
      approveUser,
      getDeals,
      createDeal,
      editDeal,
      deleteDeal,
      auditLog,
    } = this.props;

    if (!user?.admin) {
      window.location.href = "/dashboard";
    }

    const ImageUploader = () => {
      const handleChangeStatus = ({ file, meta }, status) => {
        if (status === "done") {
          this.addImages(file);
        } else if (status === "removed") {
          this.removeImage(file?.name);
        }
      };
      return (
        <Dropzone
          onChangeStatus={handleChangeStatus}
          accept="image/*"
          maxFiles={3}
          maxSizeBytes="3145728"
        />
      );
    };

    const DocumentUploader = (type, subType) => {
      const handleChangeStatus = ({ file, meta }, status) => {
        if (status === "done") {
          this.addFiles(file, type, subType);
        } else if (status === "removed") {
          this.removeFile(file?.name, type, subType);
        }
      };
      return (
        <Dropzone
          onChangeStatus={handleChangeStatus}
          accept="*"
          PreviewComponent={null}
        />
      );
    };

    if (
      userActions?.isLoading ||
      approveUser?.isLoading ||
      createDeal?.isLoading ||
      editDeal?.isLoading ||
      deleteDeal?.isLoading ||
      getDeals?.isLoading ||
      auditLog?.isLoading
    ) {
      return <Loading />;
    }

    let visitorOriginalArr = [];

    return (
      <React.Fragment>
        <SignedInNavbar />
        <div className="mainContainer mainContainerAdmin">
          <div className="adminOptionsDiv">
            <h1 className="generalHeader">Choose an option</h1>
            <div className="adminOptionsBtnDiv">
              <Button
                className={`adminOptionBtn ${generalOption && "activeButton"}`}
                onClick={() =>
                  this.toggleButtons(true, false, false, false, false)
                }
              >
                General
              </Button>
              <Button
                className={`adminOptionBtn ${
                  createDealOption && "activeButton"
                }`}
                onClick={() =>
                  this.toggleButtons(false, true, false, false, false)
                }
              >
                Create Deal
              </Button>
              <Button
                className={`adminOptionBtn ${editDealOption && "activeButton"}`}
                onClick={() =>
                  this.toggleButtons(false, false, true, false, false)
                }
              >
                Edit Deal
              </Button>
              <Button
                className={`adminOptionBtn ${
                  deleteDealOption && "activeButton"
                }`}
                onClick={() =>
                  this.toggleButtons(false, false, false, true, false)
                }
              >
                Delete Deal
              </Button>
              <Button
                className={`adminOptionBtn ${auditLogOption && "activeButton"}`}
                onClick={() =>
                  this.toggleButtons(false, false, false, false, true)
                }
              >
                Audit Logs
              </Button>
            </div>
          </div>
          <Row className="adminRow">
            <Col lg={12}>
              {generalOption && (
                <GeneralSettings
                  handleApproveUsers={(id, email, verified) =>
                    this.handleApproveUsers(id, email, verified)
                  }
                />
              )}
              {createDealOption && (
                <CreateDeal
                  handleCreateDeal={() => this.handleCreateDeal()}
                  ImageUploader={ImageUploader}
                  DocumentUploader={DocumentUploader}
                  removeImage={(v) => this.removeImage(v)}
                  removeFile={(v, t) => this.removeFile(v, t)}
                  handleMultiSelectChange={this.handleMultiSelectChange}
                />
              )}
              {editDealOption && (
                <EditDeal
                  handleEditDeal={() => this.handleEditDeal()}
                  addImages={(v) => this.addImages(v)}
                  removeImage={(v) => this.removeImage(v)}
                  addFiles={(v) => this.addFiles(v)}
                  removeFile={(v, t) => this.removeFile(v, t)}
                  ImageUploader={ImageUploader}
                  DocumentUploader={DocumentUploader}
                  removeExistingImage={(v) => this.removeExistingImage(v)}
                  removeExistingFile={(v, t) => this.removeExistingFile(v, t)}
                  handleMultiSelectChange={this.handleMultiSelectChange}
                />
              )}
              {deleteDealOption && (
                <DeleteDeal handleDeleteDeal={() => this.handleDeleteDeal()} />
              )}
              {auditLogOption && (
                <AuditLog
                  handleNotes={(v) => this.handleNotes(v)}
                  visitorOriginalArr={visitorOriginalArr}
                />
              )}
            </Col>
          </Row>
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  options: state.options,
  user: state.user,
  userActions: state.userActions,
  signup: state.signup,
  approveUser: state.approveUser,
  getDeals: state.getDeals,
  createDeal: state.createDeal,
  editDeal: state.editDeal,
  deleteDeal: state.deleteDeal,
  auditLog: state.auditLog,
});

export default connect(mapStateToProps)(Admin);
