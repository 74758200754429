import React, { Component } from "react";
import { connect } from "react-redux";
import { actions } from "react-redux-form";
import { Row, Col, Button } from "react-bootstrap";
import SignedInNavbar from "../Navbar/loggedInNavbar";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import NdaForm from "./ndaForm";
import Footer from "../Footer";
import Loading from "../Loading";
import logo from "../../global/img/logo.png";
// import ndaLogo from "../../global/img/ndaLogo.png";
// import Paperclip from "./img/paperclip.png";
import "./style.css";
import { formatCurrency } from "../utils";
import { AWS_S3_URL } from "../config";
import parse from "html-react-parser";
import { toTitleCase, formatDate } from "../utils";
import QueryString from "query-string";
import { orderBy } from "lodash";
import JSZipUtils from "jszip-utils";
import JSZip from "jszip";
import { saveAs } from "file-saver";

// import jsPDF from "jspdf";
import pdfMake from "pdfmake";
// import pdfFonts from "pdfmake/build/vfs_fonts";
import htmlToPdfmake from "html-to-pdfmake";

class Deal extends Component {
  componentDidMount() {
    const { dispatch, user, customLink } = this.props;
    const searchParams = QueryString.parse(this.props.location.search);
    if (searchParams?.custom_token) {
      const url = window.location.href?.replace(
        `?custom_token=${searchParams?.custom_token}`,
        ""
      );
      dispatch(actions.change("customLink.custom_url", url));
      dispatch(actions.change("customLink.token", searchParams?.custom_token));
      dispatch(actions.change("customLink.custom_link", true));
    }
    if (user?.login) {
      const url = window.location.href;
      if (customLink?.custom_url?.toUpperCase() === url?.toUpperCase()) {
        dispatch(actions.change("customLink", {}));
      }
    }
    const dealID = this?.props?.match?.params?.dealID;
    dispatch({
      type: "GET_DEAL",
      payload: { dealID, dsToken: null, dsComplete: null },
    });
  }

  printDocument() {
    const { user, deal } = this.props;
    //const input = document.getElementById('divToPrint');
    // const doc = new jsPDF();
    //get html
    const pdfTable = document.getElementById("divToPrint");
    //html to pdf format
    var html = htmlToPdfmake(pdfTable.innerHTML);

    const ndaSigned = user?.nda_documents?.find((d) => d?.deal_id === deal?.id);

    pdfMake.fonts = {
      // download default Roboto font from cdnjs.com
      Roboto: {
        normal:
          "https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Regular.ttf",
        bold: "https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Medium.ttf",
        italics:
          "https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Italic.ttf",
        bolditalics:
          "https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-MediumItalic.ttf",
      },
    };

    // pdfMake.fonts = {
    //   Courier: {
    //     normal: "Courier",
    //     bold: "Courier-Bold",
    //     italics: "Courier-Oblique",
    //     bolditalics: "Courier-BoldOblique",
    //   },
    //   Helvetica: {
    //     normal: "Helvetica",
    //     bold: "Helvetica-Bold",
    //     italics: "Helvetica-Oblique",
    //     bolditalics: "Helvetica-BoldOblique",
    //   },
    //   Times: {
    //     normal: "Times-Roman",
    //     bold: "Times-Bold",
    //     italics: "Times-Italic",
    //     bolditalics: "Times-BoldItalic",
    //   },
    //   Symbol: {
    //     normal: "Symbol",
    //   },
    //   ZapfDingbats: {
    //     normal: "ZapfDingbats",
    //   },
    // };

    const documentDefinition = {
      content: html,
      info: {
        title: `nda_${ndaSigned?.first_name
          ?.toLowerCase()
          ?.replaceAll(" ", "_")}_${ndaSigned?.last_name
          ?.toLowerCase()
          ?.replaceAll(" ", "_")}_${ndaSigned?.email
          ?.toLowerCase()
          ?.replaceAll(".", "_")
          ?.replaceAll("@", "_")}`,
      },
      defaultStyle: {
        font: "Roboto",
      },
    };
    pdfMake.createPdf(documentDefinition).open();
  }

  handleNdaSubmit() {
    //const input = document.getElementById('divToPrint');
    // const doc = new jsPDF();
    const { dispatch, user, deal } = this.props;
    const ndaSigned = user?.nda_documents?.find((d) => d?.deal_id === deal?.id);

    //get html
    const pdfTable = document.getElementById("divToPrint");
    //html to pdf format
    var html = htmlToPdfmake(pdfTable.innerHTML);

    const documentDefinition = { content: html };
    // pdfMake.vfs = pdfFonts.pdfMake.vfs;

    pdfMake.fonts = {
      // download default Roboto font from cdnjs.com
      Roboto: {
        normal:
          "https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Regular.ttf",
        bold: "https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Medium.ttf",
        italics:
          "https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Italic.ttf",
        bolditalics:
          "https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-MediumItalic.ttf",
      },
    };

    const ndaDoc = pdfMake.createPdf(documentDefinition);
    ndaDoc.getBlob((blob) => {
      dispatch(actions.change("userActions.nda_agreement.nda_file", blob));
      dispatch({
        type: "HANDLE_NDA",
        payload: {
          deal,
          signed: true,
          ndaSigned,
        },
      });
    });
  }

  formatPhoneNumber = (phoneNumberString) => {
    var cleaned = ("" + phoneNumberString).replace(/\D/g, "");
    var match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
    if (match) {
      var intlCode = match[1] ? "+1 " : "";
      return [intlCode, "(", match[2], ") ", match[3], "-", match[4]].join("");
    }
    return null;
  };

  render() {
    const { user, userActions, options, deal, dispatch } = this.props;

    if (deal?.isLoading || userActions?.isLoading) {
      // if (deal?.isLoading || !deal?.id || userActions?.isLoading) {
      return <Loading />;
    } else if (!deal?.id) {
      return (
        <>
          <SignedInNavbar />
          <div className="mainContainer">
            <div className="dealMain">
              <a
                href="/dashboard"
                title="Back"
                className="dashboardBackButton robotoFontRegular hoverDelay"
              >
                Back
              </a>
              <h1 className="dealErrorHeader robotoFontBold">{deal?.error}</h1>
              <Button
                className="generalButtonInverted"
                href="/"
                style={{ display: "block", margin: "60px auto 0 auto" }}
              >
                Home
              </Button>
            </div>
          </div>
        </>
      );
    }

    const images = deal?.images_url?.split(",");
    let documents = deal?.documents?.filter((d) => d?.deal_id === deal?.id);
    const documentTypes = options?.document_types?.filter(
      (d) => d?.deal_id === deal?.id
    );

    let mainDocumentCategories = [];
    documentTypes?.map(
      (d) =>
        documents?.find(
          (x) =>
            x.document_type_id === d?.value ||
            x.sub_document_type_id === d?.value ||
            d?.is_public
        ) &&
        mainDocumentCategories.push({
          name: toTitleCase(d?.label),
          id: d?.value,
          type: d?.type,
          main_group_id: d?.main_group_id,
        })
    );

    const folderName = `project_name_${deal?.name?.toLowerCase()}`;

    const ndaSigned = user?.nda_documents?.find(
      (d) => d?.deal_id === deal?.id && d?.user_id === user?.id
    );

    const getFileName = (fileName) => {
      let splitName = fileName?.split("/");
      return splitName[splitName?.length - 1]?.replaceAll("+", " ");
    };

    mainDocumentCategories = orderBy(mainDocumentCategories, ["name"], ["asc"]);
    documents = orderBy(documents, ["name"], ["asc"]);

    const documentUrls = [];
    const documentIDs = [];

    documents?.map((x) =>
      documentUrls.push(`${AWS_S3_URL}/${folderName}/${x?.name}`)
    );
    documents?.map((x) =>
      documentIDs.push(
        !x?.sub_document_type_id ? x?.document_type_id : x?.sub_document_type_id
      )
    );

    const DownloadAllFiles = () => {
      const zip = new JSZip();
      let count = 0;
      let zipFilename = deal?.name?.replaceAll(/[^a-zA-Z0-9 ]/g, "") + ".zip";
      zipFilename = zipFilename?.toLowerCase()?.replaceAll(" ", "_");
      const fileAudits = [];

      // add the links to your media/text/csv/other files
      const downloadAll = () =>
        documentUrls.forEach(async function (url, index) {
          let filename = decodeURIComponent(url);
          filename = filename?.split("/");
          filename = filename[filename?.length - 1];
          // loading a file and add it in a zip file
          JSZipUtils.getBinaryContent(url, async function (err, data) {
            if (err) {
              throw err; // or handle the error
            }
            fileAudits.push({
              user_action: `DOWNLOAD FILE`,
              description: `${decodeURIComponent(
                folderName
              )}/${decodeURIComponent(filename)}`,
              deal_id: deal?.id,
              document_id: documentIDs[index],
            });
            zip.file(filename, data, { binary: true });
            count++;
            if (count === documentUrls.length) {
              dispatch({
                type: "AUDIT",
                payload: {
                  user_action: `DOWNLOAD FILE`,
                  description: `${decodeURIComponent(
                    folderName
                  )}/${decodeURIComponent(filename)}`,
                  deal_id: deal?.id,
                  document_id: documentIDs[index],
                  fileAudits,
                },
              });
              const zipFile = await zip.generateAsync({ type: "blob" });
              saveAs(zipFile, zipFilename);
            }
          });
        });
      return (
        <Button
          onClick={downloadAll}
          className="downloadAllButton generalButton"
        >
          Download all files
        </Button>
      );
    };

    return (
      <React.Fragment>
        <SignedInNavbar />
        <div className="mainContainer">
          <div className="dealMain">
            <a
              href="/dashboard"
              title="Back"
              className="dashboardBackButton robotoFontRegular hoverDelay"
            >
              Back
            </a>
            {deal?.nda_required && !ndaSigned?.id && user?.login ? (
              <>
                <p className="ndaHeader robotoFontBold">
                  CONFIDENTIALITY AGREEMENT: To view this deal, please complete
                  the required NDA agreement
                  <br />
                  Please verify your information below, make any necessary
                  corrections, and click continue.
                </p>
                <p className="requiredText robotoFontBold">
                  <span style={{ color: "red" }}>*</span> Required
                </p>
                <NdaForm deal={deal} />
              </>
            ) : null}
            {deal?.nda_required &&
            ndaSigned?.id &&
            !ndaSigned?.is_signed &&
            user?.login ? (
              <>
                <h1 className="ndaHeader robotoFontBold">
                  To view this deal, please accept the required NDA agreement.
                </h1>
                <br />
                <Row id="divToPrint" className="dealRow">
                  <Col lg={12}>
                    <div className="dealSummaryDiv">
                      <div className="dealInfoText robotoFontRegular">
                        <div style={{ textAlign: "center" }}>
                          <img
                            src={logo}
                            // src={ndaLogo}
                            alt="Logo"
                            className=""
                            style={{
                              display: "inline-block",
                              width: "250px",
                            }}
                          />
                        </div>
                        <br />
                        <br />
                        {deal?.nda_agreement?.length
                          ? parse(
                              deal?.nda_agreement?.replaceAll(
                                "Times New Roman",
                                ""
                              )
                            )
                          : "NDA not available."}
                      </div>
                      <p>
                        <b>AGREED AND ACCEPTED BY:</b>
                      </p>
                      <div style={{ paddingBottom: "30px" }}>
                        <p>
                          <b>Date: </b>
                          {/* {todaysDate?.slice(0, todaysDate.length - 7)} */}
                          {/* {todaysDate?.slice(0, todaysDate.length - 7)} */}
                          {formatDate(true, new Date())}
                          <br />
                          <b>Buyer Company: </b>
                          {ndaSigned?.company}
                          <br />
                          <b>Buyer Name: </b>
                          {ndaSigned?.first_name} {ndaSigned?.last_name}
                          <br />
                          <b>City/State: </b>
                          {ndaSigned?.city}, {ndaSigned?.state}
                          <br />
                          <b>Phone: </b>
                          {this.formatPhoneNumber(ndaSigned?.phone)}
                          <br />
                          <b>Email: </b>
                          {ndaSigned?.email}
                        </p>
                      </div>
                    </div>
                  </Col>
                </Row>
                <div>
                  <Button
                    className="generalNDAButton"
                    type="submit"
                    onClick={() => this.handleNdaSubmit()}
                  >
                    I accept the confidentiality agreement
                  </Button>
                  <Button
                    className="generalNDAButton"
                    type="submit"
                    href="/dashboard"
                  >
                    I do not accept the confidentiality agreement
                  </Button>
                </div>
                <Button
                  className="generalNDAButtonInverted"
                  type="submit"
                  onClick={() => this.printDocument()}
                  style={{ marginTop: "30px" }}
                >
                  Click here for printable copy
                </Button>
              </>
            ) : null}
            {!deal?.nda_required || ndaSigned?.is_signed || !user?.login ? (
              <>
                <Row className="dealRow">
                  <Col lg={8}>
                    {/* {images?.length ? ( */}
                    <div className="dealImgDiv">
                      <Carousel
                        additionalTransfrom={0}
                        arrows={false}
                        autoPlay={true}
                        autoPlaySpeed={5000}
                        centerMode={false}
                        className=""
                        containerClass="carousel-container"
                        dotListClass=""
                        draggable
                        focusOnSelect={false}
                        infinite={images?.length || false}
                        itemClass=""
                        keyBoardControl
                        minimumTouchDrag={80}
                        renderButtonGroupOutside={false}
                        renderDotsOutside={false}
                        responsive={{
                          largeDesktop: {
                            breakpoint: {
                              max: 3000,
                              min: 1024,
                            },
                            items: 1,
                            // partialVisibilityGutter: 40,
                          },
                          desktop: {
                            breakpoint: {
                              max: 1900,
                              min: 900,
                            },
                            items: 1,
                            // partialVisibilityGutter: 200,
                          },
                          mobile: {
                            breakpoint: {
                              max: 464,
                              min: 0,
                            },
                            items: 1,
                            // partialVisibilityGutter: 30,
                          },
                          tablet: {
                            breakpoint: {
                              max: 1024,
                              min: 464,
                            },
                            items: 1,
                            // partialVisibilityGutter: 30,
                          },
                        }}
                        showDots={true}
                        sliderClass=""
                        slidesToSlide={1}
                        swipeable
                      >
                        {images?.length ? (
                          images?.map((x, i) => (
                            <div key={i}>
                              <img
                                alt="Deal"
                                key={i}
                                src={`${AWS_S3_URL}/${folderName}/${images[i]}`}
                                style={{ height: "auto", width: "100%" }}
                                // style={{ height: "100%", width: "auto" }}
                              />
                            </div>
                          ))
                        ) : (
                          <img
                            alt="Deal"
                            src={logo}
                            style={{
                              width: "100%",
                              height: "auto",
                              padding: "30px",
                            }}
                          />
                        )}
                        {/* {images?.length ? (
                          images?.map((x, i) => (
                            <div
                              key={i}
                              className="dealImgSubDiv"
                              style={{
                                background: `url('${AWS_S3_URL}/${folderName}/${images[i]}')`,
                                backgroundSize: "cover",
                              }}
                            ></div>
                          ))
                        ) : (
                          <div
                            className="dealImgSubDiv"
                            style={{
                              background: `url('${logo}')`,
                            }}
                          ></div>
                        )} */}
                      </Carousel>
                    </div>
                    {/* ) : null} */}
                    <div className="dealSummaryDiv">
                      <h1 className="dealHeader robotoFontBold">Summary</h1>
                      <div className="dealInfoText robotoFontRegular">
                        {deal?.summary?.length
                          ? parse(deal?.summary)
                          : "Summary not available."}
                      </div>
                    </div>
                  </Col>
                  <Col lg={4} className="dealCol2">
                    <div className="dealInfoDiv">
                      <h3 className="dealCardHeader robotoFontBold">
                        {deal?.name}
                      </h3>
                      <div className="dealCardInfoDiv">
                        <Row className="dealCardInfoRow">
                          <Col lg={12} className="dealCardInfoCol">
                            <span className="dealCardInfoText dealCardInfoTextLeft robotoFontRegular">
                              Location
                            </span>
                            <span className="dealCardInfoText dealCardInfoTextRight">
                              {toTitleCase(deal.city)}, {deal?.state}
                            </span>
                          </Col>
                        </Row>
                        <Row className="dealCardInfoRow">
                          <Col lg={12} className="dealCardInfoCol">
                            <span className="dealCardInfoText dealCardInfoTextLeft robotoFontRegular">
                              List Price
                            </span>
                            <span className="dealCardInfoText dealCardInfoTextRight">
                              {deal?.list_price
                                ? formatCurrency(deal?.list_price)
                                : "Unpriced"}
                            </span>
                          </Col>
                        </Row>
                        <Row className="dealCardInfoRow">
                          <Col lg={12} className="dealCardInfoCol">
                            <span className="dealCardInfoText dealCardInfoTextLeft robotoFontRegular">
                              Property GLA
                            </span>
                            <span className="dealCardInfoText dealCardInfoTextRight">
                              {deal?.property_gla}
                            </span>
                          </Col>
                        </Row>
                        {deal?.agents?.length ? (
                          <>
                            <h3 className="dealCardHeader robotoFontBold">
                              Listing Agents
                            </h3>
                            <br />
                            {deal?.agents?.map((d, i) => (
                              <div
                                key={i}
                                style={{ overflowWrap: "break-word" }}
                              >
                                {d?.name ? (
                                  <p className="dealAgentListing">
                                    <b>Name: </b>
                                    {d?.name}
                                  </p>
                                ) : null}
                                {d?.name ? (
                                  <p className="dealAgentListing">
                                    <b>Title: </b>
                                    {d?.title}
                                  </p>
                                ) : null}
                                {d?.email ? (
                                  <p className="dealAgentListing">
                                    <b>Email: </b>
                                    <a
                                      className="dealAgentListingEmail"
                                      href={`mailto:${d?.email}`}
                                    >
                                      {d?.email}
                                    </a>
                                  </p>
                                ) : null}
                                {d?.phone ? (
                                  <p className="dealAgentListing">
                                    <b>Phone: </b>
                                    {this.formatPhoneNumber(d?.phone)}
                                  </p>
                                ) : null}
                                {d?.license ? (
                                  <p className="dealAgentListing">
                                    <b>License #: </b>
                                    {d?.license}
                                  </p>
                                ) : null}
                                <hr />
                              </div>
                            ))}
                          </>
                        ) : null}
                        {ndaSigned?.is_signed ? (
                          <>
                            {documentUrls?.length ? <DownloadAllFiles /> : null}
                            {mainDocumentCategories?.map((d, i) => (
                              <div key={i}>
                                {d?.type?.toUpperCase() === "MAIN" ? (
                                  <Row key={i}>
                                    <Col lg={12} className="dealDocumentCol">
                                      <p className="dealDocumentHeader robotoFontBold">
                                        {d?.name?.toUpperCase()} DOCUMENTS
                                      </p>
                                      {documents?.map((x, i) => {
                                        return (
                                          d?.id === x?.document_type_id &&
                                          !x?.sub_document_type_id && (
                                            <div key={i}>
                                              <Row className="dealDocumentRow">
                                                <Col
                                                  lg={12}
                                                  className="dealDocumentCol"
                                                >
                                                  <span className="dealCardInfoText dealCardInfoTextLeft robotoFontRegular">
                                                    {getFileName(
                                                      decodeURIComponent(
                                                        x?.name
                                                      )
                                                    )}{" "}
                                                    {/* <img
                                            src={Paperclip}
                                            alt="Paperclip"
                                            className="paperclip"
                                          /> */}
                                                  </span>
                                                  <a
                                                    href={`${AWS_S3_URL}/${folderName}/${x?.name}`}
                                                    className="dealCardInfoText dealCardInfoTextRight dealDocumentLink robotoFontBold hoverDelay"
                                                    onClick={() =>
                                                      dispatch({
                                                        type: "AUDIT",
                                                        payload: {
                                                          user_action: `DOWNLOAD FILE`,
                                                          description: `${decodeURIComponent(
                                                            folderName
                                                          )}/${decodeURIComponent(
                                                            x?.name
                                                          )}`,
                                                          deal_id: deal?.id,
                                                          document_id: d?.id,
                                                        },
                                                      })
                                                    }
                                                  >
                                                    Download
                                                  </a>
                                                </Col>
                                              </Row>
                                            </div>
                                          )
                                        );
                                      })}
                                    </Col>
                                  </Row>
                                ) : (
                                  <Row key={i} style={{ marginLeft: "25px" }}>
                                    <Col lg={12} className="dealDocumentCol">
                                      <p className="dealDocumentHeader robotoFontBold">
                                        {d?.name
                                          ?.toUpperCase()
                                          ?.split("/")
                                          ?.splice(1, 1)}
                                      </p>
                                      {documents?.map((x, i) => {
                                        return (
                                          d?.main_group_id ===
                                            x?.document_type_id &&
                                          x?.sub_document_type_id === d?.id && (
                                            <div key={i}>
                                              <Row className="dealDocumentRow">
                                                <Col
                                                  lg={12}
                                                  className="dealDocumentCol"
                                                >
                                                  <span className="dealCardInfoText dealCardInfoTextLeft robotoFontRegular">
                                                    {getFileName(
                                                      decodeURIComponent(
                                                        x?.name
                                                      )
                                                    )}{" "}
                                                    {/* <img
                                            src={Paperclip}
                                            alt="Paperclip"
                                            className="paperclip"
                                          /> */}
                                                  </span>
                                                  <a
                                                    href={`${AWS_S3_URL}/${folderName}/${x?.name}`}
                                                    className="dealCardInfoText dealCardInfoTextRight dealDocumentLink robotoFontBold hoverDelay"
                                                    onClick={() =>
                                                      dispatch({
                                                        type: "AUDIT",
                                                        payload: {
                                                          user_action: `DOWNLOAD FILE`,
                                                          description: `${decodeURIComponent(
                                                            folderName
                                                          )}/${decodeURIComponent(
                                                            x?.name
                                                          )}`,
                                                          deal_id: deal?.id,
                                                          document_id: d?.id,
                                                        },
                                                      })
                                                    }
                                                  >
                                                    Download
                                                  </a>
                                                </Col>
                                              </Row>
                                            </div>
                                          )
                                        );
                                      })}
                                    </Col>
                                  </Row>
                                )}
                              </div>
                            ))}{" "}
                          </>
                        ) : (
                          <Row>
                            <Col lg={12} className="dealDocumentCol">
                              <p className="dealDocumentHeader robotoFontBold">
                                DOCUMENTS
                              </p>
                              <br />
                              <Button
                                className="generalButtonInverted"
                                type="submit"
                                href="/"
                                style={{ width: "100%" }}
                              >
                                PLEASE LOGIN TO VIEW
                              </Button>
                            </Col>
                          </Row>
                        )}
                      </div>
                    </div>
                  </Col>
                </Row>
              </>
            ) : null}
          </div>
        </div>
        <div className="dashboardFooterMainDiv">
          <div className="dashboardFooterBorderDiv">
            <Footer dashboard={true} />
          </div>
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  options: state.options,
  user: state.user,
  userActions: state.userActions,
  signup: state.signup,
  deal: state.deal,
  customLink: state.customLink,
});

export default connect(mapStateToProps)(Deal);
