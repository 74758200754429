export const user = (state) => state.user;
export const options = (state) => state.options;
export const ipAddr = (state) => state.ipAddr;
export const signup = (state) => state.signup;
export const deals = (state) => state.deals;
export const userActions = (state) => state.userActions;
export const passwordReset = (state) => state.passwordReset;
export const deal = (state) => state.deal;
export const createDeal = (state) => state.createDeal;
export const editDeal = (state) => state.editDeal;
export const deleteDeal = (state) => state.deleteDeal;
export const getDeals = (state) => state.getDeals;
export const customLink = (state) => state.customLink;
