/* eslint-disable react/jsx-pascal-case */
import React, { Component } from "react";
import { connect } from "react-redux";
import { Control, Form, actions } from "react-redux-form";
import { Col, Button } from "react-bootstrap";
import InputFields from "../InputFields";
import { toTitleCase } from "../utils";
import "react-dropzone-uploader/dist/styles.css";
import { Editor } from "react-draft-wysiwyg";
import { EditorState, convertToRaw } from "draft-js";
import draftToHtml from "draftjs-to-html";
import Select from "react-select";
import CreatableSelect from "react-select/creatable";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import "./style.css";

class CreateDeal extends Component {
  state = {
    editorState: EditorState.createEmpty(),
    ndaEditorState: EditorState.createEmpty(),
    documentName: null,
    subDocumentName: [],
    agentCount: 0,
    agentList: [],
    addMoreAgents: false,
  };

  onCreatorStateChange: Function = (editorState) => {
    this.setState({
      editorState,
    });
    this.props.dispatch(
      actions.change(
        "createDeal.summary",
        draftToHtml(convertToRaw(editorState.getCurrentContent()))
      )
    );
  };

  onNdaCreatorStateChange: Function = (ndaEditorState) => {
    this.setState({
      ndaEditorState,
    });
    this.props.dispatch(
      actions.change(
        "createDeal.nda_agreement",
        draftToHtml(convertToRaw(ndaEditorState.getCurrentContent()))
      )
    );
  };

  handleFileType(name, subDoc) {
    if (!subDoc) {
      this.setState({
        documentName: name,
      });
    } else {
      this.setState({
        subDocumentName: name,
      });
    }
  }

  removeAgent = (id) => {
    const { agentList } = this.state;
    const { dispatch } = this.props;

    let agentArr = [...agentList];
    agentArr.splice(id, 1);
    this.setState({ agentList: agentArr });
    dispatch(actions.change(`createDeal.agents`, agentArr));
  };

  handleDocumentGroupChange = (newValue: any, actionMeta: any) => {
    // console.group("Value Changed");
    // console.log(newValue);
    // console.log(`action: ${actionMeta.action}`);
    // console.groupEnd();
    this.setState({
      documentName:
        actionMeta.action?.toUpperCase() !== "CLEAR" ? newValue?.value : null,
    });
  };

  handleSubDocumentGroupChange = (
    selectedOption,
    event,
    type,
    mainDocument
  ) => {
    const selectedValues = [];
    selectedOption?.map(
      (s) => (s.main_document_name = mainDocument) && selectedValues?.push(s)
    );

    this.setState({
      subDocumentName: selectedValues,
    });
  };

  render() {
    const {
      editorState,
      ndaEditorState,
      documentName,
      subDocumentName,
      agentCount,
      agentList,
      addMoreAgents,
    } = this.state;
    const {
      userActions,
      dispatch,
      options,
      getDeals,
      createDeal,
      handleCreateDeal,
      ImageUploader,
      DocumentUploader,
      removeImage,
      removeFile,
      handleMultiSelectChange,
    } = this.props;

    const projectExists = getDeals?.deals?.find(
      (x) => x.name?.toUpperCase() === createDeal?.name?.toUpperCase()
    );

    const showNewDocs = () => {
      let existingHtml = [];
      const typeArr = ["main", "sub"];
      for (let typeIndex = 0; typeIndex < typeArr.length; typeIndex++) {
        if (!createDeal?.files[typeArr[typeIndex]]) {
          continue;
        }
        for (const [key, value] of Object.entries(
          createDeal?.files[typeArr[typeIndex]]
        )) {
          if (value?.length) {
            existingHtml.push(
              <div key={key}>
                <Col lg={12}>
                  <h4 className="existingFilesHeader robotoFontBold">
                    {toTitleCase(key)} Files:
                  </h4>
                </Col>
                {value?.map((v, i) => (
                  <Col key={i} lg={6} className="fileCol">
                    <div className="fileButtonDiv robotoFontRegular">
                      <p className="fileButtonName">{v?.name}</p>
                      <Button
                        className="fileCloseButton hoverDelay"
                        onClick={() => removeFile(v?.name, key)}
                      >
                        X
                      </Button>
                    </div>
                  </Col>
                ))}
              </div>
            );
          }
        }
      }
      return existingHtml;
    };

    return (
      <Col lg={12}>
        <h1 className="generalHeader">Create Deal</h1>
        <span className="headerSpacer"></span>
        <Form model="createDeal" onSubmit={() => handleCreateDeal()}>
          <Col lg={12} className="inputCol">
            <InputFields
              label="name"
              type="text"
              inputType="text"
              model=".name"
              value={createDeal?.name}
            />
          </Col>
          {!!projectExists && (
            <p className="inputErrorMessage robotoFontRegular">
              Project name already exists! Please change the name.
            </p>
          )}
          <Col lg={12} className="inputCol">
            <InputFields
              label="city"
              type="text"
              inputType="text"
              model=".city"
              value={createDeal?.city}
            />
          </Col>
          <Col lg={12} className="inputCol">
            <p className="selectLabel">State</p>
            <InputFields
              label="State"
              defaultOption="Choose a state"
              type="select"
              customLabel={true}
              inputType="select"
              model=".state"
              value={createDeal?.state}
              options={options?.states || []}
            />
          </Col>
          <Col lg={12} className="inputCol">
            <InputFields
              label="List Price"
              type="text"
              inputType="currency"
              model=".list_price"
              value={createDeal?.list_price}
              ignore={true}
            />
          </Col>
          <Col lg={12} className="inputCol">
            <InputFields
              label="Property GLA"
              type="text"
              inputType="text"
              model=".property_gla"
              value={createDeal?.property_gla}
            />
          </Col>
          <Col lg={12} className="inputCol">
            <p className="selectLabel">NDA Required?</p>
            <InputFields
              label="NDA choice"
              customLabel={true}
              type="select"
              inputType="select"
              defaultOption="Select an option"
              options={[
                { label: "Yes", value: 1 },
                { label: "No", value: 0 },
              ]}
              model=".nda_required"
            />
          </Col>
          {/* <Col lg={12} className="inputCol">
            <p className="selectLabel">Set to Active?</p>
            <InputFields
              label="value"
              customLabel={true}
              type="select"
              inputType="select"
              defaultOption="Select an option"
              options={[
                { label: "Yes", value: "1" },
                { label: "No", value: "0" },
              ]}
              model=".is_active"
            />
          </Col> */}
          {createDeal?.nda_required === "1" ? (
            <Col lg={12} className="inputCol">
              <p className="dropzoneLabel">NDA Agreement</p>
              <Editor
                editorState={ndaEditorState}
                toolbarClassName="toolbarClassName"
                wrapperClassName="wrapperClassName"
                editorClassName="editorClassName"
                onEditorStateChange={this.onNdaCreatorStateChange}
                toolbar={{
                  options: [
                    "inline",
                    "blockType",
                    "fontSize",
                    // "fontFamily",
                    "list",
                    "textAlign",
                    "history",
                    "image",
                  ],
                  inline: { inDropdown: true },
                  list: { inDropdown: true },
                  textAlign: { inDropdown: true },
                  link: { inDropdown: true },
                  history: { inDropdown: true },
                  // fontFamily: {
                  //   options: ["transat-textate"],
                  //   className: undefined,
                  //   component: undefined,
                  //   dropdownClassName: undefined,
                  // },
                  image: {
                    className: undefined,
                    component: undefined,
                    popupClassName: undefined,
                    urlEnabled: true,
                    uploadEnabled: true,
                    alignmentEnabled: true,
                    uploadCallback: undefined,
                    previewImage: true,
                    inputAccept:
                      "image/gif,image/jpeg,image/jpg,image/png,image/svg",
                    alt: { present: false, mandatory: false },
                    defaultSize: {
                      height: "auto",
                      width: "100%",
                    },
                  },
                }}
              />
            </Col>
          ) : null}
          <Col lg={12} className="inputCol">
            <p className="dropzoneLabel">Summary</p>
            <Editor
              editorState={editorState}
              toolbarClassName="toolbarClassName"
              wrapperClassName="wrapperClassName"
              editorClassName="editorClassName"
              onEditorStateChange={this.onCreatorStateChange}
              toolbar={{
                options: [
                  "inline",
                  "blockType",
                  "fontSize",
                  // "fontFamily",
                  "list",
                  "textAlign",
                  "history",
                  "image",
                ],
                inline: { inDropdown: true },
                list: { inDropdown: true },
                textAlign: { inDropdown: true },
                link: { inDropdown: true },
                history: { inDropdown: true },
                // fontFamily: {
                //   options: ["transat-textate"],
                //   className: undefined,
                //   component: undefined,
                //   dropdownClassName: undefined,
                // },
                image: {
                  className: undefined,
                  component: undefined,
                  popupClassName: undefined,
                  urlEnabled: true,
                  uploadEnabled: true,
                  alignmentEnabled: true,
                  uploadCallback: undefined,
                  previewImage: true,
                  inputAccept:
                    "image/gif,image/jpeg,image/jpg,image/png,image/svg",
                  alt: { present: false, mandatory: false },
                  defaultSize: {
                    height: "auto",
                    width: "100%",
                  },
                },
              }}
            />
          </Col>
          <Col lg={12} className="inputCol">
            <p className="dropzoneLabel">Upload Images</p>
            <ImageUploader />
          </Col>
          {createDeal?.images?.map((file, i) => (
            <Col key={i} lg={6} className="fileCol">
              <div className="fileButtonDiv robotoFontRegular">
                <p className="fileButtonName">{file?.name}</p>
                <Button
                  className="fileCloseButton hoverDelay"
                  onClick={() => removeImage(file?.name)}
                >
                  X
                </Button>
              </div>
            </Col>
          ))}

          {/* File Category Section */}
          <Col lg={12} className="inputCol">
            <p className="dropzoneLabel">Upload Documents</p>
            <Col lg={6} className="inputCol">
              <p className="robotoFontBold docTypeBtnGroupName">
                Type in document group to upload files to:
              </p>
              <div
                style={{
                  textAlign: "center",
                  cursor: "pointer",
                  lineHeight: "normal",
                  // width: "300px",
                }}
              >
                <CreatableSelect
                  isClearable
                  name="document_name"
                  options={[]}
                  className="basic-multi-select"
                  classNamePrefix="select"
                  onChange={this.handleDocumentGroupChange}
                />
              </div>
              {documentName ? (
                <>
                  <br />
                  <p className="robotoFontBold docTypeBtnGroupName">
                    If wanted, type in sub-document group(s) to upload files to:
                  </p>
                  <div
                    style={{
                      textAlign: "center",
                      cursor: "pointer",
                      lineHeight: "normal",
                      // width: "300px",
                    }}
                  >
                    <CreatableSelect
                      isMulti
                      isClearable
                      name={`files.${[documentName]}.sub_document_name`}
                      options={[]}
                      onChange={(a, b) =>
                        this.handleSubDocumentGroupChange(
                          a,
                          b,
                          "createDeal",
                          documentName
                        )
                      }
                      // onChange={this.handleChange}
                      // onInputChange={this.handleInputChange}
                      // styles={colourStyles}
                      styles={{
                        multiValueLabel: (base) => ({
                          ...base,
                          backgroundColor: "#FF7F32",
                          color: "white",
                          fontFamily: "roboto",
                          fontWeight: 900,
                        }),
                      }}
                    />
                  </div>
                </>
              ) : null}
            </Col>

            {documentName ? (
              <>
                <br />
                {DocumentUploader(documentName, subDocumentName)}
              </>
            ) : null}
          </Col>
          {showNewDocs()}
          <Col lg={12} className="inputCol" style={{ flexGrow: "1" }}>
            <hr />
            <br />
            <div className="adminHeaderDiv">
              <h1 className="generalHeader">Assign users to deal</h1>
            </div>
            <Col lg={6} className="inputCol">
              <div
                style={{
                  textAlign: "center",
                  cursor: "pointer",
                  lineHeight: "normal",
                  // width: "300px",
                }}
              >
                <Select
                  isMulti
                  name="assigned_users"
                  options={getDeals?.usersOptions}
                  className="basic-multi-select"
                  classNamePrefix="select"
                  onChange={(a, b) =>
                    handleMultiSelectChange(a, b, "createDeal")
                  }
                  // styles={colourStyles}
                  styles={{
                    multiValueLabel: (base) => ({
                      ...base,
                      backgroundColor: "#FF7F32",
                      color: "white",
                      fontFamily: "roboto",
                      fontWeight: 900,
                    }),
                  }}
                />
              </div>
            </Col>
          </Col>
          <hr />
          {/* MAKE GROUPS PUBLIC */}
          {/* <Col lg={12} style={{ marginTop: "60px" }}>
                      <div className="adminHeaderDiv">
                        <h1 className="generalHeader">
                          Select groups to modify public status
                        </h1>
                      </div>
                      <Select
                        value={userActions?.modify_document?.document}
                        isMulti
                        name="public_groups"
                        options={documents?.filter(
                          (d) =>
                            d?.type?.toUpperCase() === "MAIN" &&
                            d?.deal_id === editDeal?.id
                        )}
                        className="basic-multi-select"
                        classNamePrefix="select"
                        onChange={(a, b) => this.handlePublicCategory(a, b)}
                        // styles={colourStyles}
                        styles={{
                          multiValueLabel: (base) => ({
                            ...base,
                            backgroundColor: "#FF7F32",
                            color: "white",
                            fontFamily: "transat-text",
                            fontWeight: 900,
                          }),
                        }}
                      />
                      <br />
                      <InputFields
                        label="Make group public"
                        customLabel={true}
                        type="select"
                        inputType="select"
                        defaultOption="Make group public?"
                        options={[
                          { label: "Yes", value: "1" },
                          { label: "No", value: "0" },
                        ]}
                        model=".is_public"
                      />
                    </Col> 
          <hr />*/}
          <Col lg={12} className="inputCol" style={{ flexGrow: "1" }}>
            <div className="adminHeaderDiv">
              <h1 className="generalHeader">Add listing agents</h1>
            </div>
            <div>
              <Col lg={6} className="inputCol">
                {createDeal?.agents?.map((d, i) => (
                  <div key={i}>
                    <p className="robotoFontBold">
                      <b>Agent {i + 1}</b>
                    </p>
                    <div className="agentList robotoFontRegular">
                      <p>
                        <b>Name: </b>
                        {d?.name}, <b>Email: </b>
                        {d?.email}, <b>Phone: </b>
                        {d?.phone}, <b>License: </b>
                        {d?.license}
                      </p>
                      <Button
                        className="fileCloseButton"
                        onClick={() => this.removeAgent(i)}
                      >
                        X
                      </Button>
                    </div>
                    <hr />
                  </div>
                ))}
              </Col>
              {!addMoreAgents ? (
                <>
                  <Col lg={6} className="inputCol">
                    <InputFields
                      label="name"
                      type="text"
                      inputType="text"
                      model="userActions.agents.name"
                      // value={userActions?.agents?.name}
                      standardLabel={true}
                    />
                  </Col>
                  <Col lg={6} className="inputCol">
                    <InputFields
                      label="title"
                      type="text"
                      inputType="text"
                      model="userActions.agents.title"
                      // value={userActions?.agents?.title}
                      standardLabel={true}
                    />
                  </Col>
                  <Col lg={6} className="inputCol">
                    <InputFields
                      label="email"
                      type="email"
                      inputType="text"
                      model="userActions.agents.email"
                      // value={userActions?.agents?.email}
                      standardLabel={true}
                    />
                  </Col>
                  <Col lg={6} className="inputCol">
                    <InputFields
                      label="phone"
                      type="tel"
                      inputType="customPhone"
                      model="userActions.agents.phone"
                      // value={userActions?.agents?.phone}
                      standardLabel={true}
                    />
                  </Col>
                  <Col lg={6} className="inputCol">
                    <InputFields
                      label="License Number"
                      type="text"
                      inputType="text"
                      model="userActions.agents.license"
                      // value={userActions?.agents?.license}
                      standardLabel={true}
                    />
                  </Col>
                </>
              ) : null}
              {!addMoreAgents ? (
                <Col lg={12} className="inputCol" style={{ flexGrow: "1" }}>
                  <Button
                    className="generalButton"
                    onClick={() => {
                      let agentArr = [...agentList];
                      dispatch(actions.change(`userActions.agents.add`, true));
                      agentArr.push(userActions.agents);
                      this.setState({
                        agentList: agentArr,
                      });
                      dispatch(actions.change(`createDeal.agents`, agentArr));
                      dispatch(actions.change(`userActions.agents`, {}));
                      this.setState({ addMoreAgents: true });
                    }}
                  >
                    ADD AGENT
                  </Button>
                </Col>
              ) : (
                <Col lg={12} className="inputCol" style={{ flexGrow: "1" }}>
                  <Button
                    className="generalButton"
                    // disabled={{ valid: false }}
                    onClick={() => {
                      this.setState({
                        agentCount: agentCount + 1,
                        addMoreAgents: false,
                      });
                    }}
                  >
                    ADD ANOTHER
                  </Button>
                </Col>
              )}
            </div>
          </Col>
          <Col lg={12} className="inputCol" style={{ flexGrow: "1" }}>
            <Control.button
              model="createDeal"
              className="generalButton submitButton"
              type="submit"
              disabled={{ valid: false } && !!projectExists}
            >
              SUBMIT
            </Control.button>
          </Col>
        </Form>
      </Col>
    );
  }
}

const mapStateToProps = (state) => ({
  options: state.options,
  user: state.user,
  userActions: state.userActions,
  getDeals: state.getDeals,
  createDeal: state.createDeal,
});

export default connect(mapStateToProps)(CreateDeal);
