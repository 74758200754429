import React, { Component } from "react";
import { connect } from "react-redux";
import Home from "./components/Home";
import Login from "./components/Login";
import Signup from "./components/Signup";
import PrivacyPolicy from "./components/PrivacyPolicy";
import Dashboard from "./components/Dashboard";
import Deal from "./components/Deal";
import Admin from "./components/Admin";
import ThankYou from "./components/ThankYou";
import { Route } from "react-router-dom";
import "./global/css/globalStyle.css";
// import Loading from "./components/Loading";
import AlertMessage from "./components/AlertMessage";

class App extends Component {
  componentDidMount() {
    const { dispatch, user } = this.props;
    if (user?.login) {
      dispatch({ type: "CHECK_TOKEN" });
    }
    dispatch({ type: "GET_OPTIONS" });
    // dispatch({ type: "GET_IP" });
  }
  render() {
    const { user } = this.props;
    // if (ipAddr?.isLoading) {
    //   return <Loading />;
    // }
    return (
      <div>
        <AlertMessage />
        <Route exact path="/" component={!user?.login ? Home : Dashboard} />
        <Route exact path="/logout" component={Home} />
        <Route exact path="/login" component={Login} />
        <Route exact path="/signup" component={Signup} />
        <Route exact path="/verify/:token" component={ThankYou} />
        <Route exact path="/resetpassword/:token" component={Login} />
        <Route exact path="/thankyou" component={ThankYou} />
        <Route exact path="/dashboard" component={Dashboard} />
        <Route exact path="/dashboard/deal/:dealID" component={Deal} />
        <Route exact path="/admin" component={Admin} />
        {/* <Route exact path="/dashboard/deal/:dealID" component={Deal} /> */}
        <Route exact path="/privacypolicy" component={PrivacyPolicy} />
      </div>
    );
  }
}

// export default App;

const mapStateToProps = (state) => ({
  checkToken: state.checkToken,
  ipAddr: state.ipAddr,
  user: state.user,
  userActions: state.userActions,
});

export default connect(mapStateToProps)(App);
