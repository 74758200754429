/* eslint-disable react/jsx-pascal-case */
import React, { Component } from "react";
import { connect } from "react-redux";
import { Form, Control, actions } from "react-redux-form";
import InputFields from "../InputFields";
import { Row, Col } from "react-bootstrap";
import { omit } from "lodash";
import "./style.css";

class NdaForm extends Component {
  // componentDidMount() {
  //   const { dispatch, user } = this.props;
  //   dispatch(
  //     actions.change(
  //       "userActions.nda_agreement",
  //       omit(
  //         user,
  //         "full_name",
  //         "email",
  //         "admin",
  //         "accredited_investor",
  //         "agreement_form_signed",
  //         "dsToken",
  //         "email_verified",
  //         "id",
  //         "isLoading",
  //         "linked_document_categories",
  //         "linked_sub_document_categories",
  //         "login",
  //         "message",
  //         "nda_documents",
  //         "token",
  //         "user_verified"
  //       )
  //     )
  //   );
  // }

  componentDidMount() {
    const { dispatch, user } = this.props;
    if (user?.login) {
      dispatch(
        actions.change(
          "userActions.nda_agreement",
          omit(
            user,
            "full_name",
            "admin",
            "accredited_investor",
            "agreement_form_signed",
            "dsToken",
            "email_verified",
            "id",
            "isLoading",
            "linked_document_categories",
            "linked_sub_document_categories",
            "login",
            "message",
            "nda_documents",
            "token",
            "user_verified"
          )
        )
      );
    }
  }

  render() {
    const { userActions, options, deal } = this.props;

    return (
      <React.Fragment>
        <Form model="userActions.nda_agreement">
          <Row>
            <Col lg={6}>
              <Col lg={12} className="inputCol">
                <InputFields
                  label="first name"
                  type="text"
                  inputType="text"
                  model=".first_name"
                  value={userActions?.nda_agreement?.first_name}
                  standardLabel={true}
                />
              </Col>
              <Col lg={12} className="inputCol">
                <InputFields
                  label="last name"
                  type="text"
                  inputType="text"
                  model=".last_name"
                  value={userActions?.nda_agreement?.last_name}
                  standardLabel={true}
                />
              </Col>
              <Col lg={12} className="inputCol">
                <InputFields
                  label="company"
                  type="text"
                  inputType="text"
                  model=".company"
                  value={userActions?.nda_agreement?.company || ""}
                  standardLabel={true}
                />
              </Col>
              <Col lg={12} className="inputCol">
                <InputFields
                  label="title"
                  type="text"
                  inputType="text"
                  model=".title"
                  value={userActions?.nda_agreement?.title || ""}
                  standardLabel={true}
                  ignore={true}
                />
              </Col>
              {/* <Col lg={12} className="inputCol">
                <InputFields
                  label="street"
                  type="text"
                  inputType="text"
                  model=".street"
                  value={userActions?.nda_agreement?.street || ""}
                  standardLabel={true}
                />
              </Col> */}
              <Col lg={12} className="inputCol">
                <InputFields
                  label="city"
                  type="text"
                  inputType="text"
                  model=".city"
                  value={userActions?.nda_agreement?.city || ""}
                  standardLabel={true}
                />
              </Col>
            </Col>
            <Col lg={6}>
              <Col lg={12} className="inputCol inputSelectCol">
                <p className="selectLabel">
                  I am...<span className="requiredField">*</span>
                </p>
                <InputFields
                  label="answer"
                  defaultOption="Choose an option"
                  type="select"
                  customLabel={true}
                  inputType="select"
                  model=".investor_type"
                  value={userActions?.nda_agreement?.investor_type}
                  options={options?.investment_types || []}
                />
              </Col>
              <Col lg={12} className="inputCol inputSelectCol">
                <p className="selectLabel">
                  State<span className="requiredField">*</span>
                </p>
                <InputFields
                  label="State"
                  defaultOption="Choose a state"
                  type="select"
                  customLabel={true}
                  inputType="select"
                  model=".state"
                  options={options?.states || []}
                  value={userActions?.nda_agreement?.state}
                />
              </Col>
              {/* <Col lg={12} className="inputCol">
                <InputFields
                  label="zipcode"
                  type="text"
                  inputType="text"
                  model=".zipcode"
                  value={userActions?.nda_agreement?.zipcode || ""}
                  standardLabel={true}
                />
              </Col> */}
              {/* <Col lg={12} className="inputCol">
                <InputFields
                  label="country"
                  type="text"
                  inputType="text"
                  model=".country"
                  value={userActions?.nda_agreement?.country || ""}
                  standardLabel={true}
                />
              </Col> */}
              <Col lg={12} className="inputCol">
                <InputFields
                  label="email"
                  type="email"
                  inputType="text"
                  model="userActions.nda_agreement.email"
                  standardLabel={true}
                  value={userActions?.nda_agreement?.email}
                />
              </Col>
              <Col lg={12} className="inputCol">
                <InputFields
                  label="phone"
                  type="tel"
                  inputType="customPhone"
                  model="userActions.nda_agreement.phone"
                  standardLabel={true}
                  value={userActions?.nda_agreement?.phone}
                />
              </Col>
              <Col lg={12} className="inputCol">
                <InputFields
                  label="website"
                  type="text"
                  inputType="text"
                  model=".website"
                  ignore={true}
                  value={userActions?.nda_agreement?.website || ""}
                  standardLabel={true}
                />
              </Col>
            </Col>
            <Col lg={12} style={{ marginTop: "30px" }}>
              <Control.button
                model="userActions.nda_agreement"
                className="generalButton"
                type="submit"
                disabled={{ valid: false }}
                onClick={() =>
                  this.props.dispatch({
                    type: "HANDLE_NDA",
                    payload: { populate: true, deal },
                  })
                }
              >
                CONTINUE
              </Control.button>
            </Col>
          </Row>
        </Form>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  options: state.options,
  user: state.user,
  userActions: state.userActions,
  signup: state.signup,
  deal: state.deal,
});

export default connect(mapStateToProps)(NdaForm);
