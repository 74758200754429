import { combineReducers } from "redux";
import {
  createForms, // optional
} from "react-redux-form";
import {
  // ERRORS,
  // NO_ERRORS,
  APPROVE_USER,
  APPROVE_USER_SUCCESS,
  APPROVE_USER_ERROR,
  UPDATE_USER,
  UPDATE_USER_SUCCESS,
  UPDATE_USER_ERROR,
  DENY_USER,
  DENY_USER_SUCCESS,
  DENY_USER_ERROR,
  ADD_DOCUMENT_GROUP,
  REMOVE_DOCUMENT_GROUP,
  CHECK_TOKEN,
  CHECK_TOKEN_SUCCESS,
  CHECK_TOKEN_ERROR,
  GET_OPTIONS,
  GET_OPTIONS_SUCCESS,
  GET_OPTIONS_ERROR,
  GET_IP,
  GET_IP_SUCCESS,
  GET_IP_ERROR,
  SIGNUP,
  SIGNUP_SUCCESS,
  SIGNUP_ERROR,
  SEND_VERIFY_EMAIL,
  SEND_VERIFY_EMAIL_SUCCESS,
  SEND_VERIFY_EMAIL_ERROR,
  VERIFY_EMAIL,
  VERIFY_EMAIL_SUCCESS,
  VERIFY_EMAIL_ERROR,
  LOGIN,
  LOGIN_SUCCESS,
  LOGIN_ERROR,
  LOGOUT,
  SEND_PASSWORD_RESET,
  SEND_PASSWORD_RESET_SUCCESS,
  SEND_PASSWORD_RESET_ERROR,
  VERIFY_PASSWORD_RESET,
  VERIFY_PASSWORD_RESET_SUCCESS,
  VERIFY_PASSWORD_RESET_ERROR,
  PASSWORD_RESET,
  PASSWORD_RESET_SUCCESS,
  PASSWORD_RESET_ERROR,
  GET_DEAL,
  GET_DEAL_SUCCESS,
  GET_DEAL_ERROR,
  GET_DEALS,
  GET_DEALS_SUCCESS,
  GET_DEALS_ERROR,
  CREATE_DEAL,
  CREATE_DEAL_SUCCESS,
  CREATE_DEAL_ERROR,
  EDIT_DEAL,
  EDIT_DEAL_SUCCESS,
  EDIT_DEAL_ERROR,
  DELETE_DEAL,
  DELETE_DEAL_SUCCESS,
  DELETE_DEAL_ERROR,
  // VERIFY_SIGNATURE,
  VERIFY_SIGNATURE_SUCCESS,
  VERIFY_SIGNATURE_ERROR,
  SEND_INTEREST,
  SEND_INTEREST_SUCCESS,
  SEND_INTEREST_ERROR,
  HANDLE_AGENT,
  HANDLE_AGENT_SUCCESS,
  HANDLE_AGENT_ERROR,
  HANDLE_NDA,
  HANDLE_NDA_SUCCESS,
  HANDLE_NDA_ERROR,
  GET_AUDIT,
  GET_AUDIT_SUCCESS,
  GET_AUDIT_ERROR,
  HANDLE_AUDIT_NOTES_SUCCESS,
} from "./constants";

// const initialState = {};

// const userInitialState = {
//   ...this,
//   first_name: "",
//   last_name: "",
//   phone: "",
//   email: "",
//   password: "",
//   loggedIn: false,
// };

const signupInitialState = {
  ...this,
};

export const checkToken = (state = {}, { type, payload = "" }) => {
  switch (type) {
    case CHECK_TOKEN:
      return {
        isLoading: true,
      };
    case CHECK_TOKEN_SUCCESS:
      return {
        isLoading: false,
        tokenExpired: false,
      };
    case CHECK_TOKEN_ERROR:
      return {
        isLoading: false,
        tokenExpired: true,
        error: payload.error,
      };
    default:
      return state;
  }
};

export const auditLog = (state = {}, { type, payload = "" }) => {
  switch (type) {
    case GET_AUDIT:
      return {
        ...state,
        isLoading: payload?.reload ? true : false,
      };
    case GET_AUDIT_SUCCESS:
      return {
        isLoading: false,
        logs: payload.logs,
      };
    case GET_AUDIT_ERROR:
      return {
        isLoading: false,
        error: payload.error,
      };
    case HANDLE_AUDIT_NOTES_SUCCESS:
      return {
        ...state,
        isLoading: false,
      };
    default:
      return state;
  }
};

export const alertMessage = (state = {}, { type, payload = "" }) => {
  switch (type) {
    default:
      return state;
  }
};

export const ipAddr = (state = {}, { type, payload = "" }) => {
  switch (type) {
    case GET_IP:
      return {
        isLoading: true,
      };
    case GET_IP_SUCCESS:
      return {
        isLoading: false,
        ip: payload.ip,
      };
    case GET_IP_ERROR:
      return {
        isLoading: false,
        error: payload.error,
      };
    default:
      return state;
  }
};

export const options = (state = {}, { type, payload = "" }) => {
  switch (type) {
    case GET_OPTIONS:
      return {
        isLoading: true,
      };
    case GET_OPTIONS_SUCCESS:
      return {
        isLoading: false,
        ...payload.data,
      };
    case GET_OPTIONS_ERROR:
      return {
        isLoading: false,
        data: null,
      };
    default:
      return state;
  }
};

// export const errors = (state = {}, { type, payload = "" }) => {
//   switch (type) {
//     case ERRORS:
//       return {
//         error: payload.message,
//       };
//     case NO_ERRORS:
//       return {
//         message: payload.message,
//       };
//     default:
//       return state;
//   }
// };

export const signup = (state = signupInitialState, { type, payload = "" }) => {
  switch (type) {
    case SIGNUP:
    case SEND_VERIFY_EMAIL:
    case VERIFY_EMAIL:
      return {
        ...state,
        isLoading: true,
      };
    case SIGNUP_SUCCESS:
      return {
        ...state,
        isLoading: false,
        success: true,
      };
    case SEND_VERIFY_EMAIL_SUCCESS:
      return {
        ...state,
        isLoading: false,
        success: true,
        emailSent: true,
      };
    case VERIFY_EMAIL_SUCCESS:
      return {
        ...state,
        isLoading: false,
        success: true,
        email_verified: true,
        user_verified: true,
      };
    case SIGNUP_ERROR:
    case SEND_VERIFY_EMAIL_ERROR:
    case VERIFY_EMAIL_ERROR:
      return {
        isLoading: false,
        loggedIn: false,
        error: payload.message,
      };
    default:
      return state;
  }
};

export const user = (state = {}, { type, payload = "" }) => {
  switch (type) {
    case LOGIN:
      return {
        ...state,
        isLoading: true,
      };
    case LOGIN_SUCCESS:
      return {
        login: true,
        isLoading: false,
        ...payload,
      };
    case LOGIN_ERROR:
      return {
        ...state,
        password: null,
        isLoading: false,
        loggedIn: false,
        error: payload.message,
      };
    case VERIFY_EMAIL_SUCCESS:
      return {
        isLoading: false,
      };
    case GET_DEAL_SUCCESS:
      return {
        ...state,
        dsToken: payload.dsToken,
      };
    case VERIFY_SIGNATURE_SUCCESS:
      return {
        ...state,
        dsToken: null,
      };
    case GET_DEAL_ERROR:
      return {
        ...state,
      };
    case CHECK_TOKEN_SUCCESS:
      return {
        ...state,
        ...payload.user,
      };
    case HANDLE_NDA_SUCCESS:
      return {
        ...state,
        nda_documents: payload.nda_documents,
      };
    case LOGOUT:
      return {
        isLoading: false,
      };
    default:
      return state;
  }
};

export const passwordReset = (state = {}, { type, payload = "" }) => {
  switch (type) {
    case SEND_PASSWORD_RESET:
      return {
        ...state,
        isLoading: true,
      };
    case VERIFY_PASSWORD_RESET:
    case PASSWORD_RESET:
      return {
        ...state,
        isLoading: true,
      };
    case SEND_PASSWORD_RESET_SUCCESS:
      return {
        isLoading: false,
        email: payload.email,
        passwordResetSent: true,
        message: payload.message,
      };
    case SEND_PASSWORD_RESET_ERROR:
      return {
        isLoading: false,
        email: state?.email,
        passwordResetSent: false,
        error: payload.message,
      };
    case VERIFY_PASSWORD_RESET_SUCCESS:
      return {
        isLoading: false,
        verifyPasswordToken: true,
        message: payload.message,
        email: payload.email,
      };
    case VERIFY_PASSWORD_RESET_ERROR:
      return {
        isLoading: false,
        verifyPasswordToken: false,
        error: payload.message,
      };
    case PASSWORD_RESET_SUCCESS:
      return {
        isLoading: false,
        passwordReset: true,
        message: payload.message,
      };
    case PASSWORD_RESET_ERROR:
      return {
        isLoading: false,
        passwordReset: false,
        message: payload.message,
      };
    default:
      return state;
  }
};

export const userActions = (state = {}, { type, payload = "" }) => {
  switch (type) {
    case LOGIN:
      return {
        ...state,
        isLoading: true,
      };
    case LOGIN_SUCCESS:
      return {
        isLoading: false,
      };
    case LOGIN_ERROR:
      return {
        isLoading: false,
        error: payload.message,
      };
    case LOGOUT:
      return {
        isLoading: true,
      };
    case UPDATE_USER:
    case HANDLE_AGENT:
    case HANDLE_NDA:
      return {
        ...state,
        isLoading: true,
      };
    case ADD_DOCUMENT_GROUP:
    case REMOVE_DOCUMENT_GROUP:
      return {
        ...state,
      };
    case UPDATE_USER_SUCCESS:
    case HANDLE_AGENT_SUCCESS:
    case HANDLE_NDA_SUCCESS:
      return {
        isLoading: false,
      };
    case UPDATE_USER_ERROR:
    case HANDLE_AGENT_ERROR:
    case HANDLE_NDA_ERROR:
      return {
        isLoading: false,
        error: payload.message,
      };
    default:
      return state;
  }
};

export const deal = (state = { ...this }, { type, payload = "" }) => {
  switch (type) {
    case GET_DEAL:
      return {
        ...state,
        isLoading: true,
      };
    case GET_DEAL_SUCCESS:
      return {
        isLoading: false,
        // message: payload.message,
        ...payload.deal,
      };
    case VERIFY_SIGNATURE_SUCCESS:
      return {
        ...state,
        signatureComplete: true,
      };
    case GET_DEAL_ERROR:
      return {
        ...state,
        isLoading: false,
        error: payload.message,
      };
    case VERIFY_SIGNATURE_ERROR:
      return {
        ...state,
        signatureComplete: false,
      };
    case SEND_INTEREST:
      return {
        ...state,
        isLoading: true,
      };
    case SEND_INTEREST_SUCCESS:
      return {
        ...state,
        isLoading: false,
        interest_sent: true,
        message: payload.message,
      };
    case SEND_INTEREST_ERROR:
      return {
        ...state,
        isLoading: false,
        interest_sent: false,
        error: payload.message,
      };
    default:
      return state;
  }
};

export const getDeals = (
  state = { ...this, isLoading: false, files: [], images: [] },
  { type, payload = "" }
) => {
  switch (type) {
    case GET_DEALS:
      return {
        ...state,
        isLoading: true,
      };
    case GET_DEALS_SUCCESS:
      return {
        isLoading: false,
        ...payload,
      };
    case GET_DEALS_ERROR:
      return {
        ...state,
        isLoading: false,
        error: payload.message,
      };
    default:
      return state;
  }
};

export const createDeal = (
  state = { ...this, files: {}, images: [] },
  { type, payload = "" }
) => {
  switch (type) {
    case CREATE_DEAL:
      return {
        ...state,
        isLoading: true,
      };
    case CREATE_DEAL_SUCCESS:
      return {
        isLoading: false,
        message: payload.message,
        files: {},
        images: [],
      };
    case CREATE_DEAL_ERROR:
      return {
        ...state,
        isLoading: false,
        error: payload.message,
      };
    default:
      return state;
  }
};

export const editDeal = (state = {}, { type, payload = "" }) => {
  switch (type) {
    case EDIT_DEAL:
      return {
        ...state,
        isLoading: true,
      };
    case EDIT_DEAL_SUCCESS:
      return {
        isLoading: false,
        message: payload.message,
      };
    case EDIT_DEAL_ERROR:
      return {
        ...state,
        isLoading: false,
        error: payload.message,
      };
    default:
      return state;
  }
};

export const deleteDeal = (state = {}, { type, payload = "" }) => {
  switch (type) {
    case DELETE_DEAL:
      return {
        ...state,
        isLoading: true,
      };
    case DELETE_DEAL_SUCCESS:
      return {
        isLoading: false,
        message: payload.message,
      };
    case DELETE_DEAL_ERROR:
      return {
        ...state,
        isLoading: false,
        error: payload.message,
      };
    default:
      return state;
  }
};

export const approveUser = (state = {}, { type, payload = "" }) => {
  switch (type) {
    case APPROVE_USER:
    case DENY_USER:
      return {
        ...state,
        isLoading: true,
      };
    case APPROVE_USER_SUCCESS:
    case DENY_USER_SUCCESS:
      return {
        isLoading: false,
        message: payload.message,
      };
    case APPROVE_USER_ERROR:
    case DENY_USER_ERROR:
      return {
        ...state,
        isLoading: false,
        error: payload.message,
      };
    default:
      return state;
  }
};

export const customLink = (state = {}, { type, payload = "" }) => {
  switch (type) {
    case LOGOUT:
      return {
        custom_link: false,
      };
    default:
      return state;
  }
};

export default combineReducers({
  ipAddr,
  options,
  user,
  checkToken,
  deal,
  approveUser,
  auditLog,
  // errors,
  ...createForms({
    alertMessage,
    user,
    signup,
    userActions,
    passwordReset,
    getDeals,
    createDeal,
    editDeal,
    deleteDeal,
    customLink,
  }),
});
