/* eslint-disable react/jsx-pascal-case */
import React, { Component } from "react";
import { connect } from "react-redux";
import { Control, Form, actions } from "react-redux-form";
import { Row, Col } from "react-bootstrap";
import InputFields from "../InputFields";
import NavbarHeader from "../Navbar";
import Loading from "../Loading";
import Footer from "../Footer";
import PrivacyPolicy from "../../global/pdf/privacy-policy.pdf";
import "./style.css";

class Login extends Component {
  componentDidMount() {
    const { user, dispatch } = this.props;
    const token = this?.props?.match?.params?.token;
    if (user?.login && user?.email_verified && user?.user_verified) {
      window.location.href = "/dashboard";
    } else if (user?.login && (!user?.email_verified || !user?.user_verified)) {
      window.location.href = "/thankyou";
    }

    if (token) {
      dispatch({ type: "VERIFY_PASSWORD_RESET", payload: token });
    }
  }
  state = {
    forgotPassword: false,
  };

  handleLogin() {
    this.props.dispatch({ type: "LOGIN" });
  }

  handleSendPasswordReset() {
    this.props.dispatch({ type: "SEND_PASSWORD_RESET" });
  }

  handleChangePassword() {
    this.props.dispatch({ type: "PASSWORD_RESET" });
  }

  render() {
    const { forgotPassword } = this.state;
    const { userActions, passwordReset, dispatch } = this.props;

    if (userActions?.isLoading || passwordReset?.isLoading) {
      return <Loading />;
    }

    const matchPasswords =
      (passwordReset?.password?.length > 3 &&
        passwordReset?.confirmPassword?.length > 3 &&
        passwordReset?.password === passwordReset?.confirmPassword) ||
      undefined;

    return (
      <React.Fragment>
        <div
          className="mainContainer mainContainerHeight"
          style={{ position: "relative" }}
        >
          <div id="redBorderLine"></div>
          <NavbarHeader />
          <div className="loginCol1Div">
            {!forgotPassword && !passwordReset?.verifyPasswordToken ? (
              <React.Fragment>
                <h1 className="generalHeader">Login</h1>
                <span className="headerSpacer"></span>
                <Form model="userActions" onSubmit={() => this.handleLogin()}>
                  <Row className="loginRow">
                    <Col lg={12} className="inputCol">
                      <InputFields
                        label="username"
                        type="email"
                        inputType="text"
                        model=".email"
                        value={userActions?.email}
                      />
                    </Col>
                    <Col lg={12} className="inputCol">
                      <InputFields
                        label="password"
                        type="password"
                        inputType="text"
                        model=".password"
                        value={userActions?.password}
                      />
                    </Col>
                    <Col
                      lg={12}
                      className="inputCol loginButtonCol"
                      style={{ flexGrow: "1" }}
                    >
                      <a
                        href="#/"
                        title="Forgot Password"
                        className="forgotPasswordLink robotoFontRegular"
                        onClick={() => {
                          dispatch(actions.change("user", {}));
                          this.setState({ forgotPassword: !forgotPassword });
                        }}
                      >
                        {!forgotPassword ? "Forgot Password?" : "Back to Login"}
                      </a>
                      <Control.button
                        model="userActions"
                        className="generalButtonInverted submitButton"
                        type="submit"
                        disabled={{ valid: false }}
                      >
                        LOGIN
                      </Control.button>
                    </Col>
                  </Row>
                </Form>
              </React.Fragment>
            ) : (
              <>
                <h1 className="generalHeader">Forgot Password</h1>
                <span className="headerSpacer"></span>
                <Form
                  model="passwordReset"
                  onSubmit={() =>
                    !passwordReset?.verifyPasswordToken
                      ? this.handleSendPasswordReset()
                      : this.handleChangePassword()
                  }
                >
                  <Row className="loginRow">
                    {!passwordReset?.verifyPasswordToken ? (
                      <>
                        <Col lg={12} className="inputCol">
                          <InputFields
                            label="username"
                            type="email"
                            inputType="text"
                            model=".email"
                          />
                        </Col>
                        <Col
                          lg={12}
                          className="inputCol"
                          style={{ flexGrow: "1" }}
                        >
                          <a
                            href="#/"
                            title="Forgot Password"
                            className="forgotPasswordLink robotoFontRegular"
                            onClick={() => {
                              dispatch(actions.change("passwordReset", {}));
                              this.setState({
                                forgotPassword: !forgotPassword,
                              });
                            }}
                          >
                            {!forgotPassword
                              ? "Forgot Password?"
                              : "Back to Login"}
                          </a>
                          <Control.button
                            model="passwordReset"
                            className="generalButton submitButton"
                            type="submit"
                            disabled={{ valid: false }}
                          >
                            SUBMIT
                          </Control.button>
                        </Col>
                      </>
                    ) : (
                      <>
                        <Col lg={12} className="inputCol">
                          <InputFields
                            label="password"
                            type="password"
                            inputType="text"
                            model=".password"
                          />
                        </Col>
                        <Col lg={12} className="inputCol">
                          <InputFields
                            label="confirm password"
                            type="password"
                            inputType="text"
                            model=".confirmPassword"
                          />
                          {!matchPasswords && (
                            <p className="passwordError robotoFontBold">
                              Please match your passwords*
                            </p>
                          )}
                        </Col>
                        <Col
                          lg={6}
                          className="inputCol"
                          style={{ flexGrow: "1" }}
                        >
                          <Control.button
                            model="passwordReset"
                            className="generalButton submitButton"
                            type="submit"
                            disabled={{ valid: false }}
                          >
                            SUBMIT
                          </Control.button>
                        </Col>
                      </>
                    )}
                  </Row>
                </Form>
              </>
            )}
          </div>
          <div className="loginDisclaimerDiv">
            <p className="loginDisclaimerText robotoFontRegular">
              *We don’t share your personal info with anyone.
              <br />
              Check out our{" "}
              <a
                href={PrivacyPolicy}
                target="_blank"
                className="anchorLink"
                rel="noreferrer"
              >
                PRIVACY POLICY
              </a>{" "}
              for more information.
            </p>
          </div>
        </div>
        <Footer />
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  options: state.options,
  user: state.user,
  userActions: state.userActions,
  signup: state.signup,
  passwordReset: state.passwordReset,
});

export default connect(mapStateToProps)(Login);
