/* eslint-disable react/jsx-pascal-case */
import React, { Component } from "react";
import { connect } from "react-redux";
import { actions, Form, Control } from "react-redux-form";
import { Row, Col, Button, Modal } from "react-bootstrap";
import ReactPaginate from "react-paginate";
// import BootstrapTable from "react-bootstrap-table-next";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import { toTitleCase } from "../utils/index";
import Loading from "../Loading";
import { CSVLink } from "react-csv";
// import InputFields from "../InputFields";
import { Editor } from "react-draft-wysiwyg";
import {
  EditorState,
  convertToRaw,
  ContentState,
  convertFromHTML,
} from "draft-js";
import draftToHtml from "draftjs-to-html";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import parse from "html-react-parser";

class AuditLog extends Component {
  state = {
    selectedDeal: null,
    perPage: 10,
    page: 0,
    pages: this.props.auditLog?.logs?.length / 10,
    activeSort: "name",
    activeSortOrder: "ASC",
    sortItems: [
      "name",
      "company",
      "city",
      "state",
      "views",
      "first_visit",
      "last_visit",
    ],
    showVisitorDetails: false,
    showNotes: false,
    editorState: EditorState.createEmpty(),
    searchValue: null,
  };

  onCreatorStateChange: Function = (editorState) => {
    this.setState({
      editorState,
    });
    this.props.dispatch(
      actions.change(
        "userActions.visitor_details.notes.note",
        draftToHtml(convertToRaw(editorState.getCurrentContent()))
      )
    );
  };

  handlePageClick = (event) => {
    let page = event.selected;
    this.setState({ page });
  };

  removeHTMLTags(v) {
    if (v === null || v === "") return false;
    else v = v.toString();

    // Regular expression to identify HTML tags in
    // the input string. Replacing the identified
    // HTML tag with a null string.
    return v.replace(/(<([^>]+)>)/gi, "");
  }

  render() {
    const { auditLog, options, getDeals, dispatch, userActions, handleNotes } =
      this.props;
    let { visitorOriginalArr } = this.props;
    const {
      selectedDeal,
      perPage,
      page,
      pages,
      showVisitorDetails,
      showNotes,
      editorState,
      searchValue,
    } = this.state;

    if (auditLog?.isLoading || !auditLog?.logs?.length) {
      return <Loading />;
    }

    const users = [];
    getDeals?.users?.map((x) =>
      users.push({ label: `${x?.full_name} (${x?.email})`, value: x?.id })
    );

    const headers = [
      { label: "Name", key: "name" },
      { label: "Approved", key: "approved" },
      { label: "CA", key: "ca" },
      { label: "Total Page Views", key: "total_page_views" },
      { label: "Distinct Page Views", key: "distinct_page_views" },
      { label: "Total File Downloads", key: "total_file_downloads" },
      { label: "Distinct File Downloads", key: "distinct_file_downloads" },
      { label: "First Visit", key: "first_visit" },
      { label: "Last Visit", key: "last_visit" },
      { label: "First Name", key: "first_name" },
      { label: "Last Name", key: "last_name" },
      { label: "Email", key: "email" },
      { label: "Company", key: "company" },
      { label: "Title", key: "title" },
      { label: "Street", key: "street" },
      { label: "City", key: "city" },
      { label: "State", key: "state" },
      { label: "Zipcode", key: "zipcode" },
      { label: "Country", key: "country" },
      { label: "Phone", key: "phone" },
      { label: "Fax", key: "fax" },
      { label: "Website", key: "website" },
      { label: "Type", key: "type" },
      { label: "Notes", key: "notes" },
      { label: "Date Added", key: "date_added" },
      { label: "Last Updated", key: "date_modified" },
    ];

    const dealsList = [];
    // eslint-disable-next-line array-callback-return
    auditLog?.logs?.map((a) => {
      const usedDistinctDocs = [];
      // eslint-disable-next-line array-callback-return
      a?.visitors?.map((v) => {
        const userInfo = v?.user_info;
        const investmentType = options?.investment_types?.find(
          (i) => i?.value === userInfo?.investor_type
        );
        const uniqueViews = a?.views?.filter((j) => j?.user_id === v?.user_id);
        dealsList.push({
          id: a?.id,
          name: a?.name,
          approved: userInfo?.user_verified,
          ca: a?.nda_required ? (v?.nda?.length ? 1 : 0) : "N/A",
          total_page_views: a?.views_length,
          distinct_page_views: v?.unique_views_length,
          total_file_downloads: a?.file_audit_total_length,
          distinct_file_downloads: v?.unique_file_downloads_length || "N/A",
          ...a?.total_doc_categories,
          ...v?.unique_doc_categories,
          first_visit:
            uniqueViews[uniqueViews?.length - 1]?.date_created?.split("T")[0] ||
            "N/A",
          last_visit: uniqueViews[0]?.date_created?.split("T")[0] || "N/A",
          first_name: userInfo?.first_name,
          last_name: userInfo?.last_name,
          email: userInfo?.email,
          company: userInfo?.company,
          title: userInfo?.title,
          street: userInfo?.street,
          city: userInfo?.city,
          state: userInfo?.state,
          zipcode: userInfo?.zipcode,
          country: userInfo?.country,
          phone: userInfo?.phone,
          fax: userInfo?.fax,
          website: userInfo?.website,
          type: investmentType?.label,
          notes: userInfo?.notes?.note
            ? this.removeHTMLTags(userInfo?.notes?.note)
            : "N/A",
          date_added: userInfo?.date_created,
          date_modified: userInfo?.date_modified,
        });
        if (v?.unique_doc_categories) {
          for (const [key] of Object.entries(v?.unique_doc_categories)) {
            const modifiedKey = toTitleCase(key?.replaceAll("_", " "));
            if (!usedDistinctDocs?.includes(key)) {
              headers.splice(headers?.length - 18, 0, {
                label: modifiedKey,
                key,
              });
              usedDistinctDocs.push(key);
            }
          }
        }
      });
      if (a?.total_doc_categories) {
        for (const [key] of Object.entries(a?.total_doc_categories)) {
          const modifiedKey = toTitleCase(key?.replaceAll("_", " "));
          headers.splice(7, 0, { label: modifiedKey, key });
        }
      }
    });

    let viewDealVisitors = auditLog?.logs;
    if (selectedDeal) {
      viewDealVisitors = viewDealVisitors?.find((l) => l?.id === selectedDeal);
    }

    visitorOriginalArr = viewDealVisitors?.visitors;

    const getUniqueUserInfo = (id) => {
      const uniqueUserLogs = viewDealVisitors?.views?.filter(
        (v) => v?.user_id === id
      );

      const firstView =
        uniqueUserLogs[uniqueUserLogs?.length - 1]?.date_created?.split(
          "T"
        )[0] || "N/A";
      const lastView = uniqueUserLogs[0]?.date_created?.split("T")[0] || "N/A";

      return { views: uniqueUserLogs?.length, firstView, lastView };
    };

    const sortData = (v) => {
      const { activeSortOrder } = this.state;
      this.setState({
        activeSort: v,
        activeSortOrder:
          activeSortOrder?.toUpperCase() === "DESC" ? "ASC" : "DESC",
      });
    };

    const sortButtons = () => {
      const exportHtml = [];
      const { sortItems, activeSort, activeSortOrder } = this.state;
      // eslint-disable-next-line array-callback-return
      sortItems?.map((s, i) => {
        const activeItem = activeSort?.toUpperCase() === s?.toUpperCase();
        exportHtml.push(
          <Button
            key={i}
            href="#/"
            title="Back"
            className={`adminSortButton ${
              activeItem ? "adminSortButtonActive" : ""
            } robotoFontRegular hoverDelay`}
            style={{ padding: "0" }}
            onClick={() => sortData(s)}
          >
            {toTitleCase(s?.replace("_", " "))}
            {activeItem ? (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="currentColor"
                className="bi bi-arrow-up-short"
                viewBox="0 0 16 16"
                transform={
                  activeItem && activeSortOrder?.toUpperCase() === "DESC"
                    ? "rotate(180)"
                    : ""
                }
              >
                <path
                  fillRule="evenodd"
                  d="M8 12a.5.5 0 0 0 .5-.5V5.707l2.146 2.147a.5.5 0 0 0 .708-.708l-3-3a.5.5 0 0 0-.708 0l-3 3a.5.5 0 1 0 .708.708L7.5 5.707V11.5a.5.5 0 0 0 .5.5z"
                />
              </svg>
            ) : null}
          </Button>
        );
      });
      return exportHtml;
    };

    const dealVisitorInfo = () => {
      const exportHtml = [];
      const visitorArr = [];
      const { activeSort, activeSortOrder } = this.state;

      if (searchValue && searchValue?.length) {
        visitorOriginalArr = visitorOriginalArr?.filter(
          (v) =>
            v?.user_info?.full_name
              ?.toUpperCase()
              ?.includes(searchValue?.toUpperCase()) ||
            v?.user_info?.company
              ?.toUpperCase()
              ?.includes(searchValue?.toUpperCase())
        );
      }

      // eslint-disable-next-line array-callback-return
      visitorOriginalArr?.map((l, i) => {
        const investmentType = options?.investment_types?.find(
          (i) => i?.value === l?.user_info?.investor_type
        );
        visitorArr.push({
          logIndex: i,
          user_id: l?.user_id,
          views: getUniqueUserInfo(l?.user_id)?.views,
          first_visit: getUniqueUserInfo(l?.user_id)?.firstView,
          last_visit: getUniqueUserInfo(l?.user_id)?.lastView,
          approved: l?.user_info?.user_verified,
          ca: l?.nda?.length && l?.nda[0]?.is_signed ? "Yes" : "No",
          name: l?.user_info?.full_name,
          first_name: l?.user_info?.first_name,
          last_name: l?.user_info?.last_name,
          company: l?.user_info?.company,
          title: l?.user_info?.title,
          street: l?.user_info?.street,
          city: l?.user_info?.city,
          state: l?.user_info?.state,
          zipcode: l?.user_info?.zipcode,
          country: l?.user_info?.country,
          phone: l?.user_info?.phone,
          type: investmentType?.label,
          email: l?.user_info?.email,
          notes: l?.user_info?.notes,
        });
        // console.log(
        //   "----------------------------------------------------visitorArr Before",
        //   visitorArr
        // );
        visitorArr.sort(function (a, b) {
          if (activeSortOrder?.toUpperCase() === "DESC") {
            return a[activeSort] < b[activeSort]
              ? 1
              : b[activeSort] < a[activeSort]
              ? -1
              : 0;
          } else {
            return a[activeSort] > b[activeSort]
              ? 1
              : b[activeSort] > a[activeSort]
              ? -1
              : 0;
          }
        });
        // console.log(
        //   "----------------------------------------------------visitorArr After",
        //   visitorArr
        // );
      });

      visitorArr?.map((v, i) =>
        exportHtml.push(
          <Row key={i} className="auditLogVisitorsRow">
            <Col className="auditLogCol auditLogCol1">
              <div className="auditLogDiv auditLogDiv1">
                <p>
                  Views: <b>{v?.views}</b>
                </p>
                <p>
                  First: <b>{v?.first_visit}</b>
                </p>
                <p>
                  Last: <b>{v?.last_visit}</b>
                </p>
                <br />
                <p>
                  Approved: <b>{v?.approved}</b>
                </p>
                <p>
                  CA: <b>{v?.ca}</b>
                </p>
              </div>
            </Col>
            <Col className="auditLogCol auditLogCol2">
              <div className="auditLogDiv auditLogDiv2 auditLogDivCenter">
                {v?.name ? (
                  <>
                    <p>
                      <b>{v?.name}</b>
                    </p>
                    <p>{v?.company}</p>
                    <p>{v?.title}</p>
                    <p>{v?.type}</p>
                    <p>{v?.email}</p>
                    <p>
                      {v?.city}, {v?.state}
                    </p>
                  </>
                ) : (
                  <>
                    <p>
                      <b>Landing Page Views</b>
                    </p>
                  </>
                )}
              </div>
            </Col>
            <Col lg={4} className="auditLogCol auditLogCol3">
              <Button
                title="Visitor Details"
                className="generalButtonInverted"
                onClick={() => {
                  dispatch(actions.change("userActions.visitor_details", v));
                  handleShow();
                }}
              >
                Visitor Details
              </Button>
            </Col>
          </Row>
        )
      );
      // console.log(
      //   "------------------------------pages",
      //   exportHtml,
      //   page,
      //   perPage,
      //   page * perPage,
      //   (page + 1) * perPage,
      //   exportHtml.slice(page * perPage, (page + 1) * perPage)
      // );
      return exportHtml.slice(
        searchValue ? 0 : page * perPage,
        (page + 1) * perPage
      );
    };
    const handleClose = () =>
      this.setState({ showVisitorDetails: false, showNotes: false });
    const handleShow = () => this.setState({ showVisitorDetails: true });
    const handleShowNotes = () =>
      this.setState({ showNotes: showNotes ? false : true });

    const formatPhoneNumber = (phoneNumberString) => {
      var cleaned = ("" + phoneNumberString).replace(/\D/g, "");
      var match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
      if (match) {
        var intlCode = match[1] ? "+1 " : "";
        return [intlCode, "(", match[2], ") ", match[3], "-", match[4]].join(
          ""
        );
      }
      return null;
    };

    const auditLogItems = auditLog?.logs?.slice(
      page * perPage,
      (page + 1) * perPage
    );

    return (
      <>
        {!selectedDeal ? (
          <>
            <Col lg={12}>
              <div className="adminHeaderDiv">
                <h1 className="generalHeader">Deal Audit Logs</h1>
              </div>
              <span className="headerSpacer"></span>
            </Col>
            {auditLogItems?.map((l, i) => (
              <Row key={i} className="auditLogRow">
                <Col className="auditLogCol auditLogCol1">
                  <div className="auditLogDiv auditLogDiv1">
                    <p>
                      Page views: <b>{l?.views_length}</b>
                    </p>
                    <p>
                      Visitors: <b>{l?.visitors_length}</b>
                    </p>
                    <p>
                      First:{" "}
                      <b>
                        {l?.views[l?.views?.length - 1]?.date_created?.split(
                          "T"
                        )[0] || "N/A"}
                      </b>
                    </p>
                    <p>
                      Last:{" "}
                      <b>{l?.views[0]?.date_created?.split("T")[0] || "N/A"}</b>
                    </p>
                  </div>
                </Col>
                <Col className="auditLogCol auditLogCol2">
                  <div className="auditLogDiv auditLogDiv2 auditLogDivCenter">
                    <p>
                      <b>{l?.name}</b>
                    </p>
                    <p>
                      <b>
                        {l?.city}, {l?.state}
                      </b>
                    </p>
                  </div>
                </Col>
                <Col className="auditLogCol">
                  <div className="auditLogDiv auditLogDiv3">
                    <CSVLink
                      filename={`${l?.name}.csv`}
                      className="auditButton"
                      target="_blank"
                      data={dealsList.filter((d) => l?.id === d?.id)}
                      headers={headers}
                    >
                      Download CSV
                    </CSVLink>
                    <a
                      href="#/"
                      className="auditViewVisitorsLButton hoverDelay"
                      onClick={() =>
                        this.setState({
                          selectedDeal: l?.id,
                          page: 0,
                        })
                      }
                    >
                      View Visitors
                    </a>
                  </div>
                </Col>
              </Row>
            ))}
            <ReactPaginate
              previousLabel={"previous"}
              nextLabel={"next"}
              breakLabel={"..."}
              breakClassName={"break-me"}
              pageCount={pages || auditLog?.logs?.length / 10}
              // marginPagesDisplayed={2}
              // pageRangeDisplayed={pages}
              onPageChange={this.handlePageClick}
              previousLinkClassName={"paginationPageBackNext"}
              nextLinkClassName={"paginationPageBackNext"}
              pageClassName={"paginationPage"}
              containerClassName={"pagination"}
              activeClassName={"paginationPageActive"}
            />
          </>
        ) : null}
        {selectedDeal ? (
          <>
            <Modal
              show={showVisitorDetails || showNotes}
              onHide={handleClose}
              backdrop="static"
              keyboard={false}
              dialogClassName="visitorDetailsModal"
            >
              <Modal.Header>
                <Modal.Title className="robotoFontBold">
                  {showVisitorDetails ? "Visitor Details" : "Visitor Notes"}
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <>
                  <Row>
                    <Col lg={6}>
                      <div className="visitorInfoDiv">
                        <p className="visitorLabel robotoFontBold">
                          First Name:
                        </p>
                        <p className="visitorInfo robotoFontRegular">
                          {userActions?.visitor_details?.first_name}
                        </p>
                      </div>
                      <div className="visitorInfoDiv">
                        <p className="visitorLabel robotoFontBold">
                          Last Name:
                        </p>
                        <p className="visitorInfo robotoFontRegular">
                          {userActions?.visitor_details?.last_name}
                        </p>
                      </div>
                      <div className="visitorInfoDiv">
                        <p className="visitorLabel robotoFontBold">Company:</p>
                        <p className="visitorInfo robotoFontRegular">
                          {userActions?.visitor_details?.company}
                        </p>
                      </div>
                      <div className="visitorInfoDiv">
                        <p className="visitorLabel robotoFontBold">Title:</p>
                        <p className="visitorInfo robotoFontRegular">
                          {userActions?.visitor_details?.title || "N/A"}
                        </p>
                      </div>
                      <div className="visitorInfoDiv">
                        <p className="visitorLabel robotoFontBold">Street:</p>
                        <p className="visitorInfo robotoFontRegular">
                          {userActions?.visitor_details?.street}
                        </p>
                      </div>
                      <div className="visitorInfoDiv">
                        <p className="visitorLabel robotoFontBold">City:</p>
                        <p className="visitorInfo robotoFontRegular">
                          {userActions?.visitor_details?.city}
                        </p>
                      </div>
                      <div className="visitorInfoDiv">
                        <p className="visitorLabel robotoFontBold">State:</p>
                        <p className="visitorInfo robotoFontRegular">
                          {userActions?.visitor_details?.state}
                        </p>
                      </div>
                    </Col>
                    <Col lg={6}>
                      <div className="visitorInfoDiv">
                        <p className="visitorLabel robotoFontBold">Zip Code:</p>
                        <p className="visitorInfo robotoFontRegular">
                          {userActions?.visitor_details?.zipcode}
                        </p>
                      </div>
                      <div className="visitorInfoDiv">
                        <p className="visitorLabel robotoFontBold">Country:</p>
                        <p className="visitorInfo robotoFontRegular">
                          {userActions?.visitor_details?.country}
                        </p>
                      </div>
                      <div className="visitorInfoDiv">
                        <p className="visitorLabel robotoFontBold">Phone:</p>
                        <p className="visitorInfo robotoFontRegular">
                          {formatPhoneNumber(
                            userActions?.visitor_details?.phone
                          )}
                        </p>
                      </div>
                      <div className="visitorInfoDiv">
                        <p className="visitorLabel robotoFontBold">
                          Investor/Broker:
                        </p>
                        <p className="visitorInfo robotoFontRegular">
                          {userActions?.visitor_details?.type}
                        </p>
                      </div>
                      <div className="visitorInfoDiv">
                        <p className="visitorLabel robotoFontBold">Email:</p>
                        <p className="visitorInfo robotoFontRegular">
                          {userActions?.visitor_details?.email}
                        </p>
                      </div>
                      <div className="visitorInfoDiv">
                        <p className="visitorLabel robotoFontBold">Website:</p>
                        <p className="visitorInfo robotoFontRegular">
                          {userActions?.visitor_details?.website || "N/A"}
                        </p>
                      </div>
                      <div className="visitorInfoDiv">
                        <p className="visitorLabel robotoFontBold">Notes:</p>
                        <div className="visitorInfo visitorInfoNotes robotoFontRegular">
                          {userActions?.visitor_details?.notes?.note
                            ? parse(userActions?.visitor_details?.notes?.note)
                            : "N/A"}
                        </div>
                      </div>
                    </Col>
                    <Col lg={12}>
                      <Button
                        title="View Notes"
                        className={
                          !showNotes ? "generalButtonInverted" : "generalButton"
                        }
                        onClick={(v) => {
                          this.setState({
                            editorState: EditorState.createWithContent(
                              ContentState.createFromBlockArray(
                                convertFromHTML(
                                  `${
                                    userActions?.visitor_details?.notes?.note ||
                                    "<div></div>"
                                  }`
                                )
                              )
                            ),
                          });
                          handleShowNotes();
                        }}
                      >
                        {!showNotes
                          ? userActions?.visitor_details?.notes
                            ? "Modify"
                            : "Add"
                          : "Close"}{" "}
                        Notes
                      </Button>
                    </Col>
                    {showNotes ? (
                      <Col lg={12}>
                        <Col lg={12} className="inputCol">
                          <p className="dropzoneLabel">User Audit Notes</p>
                          <Editor
                            editorState={editorState}
                            toolbarClassName="toolbarClassName"
                            wrapperClassName="wrapperClassName"
                            editorClassName="editorClassName"
                            onEditorStateChange={this.onCreatorStateChange}
                            toolbar={{
                              options: [
                                "inline",
                                "blockType",
                                "fontSize",
                                // "fontFamily",
                                "list",
                                "textAlign",
                                "history",
                                "image",
                              ],
                              inline: { inDropdown: true },
                              list: { inDropdown: true },
                              textAlign: { inDropdown: true },
                              link: { inDropdown: true },
                              history: { inDropdown: true },
                              // fontFamily: {
                              //   options: ["transat-textate"],
                              //   className: undefined,
                              //   component: undefined,
                              //   dropdownClassName: undefined,
                              // },
                              image: {
                                className: undefined,
                                component: undefined,
                                popupClassName: undefined,
                                urlEnabled: true,
                                uploadEnabled: true,
                                alignmentEnabled: true,
                                uploadCallback: undefined,
                                previewImage: true,
                                inputAccept:
                                  "image/gif,image/jpeg,image/jpg,image/png,image/svg",
                                alt: { present: false, mandatory: false },
                                defaultSize: {
                                  height: "auto",
                                  width: "100%",
                                },
                              },
                            }}
                          />
                        </Col>
                        {/* <Form
                          model="userActions.visitor_details"
                          onSubmit={() => handleNotes()}
                        >
                          <Row>
                            <Col lg={12}>
                              <InputFields
                                placeholder="Please enter your notes here"
                                type="text"
                                inputType="textbox"
                                model=".notes"
                                value={userActions?.visitor_details?.notes}
                              />
                            </Col>
                            <Col lg={12}>
                              <Control.button
                                model="userActions.visitor_details.notes"
                                className="generalButtonInverted"
                                onClick={() => {
                                  dispatch(actions.change("userActions", {}));
                                  handleClose();
                                }}
                              >
                                Save
                              </Control.button>
                            </Col>
                          </Row>
                        </Form> */}
                      </Col>
                    ) : null}
                  </Row>
                </>
              </Modal.Body>
              <Modal.Footer>
                {showNotes ? (
                  <Button
                    className="generalButtonInverted"
                    onClick={() => {
                      handleNotes({
                        id: userActions?.visitor_details?.notes?.id,
                        user_id: userActions?.visitor_details?.user_id,
                        deal_id: viewDealVisitors?.id,
                        notes: userActions?.visitor_details?.notes?.note,
                      });
                      this.setState({ showNotes: showNotes ? false : true });
                    }}
                  >
                    Save Notes
                  </Button>
                ) : null}
                <Button
                  className="generalButtonInverted"
                  onClick={() => {
                    dispatch(actions.change("userActions", {}));
                    handleClose();
                  }}
                >
                  Close
                </Button>
              </Modal.Footer>
            </Modal>
            <Col lg={12}>
              <div className="adminHeaderDiv">
                <h1 className="generalHeader">Visitor Audit Logs</h1>
              </div>
              {/* <a
                href="#/"
                className="auditViewVisitorsLButton hoverDelay"
                onClick={() => this.setState({ selectedDeal: null })}
              >
                Back
              </a> */}
              <a
                href="#/"
                title="Back"
                className="dashboardBackButton robotoFontRegular hoverDelay"
                style={{ padding: "0" }}
                onClick={() =>
                  this.setState({
                    selectedDeal: null,
                    page: 0,
                  })
                }
              >
                Back
              </a>
              <span className="headerSpacer"></span>
            </Col>
            <Col lg={12}>
              <span className="adminSortHeader robotoFontBold">Sort:</span>
              {sortButtons()}
              <span className="headerSpacer"></span>
            </Col>
            <Form model="userActions">
              <Col lg={12} className="inputCol">
                <b className="searchBarHeader robotoFontBold">Search:</b>
                <Control.text
                  placeholder="Search for name here..."
                  className="formInputs poppinsLight hoverDelay"
                  model=".visitor_search"
                  type="text"
                  onChange={(v) =>
                    this.setState({ searchValue: v?.target?.value })
                  }
                />
              </Col>
            </Form>
            <Col lg={12}>
              <h1 className="robotoFontBold">{viewDealVisitors?.name}</h1>
            </Col>
            {dealVisitorInfo()}
            <ReactPaginate
              previousLabel={"previous"}
              nextLabel={"next"}
              breakLabel={"..."}
              breakClassName={"break-me"}
              pageCount={visitorOriginalArr?.length / 10}
              // marginPagesDisplayed={2}
              // pageRangeDisplayed={pages}
              onPageChange={this.handlePageClick}
              previousLinkClassName={"paginationPageBackNext"}
              nextLinkClassName={"paginationPageBackNext"}
              pageClassName={"paginationPage"}
              containerClassName={"pagination"}
              activeClassName={"paginationPageActive"}
            />
          </>
        ) : null}
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  options: state.options,
  user: state.user,
  auditLog: state.auditLog,
  userActions: state.userActions,
  getDeals: state.getDeals,
});

export default connect(mapStateToProps)(AuditLog);
