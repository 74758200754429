import React, { Component } from "react";
import { connect } from "react-redux";
import { Navbar, Nav } from "react-bootstrap";
import logo from "../../global/img/loggedInLogo.png";
import "./style.css";

class SignedInNavbar extends Component {
  render() {
    const { user, dispatch } = this.props;
    return (
      <div>
        <Navbar id="loggedInmenu" expand="lg">
          <Navbar.Brand
            className="loggedInNavbarBrand robotoFontBold hoverDelay"
            href="/dashboard"
          >
            DEAL ROOM
            <span className="navbarBrandRedUnderline"></span>
          </Navbar.Brand>
          {/* <Navbar.Toggle aria-controls="basic-navbar-nav"> */}
          <Navbar.Toggle>
            <div className="menuSpan"></div>
            <div className="menuSpan"></div>
            <div className="menuSpan"></div>
          </Navbar.Toggle>
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav>
              <img src={logo} alt="Logo" className="loggedInLogo" />
            </Nav>
            {user?.admin ? (
              <Nav.Link
                href="/admin"
                className="linkButton adminButton robotoFontBold hoverDelay"
              >
                ADMIN
                <span className="navbarBrandRedUnderline navbarBrandRedUnderlineAdmin"></span>
              </Nav.Link>
            ) : (
              ""
            )}
            <Nav.Link
              href="/"
              onClick={() => user?.login && dispatch({ type: "LOGOUT" })}
              className="linkButton logoutButton robotoFontBold hoverDelay"
            >
              {user?.login ? "LOGOUT" : "LOGIN"}
            </Nav.Link>
          </Navbar.Collapse>
        </Navbar>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  options: state.options,
  user: state.user,
  signup: state.signup,
});

export default connect(mapStateToProps)(SignedInNavbar);
