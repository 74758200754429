/* eslint-disable react/jsx-pascal-case */
import React, { Component } from "react";
import { connect } from "react-redux";
import { Control, Form, actions } from "react-redux-form";
import { Row, Col, Button } from "react-bootstrap";
import InputFields from "../InputFields";
import NavbarHeader from "../Navbar";
import Footer from "../Footer";
import Loading from "../Loading";
import PrivacyPolicy from "../../global/pdf/privacy-policy.pdf";
import "./style.css";

class Home extends Component {
  state = {
    forgotPassword: false,
  };

  handleLogin() {
    this.props.dispatch({ type: "LOGIN" });
  }

  handlePasswordReset() {
    this.props.dispatch({ type: "SEND_PASSWORD_RESET" });
  }

  render() {
    const { forgotPassword } = this.state;
    const { user, userActions, dispatch } = this.props;

    if (userActions?.isLoading) {
      return <Loading />;
    }

    if (user?.login && user?.email_verified && user?.user_verified) {
      window.location.href = "/dashboard";
    } else if (user?.login && (!user?.email_verified || !user?.user_verified)) {
      window.location.href = "/thankyou";
    }

    return (
      <React.Fragment>
        <div className="mainContainer mainContainerHeight">
          <NavbarHeader />
          <div id="background"></div>
          <div id="redBorderLine"></div>
          <Row className="homeRow">
            <Col lg={6} className="homeCol homeCol1">
              <div className="homeCol1Div">
                {!forgotPassword ? (
                  <React.Fragment>
                    <h1 className="generalHeader">Login</h1>
                    <span className="headerSpacer"></span>
                    <Form
                      model="userActions"
                      onSubmit={() =>
                        !forgotPassword
                          ? this.handleLogin()
                          : this.handlePasswordReset()
                      }
                    >
                      <Col lg={12} className="inputCol">
                        <InputFields
                          label="username"
                          type="email"
                          inputType="text"
                          model=".email"
                          value={userActions?.email}
                        />
                      </Col>
                      <Col lg={12} className="inputCol">
                        <InputFields
                          label="password"
                          type="password"
                          inputType="text"
                          model=".password"
                          value={userActions?.password}
                        />
                      </Col>
                      <Col
                        lg={12}
                        className="inputCol"
                        style={{ flexGrow: "1" }}
                      >
                        <a
                          href="#/"
                          title="Forgot Password"
                          className="forgotPasswordLink robotoFontRegular"
                          onClick={() => {
                            dispatch(actions.change("userActions", {}));
                            this.setState({ forgotPassword: !forgotPassword });
                          }}
                        >
                          {!forgotPassword
                            ? "Forgot Password?"
                            : "Back to Login"}
                        </a>
                        <Control.button
                          model="userActions"
                          className="generalButtonInverted submitButton"
                          type="submit"
                          disabled={{ valid: false }}
                        >
                          LOGIN
                        </Control.button>
                      </Col>
                    </Form>
                  </React.Fragment>
                ) : (
                  <React.Fragment>
                    <h1 className="generalHeader">Forgot Password</h1>
                    <span className="headerSpacer"></span>
                    <Form
                      model="passwordReset"
                      onSubmit={() =>
                        !forgotPassword
                          ? this.handleLogin()
                          : this.handlePasswordReset()
                      }
                    >
                      <Col lg={12} className="inputCol">
                        <InputFields
                          label="username"
                          type="email"
                          inputType="text"
                          model=".email"
                        />
                      </Col>
                      <Col
                        lg={12}
                        className="inputCol"
                        style={{ flexGrow: "1" }}
                      >
                        <a
                          href="#/"
                          title="Forgot Password"
                          className="forgotPasswordLink robotoFontRegular"
                          onClick={() => {
                            dispatch(actions.change("passwordReset", {}));
                            this.setState({ forgotPassword: !forgotPassword });
                          }}
                        >
                          {!forgotPassword
                            ? "Forgot Password?"
                            : "Back to Login"}
                        </a>
                        <Control.button
                          model="user"
                          className="generalButton submitButton"
                          type="submit"
                          disabled={{ valid: false }}
                        >
                          SUBMIT
                        </Control.button>
                      </Col>
                    </Form>
                  </React.Fragment>
                )}
              </div>
            </Col>
            <Col lg={6} className="homeCol homeCol2">
              <h1 className="generalHeader" style={{ color: "#707070" }}>
                Request Access
              </h1>
              <p className="requestAccessText robotoFontRegular">
                Whatever type of questions you’ll have, feel free to get in
                touch with us!
              </p>
              <Button
                href="/signup"
                className="generalButton"
                style={{ marginTop: "30px" }}
                type="submit"
              >
                SIGN UP
              </Button>
            </Col>
          </Row>
          <p className="homeDisclaimerText robotoFontRegular">
            *We don’t share your personal info with anyone.
            <br />
            Check out our{" "}
            <a
              href={PrivacyPolicy}
              target="_blank"
              className="anchorLink"
              rel="noreferrer"
            >
              PRIVACY POLICY
            </a>{" "}
            for more information.
          </p>
        </div>
        <Footer />
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  options: state.options,
  user: state.user,
  userActions: state.userActions,
  signup: state.signup,
  passwordReset: state.passwordReset,
  checkToken: state.checkToken,
});

export default connect(mapStateToProps)(Home);
