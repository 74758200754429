// MISC
export const ERRORS = "ERRORS";
export const NO_ERRORS = "NO_ERRORS";
export const CHECK_TOKEN = "CHECK_TOKEN";
export const CHECK_TOKEN_SUCCESS = "CHECK_TOKEN_SUCCESS";
export const CHECK_TOKEN_ERROR = "CHECK_TOKEN_ERROR";
export const GET_OPTIONS = "GET_OPTIONS";
export const GET_OPTIONS_SUCCESS = "GET_OPTIONS_SUCCESS";
export const GET_OPTIONS_ERROR = "GET_OPTIONS_ERROR";
export const GET_IP = "GET_IP";
export const GET_IP_SUCCESS = "GET_IP_SUCCESS";
export const GET_IP_ERROR = "GET_IP_ERROR";

// AUDIT
export const AUDIT = "AUDIT";
export const AUDIT_SUCCESS = "AUDIT_SUCCESS";
export const AUDIT_ERROR = "AUDIT_ERROR";
export const GET_AUDIT = "GET_AUDIT";
export const GET_AUDIT_SUCCESS = "GET_AUDIT_SUCCESS";
export const GET_AUDIT_ERROR = "GET_AUDIT_ERROR";

// SIGNUP/LOGINS
export const SIGNUP = "SIGNUP";
export const SIGNUP_SUCCESS = "SIGNUP_SUCCESS";
export const SIGNUP_ERROR = "SIGNUP_ERROR";
export const SEND_VERIFY_EMAIL = "SEND_VERIFY_EMAIL";
export const SEND_VERIFY_EMAIL_SUCCESS = "SEND_VERIFY_EMAIL_SUCCESS";
export const SEND_VERIFY_EMAIL_ERROR = "SEND_VERIFY_EMAIL_ERROR";
export const VERIFY_EMAIL = "VERIFY_EMAIL";
export const VERIFY_EMAIL_SUCCESS = "VERIFY_EMAIL_SUCCESS";
export const VERIFY_EMAIL_ERROR = "VERIFY_EMAIL_ERROR";
export const LOGIN = "LOGIN";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_ERROR = "LOGIN_ERROR";
export const LOGOUT = "LOGOUT";
export const LOGOUT_ERROR = "LOGOUT_ERROR";
export const SEND_PASSWORD_RESET = "SEND_PASSWORD_RESET";
export const SEND_PASSWORD_RESET_SUCCESS = "SEND_PASSWORD_RESET_SUCCESS";
export const SEND_PASSWORD_RESET_ERROR = "SEND_PASSWORD_RESET_ERROR";
export const VERIFY_PASSWORD_RESET = "VERIFY_PASSWORD_RESET";
export const VERIFY_PASSWORD_RESET_SUCCESS = "VERIFY_PASSWORD_RESET_SUCCESS";
export const VERIFY_PASSWORD_RESET_ERROR = "VERIFY_PASSWORD_RESET_ERROR";
export const PASSWORD_RESET = "PASSWORD_RESET";
export const PASSWORD_RESET_SUCCESS = "PASSWORD_RESET_SUCCESS";
export const PASSWORD_RESET_ERROR = "PASSWORD_RESET_ERROR";
// CREATE/EDIT DEALS
export const CREATE_DEAL = "CREATE_DEAL";
export const CREATE_DEAL_SUCCESS = "CREATE_DEAL_SUCCESS";
export const CREATE_DEAL_ERROR = "CREATE_DEAL_ERROR";
export const EDIT_DEAL = "EDIT_DEAL";
export const EDIT_DEAL_SUCCESS = "EDIT_DEAL_SUCCESS";
export const EDIT_DEAL_ERROR = "EDIT_DEAL_ERROR";
export const DELETE_DEAL = "DELETE_DEAL";
export const DELETE_DEAL_SUCCESS = "DELETE_DEAL_SUCCESS";
export const DELETE_DEAL_ERROR = "DELETE_DEAL_ERROR";
// AUDIT USER NOTES
export const HANDLE_AUDIT_NOTES = "HANDLE_AUDIT_NOTES";
export const HANDLE_AUDIT_NOTES_SUCCESS = "HANDLE_AUDIT_NOTES_SUCCESS";
export const HANDLE_AUDIT_NOTES_ERROR = "HANDLE_AUDIT_NOTES_ERROR";
// GET DEALS/LENDERS
export const GET_DEALS = "GET_DEALS";
export const GET_DEALS_SUCCESS = "GET_DEALS_SUCCESS";
export const GET_DEALS_ERROR = "GET_DEALS_ERROR";
export const GET_DEAL = "GET_DEAL";
export const GET_DEAL_SUCCESS = "GET_DEAL_SUCCESS";
export const GET_DEAL_ERROR = "GET_DEAL_ERROR";
// SIGNATURE SECTION
export const VERIFY_SIGNATURE = "VERIFY_SIGNATURE";
export const VERIFY_SIGNATURE_SUCCESS = "VERIFY_SIGNATURE_SUCCESS";
export const VERIFY_SIGNATURE_ERROR = "VERIFY_SIGNATURE_ERROR";
// MAIL
export const SEND_INTEREST = "SEND_INTEREST";
export const SEND_INTEREST_SUCCESS = "SEND_INTEREST_SUCCESS";
export const SEND_INTEREST_ERROR = "SEND_INTEREST_ERROR";
// GET USERS
export const APPROVE_USER = "APPROVE_USER";
export const APPROVE_USER_SUCCESS = "APPROVE_USER_SUCCESS";
export const APPROVE_USER_ERROR = "APPROVE_USER_ERROR";
export const DENY_USER = "DENY_USER";
export const DENY_USER_SUCCESS = "DENY_USER_SUCCESS";
export const DENY_USER_ERROR = "DENY_USER_ERROR";
// UPDATE USER
export const UPDATE_USER = "UPDATE_USER";
export const UPDATE_USER_SUCCESS = "UPDATE_USER_SUCCESS";
export const UPDATE_USER_ERROR = "UPDATE_USER_ERROR";
// DOCUMENT GROUPS
export const ADD_DOCUMENT_GROUP = "ADD_DOCUMENT_GROUP";
export const ADD_DOCUMENT_GROUP_SUCCESS = "ADD_DOCUMENT_GROUP_SUCCESS";
export const ADD_DOCUMENT_GROUP_ERROR = "ADD_DOCUMENT_GROUP_ERROR";
export const REMOVE_DOCUMENT_GROUP = "REMOVE_DOCUMENT_GROUP";
export const REMOVE_DOCUMENT_GROUP_SUCCESS = "REMOVE_DOCUMENT_GROUP_SUCCESS";
export const REMOVE_DOCUMENT_GROUP_ERROR = "REMOVE_DOCUMENT_GROUP_ERROR";
// DEAL AGENTS
export const HANDLE_AGENT = "HANDLE_AGENT";
export const HANDLE_AGENT_SUCCESS = "HANDLE_AGENT_SUCCESS";
export const HANDLE_AGENT_ERROR = "HANDLE_AGENT_ERROR";
// DEAL NDA
export const HANDLE_NDA = "HANDLE_NDA";
export const HANDLE_NDA_SUCCESS = "HANDLE_NDA_SUCCESS";
export const HANDLE_NDA_ERROR = "HANDLE_NDA_ERROR";
// CUSTOM LINK
export const CUSTOM_LINK = "CUSTOM_LINK";
export const CUSTOM_LINK_SUCCESS = "CUSTOM_LINK_SUCCESS";
export const CUSTOM_LINK_ERROR = "CUSTOM_LINK_ERROR";

// export const exampleSubmit = (payload) => ({
//   type: EXAMPLE_SUBMIT,
//   payload,
// });

// export default exampleSubmit;
