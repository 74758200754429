import React, { Component } from "react";
import { connect } from "react-redux";
import logo from "../../global/img/loggedInLogo.png";
import "./style.css";

class Footer extends Component {
  render() {
    const { dashboard } = this.props;
    return (
      <React.Fragment>
        {!dashboard ? (
          <div className="footerDiv">
            <h3 className="footerText robotoFontBold">
              Confidentiality and Disclaimer
            </h3>
            <p className="footerText robotoFontRegular">
              The information contained in the following Marketing Brochure is
              proprietary and strictly confidential. It is intended to be
              reviewed only by the party receiving it from Marcus & Millichap
              and should not be made available to any other person or entity
              without the written consent of Marcus & Millichap. This Marketing
              Brochure has been prepared to provide summary, unverified
              information to prospective purchasers, and to establish only a
              preliminary level of interest in the subject property. The
              information contained herein is not a substitute for a thorough
              due diligence investigation. Marcus & Millichap has not made any
              investigation, and makes no warranty or representation, with
              respect to the income or expenses for the subject property, the
              future projected financial performance of the property, the size
              and square footage of the property and improvements, the presence
              or absence of contaminating substances, PCB's or asbestos, the
              compliance with State and Federal regulations, the physical
              condition of the improvements thereon, or the financial condition
              or business prospects of any tenant, or any tenant’s plans or
              intentions to continue its occupancy of the subject property. The
              information contained in this Marketing Brochure has been obtained
              from sources we believe to be reliable; however, Marcus &
              Millichap has not verified, and will not verify, any of the
              information contained herein, nor has Marcus & Millichap conducted
              any investigation regarding these matters and makes no warranty or
              representation whatsoever regarding the accuracy or completeness
              of the information provided. All potential buyers must take
              appropriate measures to verify all of the information set forth
              herein.
            </p>
            <h3 className="footerText robotoFontBold">
              Non-Endorsement Notice
            </h3>
            <p className="footerText robotoFontRegular">
              Marcus & Millichap is not affiliated with, sponsored by, or
              endorsed by any commercial tenant or lessee identified in this
              marketing package. The presence of any corporation’s logo or name
              is not intended to indicate or imply affiliation with, or
              sponsorship or endorsement by, said corporation of Marcus &
              Millichap, its affiliates or subsidiaries, or any agent, product,
              service, or commercial listing of Marcus & Millichap, and is
              solely included for the purpose of providing tenant lessee
              information about this listing to prospective customers.
            </p>
            <h3 className="footerText robotoFontBold">
              Special COVID-19 Notice
            </h3>
            <p className="footerText robotoFontRegular">
              All potential buyers are strongly advised to take advantage of
              their opportunities and obligations to conduct thorough due
              diligence and seek expert opinions as they may deem necessary,
              especially given the unpredictable changes resulting from the
              continuing COVID-19 pandemic. Marcus & Millichap has not been
              retained to perform, and cannot conduct, due diligence on behalf
              of any prospective purchaser. Marcus & Millichap’s principal
              expertise is in marketing investment properties and acting as
              intermediaries between buyers and sellers. Marcus & Millichap and
              its investment professionals cannot and will not act as lawyers,
              accountants, contractors, or engineers. All potential buyers are
              admonished and advised to engage other professionals on legal
              issues, tax, regulatory, financial, and accounting matters, and
              for questions involving the property’s physical condition or
              financial outlook. Projections and pro forma financial statements
              are not guarantees and, given the potential volatility created by
              COVID-19, all potential buyers should be comfortable with and rely
              solely on their own projections, analyses, and decision-making.)
              ALL PROPERTY SHOWINGS ARE BY APPOINTMENT ONLY.
            </p>
            <h3 className="footerText robotoFontBold">
              PLEASE CONSULT YOUR MARCUS & MILLICHAP AGENT FOR MORE DETAILS.
            </h3>
            <img src={logo} alt="Logo" className="footerLogo" />
          </div>
        ) : (
          <div className="dashboardFooterDiv robotoFontBold">
            <h3 className="dashboardFooterText robotoFontBold">
              Confidentiality and Disclaimer
            </h3>
            <p className="dashboardFooterText robotoFontRegular">
              The information contained in the following Marketing Brochure is
              proprietary and strictly confidential. It is intended to be
              reviewed only by the party receiving it from Marcus & Millichap
              and should not be made available to any other person or entity
              without the written consent of Marcus & Millichap. This Marketing
              Brochure has been prepared to provide summary, unverified
              information to prospective purchasers, and to establish only a
              preliminary level of interest in the subject property. The
              information contained herein is not a substitute for a thorough
              due diligence investigation. Marcus & Millichap has not made any
              investigation, and makes no warranty or representation, with
              respect to the income or expenses for the subject property, the
              future projected financial performance of the property, the size
              and square footage of the property and improvements, the presence
              or absence of contaminating substances, PCB's or asbestos, the
              compliance with State and Federal regulations, the physical
              condition of the improvements thereon, or the financial condition
              or business prospects of any tenant, or any tenant’s plans or
              intentions to continue its occupancy of the subject property. The
              information contained in this Marketing Brochure has been obtained
              from sources we believe to be reliable; however, Marcus &
              Millichap has not verified, and will not verify, any of the
              information contained herein, nor has Marcus & Millichap conducted
              any investigation regarding these matters and makes no warranty or
              representation whatsoever regarding the accuracy or completeness
              of the information provided. All potential buyers must take
              appropriate measures to verify all of the information set forth
              herein.
            </p>
            <h3 className="dashboardFooterText robotoFontBold">
              Non-Endorsement Notice
            </h3>
            <p className="dashboardFooterText robotoFontRegular">
              Marcus & Millichap is not affiliated with, sponsored by, or
              endorsed by any commercial tenant or lessee identified in this
              marketing package. The presence of any corporation’s logo or name
              is not intended to indicate or imply affiliation with, or
              sponsorship or endorsement by, said corporation of Marcus &
              Millichap, its affiliates or subsidiaries, or any agent, product,
              service, or commercial listing of Marcus & Millichap, and is
              solely included for the purpose of providing tenant lessee
              information about this listing to prospective customers.
            </p>
            <h3 className="dashboardFooterText robotoFontBold">
              Special COVID-19 Notice
            </h3>
            <p className="dashboardFooterText robotoFontRegular">
              All potential buyers are strongly advised to take advantage of
              their opportunities and obligations to conduct thorough due
              diligence and seek expert opinions as they may deem necessary,
              especially given the unpredictable changes resulting from the
              continuing COVID-19 pandemic. Marcus & Millichap has not been
              retained to perform, and cannot conduct, due diligence on behalf
              of any prospective purchaser. Marcus & Millichap’s principal
              expertise is in marketing investment properties and acting as
              intermediaries between buyers and sellers. Marcus & Millichap and
              its investment professionals cannot and will not act as lawyers,
              accountants, contractors, or engineers. All potential buyers are
              admonished and advised to engage other professionals on legal
              issues, tax, regulatory, financial, and accounting matters, and
              for questions involving the property’s physical condition or
              financial outlook. Projections and pro forma financial statements
              are not guarantees and, given the potential volatility created by
              COVID-19, all potential buyers should be comfortable with and rely
              solely on their own projections, analyses, and decision-making.)
              ALL PROPERTY SHOWINGS ARE BY APPOINTMENT ONLY.
            </p>
            <h3 className="dashboardFooterText robotoFontBold">
              PLEASE CONSULT YOUR MARCUS & MILLICHAP AGENT FOR MORE DETAILS.
            </h3>
            <img src={logo} alt="Logo" className="footerLogo" />
          </div>
        )}
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  options: state.options,
  user: state.user,
  signup: state.signup,
});

export default connect(mapStateToProps)(Footer);
