import React, { Component } from "react";
import { connect } from "react-redux";
import { Alert } from "react-bootstrap";
import { actions } from "react-redux-form";
import "./style.css";

class AlertMessage extends Component {
  render() {
    const { alertMessage, dispatch } = this.props;
    const alertText = alertMessage?.message || alertMessage?.error;
    const error = alertMessage?.error;
    if (alertMessage?.message || alertMessage?.error) {
      setTimeout(function () {
        dispatch(actions.change("userActions", {}));
        dispatch(actions.change("alertMessage", {}));
      }, 5000);
    }
    if (alertText) {
      return (
        <Alert
          variant={error ? "danger" : "success"}
          className="alertDiv robotoFontRegular"
          onClose={() => {
            dispatch(actions.change("alertMessage", {}));
            dispatch(actions.change("userActions", {}));
          }}
          dismissible
        >
          {alertText}
        </Alert>
      );
    } else {
      return "";
    }
  }
}

const mapStateToProps = (state) => ({
  checkToken: state.checkToken,
  alertMessage: state.alertMessage,
});

export default connect(mapStateToProps)(AlertMessage);
