/* eslint-disable react/jsx-pascal-case */
import React, { Component } from "react";
import { connect } from "react-redux";
import { Control, Form } from "react-redux-form";
import { Col } from "react-bootstrap";
import InputFields from "../InputFields";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import "./style.css";

class Admin extends Component {
  render() {
    const { getDeals, handleDeleteDeal } = this.props;

    return (
      <Col lg={12}>
        <h1 className="generalHeader">Delete Deal</h1>
        <span className="headerSpacer"></span>
        <Form model="deleteDeal" onSubmit={() => handleDeleteDeal()}>
          <Col lg={12} className="inputCol">
            <p className="selectLabel">Select a deal to delete</p>
            <InputFields
              label="Edit Deal"
              customLabel={true}
              type="select"
              inputType="select"
              defaultOption="Select an deal"
              options={getDeals?.dealsOptions}
              model=".id"
              ignore={true}
            />
          </Col>
          <Col lg={12} className="inputCol" style={{ flexGrow: "1" }}>
            <Control.button
              model="deleteDeal"
              className="generalButton submitButton"
              type="submit"
            >
              Submit
            </Control.button>
          </Col>
        </Form>
      </Col>
    );
  }
}

const mapStateToProps = (state) => ({
  getDeals: state.getDeals,
  deleteDeal: state.deleteDeal,
});

export default connect(mapStateToProps)(Admin);
