import React, { Component } from "react";
import { connect } from "react-redux";
import NavbarHeader from "../Navbar";
import Footer from "../Footer";
import { Button } from "react-bootstrap";
// import thankyouImg from "./img/thankyou.png";
import Loading from "../Loading";
import "./style.css";

class ThankYou extends Component {
  componentDidMount() {
    const { user, dispatch } = this.props;
    const token = this?.props?.match?.params?.token;
    if (user && user?.login && user?.email_verified && user?.user_verified) {
      window.location.href = "/dashboard";
    }
    if (token) {
      dispatch({ type: "VERIFY_EMAIL", payload: token });
    }
  }
  render() {
    const { user, signup, dispatch } = this.props;

    const email_verified =
      (user?.login && user?.email_verified) ||
      (!user?.login && signup?.email_verified);

    const user_verified =
      (user?.login && user?.user_verified) ||
      (!user?.login && signup?.user_verified);

    if (signup?.isLoading) {
      return <Loading />;
    }

    return (
      <React.Fragment>
        <div className="mainContainer mainContainerHeight">
          <NavbarHeader />
          {/* <img src={thankyouImg} alt="Thank You" className="thankyouImg" /> */}
          <h1 className="generalHeader text-center">
            Thank you for registering with us.
          </h1>
          {email_verified && user_verified && (
            <p className="email_verifiedText robotoFontRegular">
              Your email has successfully been verified!
              <br />
              Please click "Login" below to sign in. Your username will be the
              email address you used to register.
            </p>
          )}
          {!email_verified && (
            <>
              <p className="thankyouText robotoFontRegular text-center">
                Please verify your email at the email address you provided us.
              </p>
              <div className="thankyouEmailDiv">
                <p className="thankyouEmailText robotoFontRegular">
                  Didn’t get the email?
                </p>
                <a
                  href="#/"
                  title="Resend email"
                  className="thankyouEmailLink robotoFontBold"
                  onClick={() => dispatch({ type: "SEND_VERIFY_EMAIL" })}
                >
                  RESEND EMAIL
                </a>
              </div>
            </>
          )}
          {email_verified && user_verified && (
            <div className="email_verifiedLoginDiv">
              <Button href="/login" className="generalButton">
                Login
              </Button>
            </div>
          )}
          {(!email_verified || !user_verified) && (
            <div className="email_verifiedLoginDiv">
              <Button
                href="/"
                onClick={() => dispatch({ type: "LOGOUT" })}
                className="generalButton"
              >
                Home
              </Button>
            </div>
          )}
        </div>
        <Footer />
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  options: state.options,
  user: state.user,
  signup: state.signup,
});

export default connect(mapStateToProps)(ThankYou);
