/* eslint-disable react/jsx-pascal-case */
import React, { Component } from "react";
import { connect } from "react-redux";
import { Control, Errors, actions } from "react-redux-form";
import "react-phone-number-input/style.css";
// import PhoneInput from "react-phone-number-input";
import PhoneInput from "react-phone-number-input/input";
import "./style.css";

class InputFields extends Component {
  constructor() {
    super();
    this.state = {
      isFocused: false,
      inputPadding: "15px",
      showPassword: false,
    };
  }

  handleFocus = () => this.setState({ isFocused: true });
  handleBlur = (m, v, option) => {
    if (!v?.value && !m?.target?.value) {
      this.setState({ isFocused: false, inputPadding: "15px" });
    }
    if (option === "currency") {
      if (v?.value && !v?.value?.includes("$")) {
        this.props.dispatch(actions.change(v?.model, `$${v?.value}`));
      }
    }
  };

  displayOptions(options) {
    const { defaultOption } = this.props;
    const optionArr = [
      <option key={0} value="">
        {defaultOption}
      </option>,
    ];
    options.map((data, i) => {
      return optionArr.push(
        <option key={++i} value={data.value}>
          {data.label}
        </option>
      );
    });
    return optionArr;
  }

  formatCurrency = (input) => {
    if (!input) return;
    this.handleFocus();
    const strInput = !isNaN(input) ? `$${input}` : input;
    if (isNaN(parseInt(strInput[strInput.length - 1], 10))) {
      return strInput?.slice(0, -1);
    }
    return strInput?.replace(/,/g, "").replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };

  normalizeAmount = (val) => {
    return val.replace(/,/g, "");
  };

  render() {
    const {
      model,
      standardLabel,
      label,
      checkboxLabel,
      mainLabel,
      type,
      options,
      inputType,
      value,
      customLabel,
      customValue,
      ignore,
      disabled,
      className,
      dispatch,
      onChange,
      placeholder,
    } = this.props;
    const { isFocused, inputPadding, showPassword } = this.state;

    const labelStyle = {
      position: "absolute",
      left: !customLabel && "0px",
      top: !isFocused && !standardLabel ? 40 : 5,
      fontSize: 14,
      paddingBottom: !isFocused && !standardLabel ? 0 : "10px",
      color: "#b5bbce",
      transitionDuration: ".4s",
      textTransform: "uppercase",
      fontFamily: "transat-text, sans-serif",
    };

    const labelRelativeStyle = {
      fontSize: 14,
      paddingBottom: !isFocused && !standardLabel ? 0 : "10px",
      color: "#b5bbce",
      transitionDuration: ".4s",
      textTransform: "uppercase",
      fontFamily: "transat-text, sans-serif",
    };

    // const labelStyle = {
    //   position: "absolute",
    //   left: !customLabel && "0px",
    //   top: !isFocused && !standardLabel ? 40 : 5,
    //   fontSize: 14,
    //   paddingBottom: !isFocused && !standardLabel ? 0 : "10px",
    //   color: "#b5bbce",
    //   transitionDuration: ".4s",
    //   textTransform: "uppercase",
    //   fontFamily: "transat-text, sans-serif",
    //   fontWeight: 700,
    // };

    const radioLabelStyle = {
      position: "absolute",
      left: !customLabel && "0px",
      top: "25px",
      fontSize: 14,
      paddingBottom: !isFocused && !standardLabel ? 0 : "10px",
      color: "#b5bbce",
      transitionDuration: ".4s",
      textTransform: "uppercase",
      fontFamily: "transat-text, sans-serif",
      fontWeight: 700,
    };

    const customLabelStyle = {
      position: "absolute",
      right: customLabel && "15px",
      top: !isFocused && !standardLabel ? 30 : 0,
      fontSize: !isFocused && !standardLabel ? 20 : 14,
      color: "#b5bbce",
      transitionDuration: ".4s",
      textTransform: "uppercase",
      fontFamily: "transat-text, sans-serif",
      fontWeight: 400,
      // backgroundColor: customLabel && "rgba(241, 241, 241, .58)",
      padding: customLabel && "5px",
      borderRadius: customLabel && "5px",
    };

    const email = (value) =>
      value && /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,10}$/i.test(value);
    const phone = (value) => value && /^[2-9]\d{2}[2-9]\d{2}\d{4}$/.test(value);
    // const phone = (value) =>
    //   value && /^\+[0-9]{1,3}\.[0-9]{4,14}(?:x.+)?$/.test(value);
    const date = (value) =>
      value && /^\d{4}-(0?[1-9]|1[012])-(0?[1-9]|[12][0-9]|3[01])$/.test(value);
    return (
      <React.Fragment>
        {inputType === "text" && (
          <React.Fragment>
            <label style={labelStyle}>
              {label}
              {!ignore && <span className="requiredField">*</span>}
            </label>
            <div style={{ display: type === "password" ? "flex" : "" }}>
              <Control.text
                className="formInputs poppinsLight hoverDelay"
                style={{ paddingBottom: inputPadding }}
                model={model}
                type={
                  type === "password"
                    ? showPassword
                      ? "text"
                      : "password"
                    : type
                }
                onFocus={this.handleFocus}
                onBlur={(m, v) => this.handleBlur(m, v)}
                onChange={onChange}
                value={value}
                validators={{
                  required: (val) =>
                    type !== "password" ? (!ignore ? val?.length : true) : true,
                  isValidEmail: (val) =>
                    type !== "email" ? true : !val?.length ? true : email(val),
                  isValidPassword: (val) =>
                    type !== "password" ? true : val?.length >= 6,
                }}
                disabled={disabled}
              />
              {type === "password" && (
                <a
                  href="#/"
                  onClick={() => this.setState({ showPassword: !showPassword })}
                  className="showPasswordIcon"
                  title={showPassword ? "Hide Password" : "Show Password"}
                  alt={showPassword ? "Hide Password" : "Show Password"}
                >
                  {showPassword ? (
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="20"
                      height="20"
                      fill="#FF7F32"
                      className="bi bi-eye-slash-fill"
                      viewBox="0 0 16 16"
                    >
                      <path d="m10.79 12.912-1.614-1.615a3.5 3.5 0 0 1-4.474-4.474l-2.06-2.06C.938 6.278 0 8 0 8s3 5.5 8 5.5a7.029 7.029 0 0 0 2.79-.588zM5.21 3.088A7.028 7.028 0 0 1 8 2.5c5 0 8 5.5 8 5.5s-.939 1.721-2.641 3.238l-2.062-2.062a3.5 3.5 0 0 0-4.474-4.474L5.21 3.089z" />
                      <path d="M5.525 7.646a2.5 2.5 0 0 0 2.829 2.829l-2.83-2.829zm4.95.708-2.829-2.83a2.5 2.5 0 0 1 2.829 2.829zm3.171 6-12-12 .708-.708 12 12-.708.708z" />
                    </svg>
                  ) : (
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="20"
                      height="20"
                      fill="#FF7F32"
                      className="bi bi-eye-fill"
                      viewBox="0 0 16 16"
                    >
                      <path d="M10.5 8a2.5 2.5 0 1 1-5 0 2.5 2.5 0 0 1 5 0z" />
                      <path d="M0 8s3-5.5 8-5.5S16 8 16 8s-3 5.5-8 5.5S0 8 0 8zm8 3.5a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7z" />
                    </svg>
                  )}
                </a>
              )}
            </div>
          </React.Fragment>
        )}
        {inputType === "phone" && (
          <React.Fragment>
            <label style={labelStyle}>
              {label}
              {!ignore && <span className="requiredField">*</span>}
            </label>
            <Control.text
              className="formInputs poppinsLight hoverDelay"
              style={{ paddingBottom: inputPadding }}
              model={model}
              type={type}
              onFocus={this.handleFocus}
              onBlur={(m, v) => this.handleBlur(m, v)}
              value={value}
              validators={{
                required: (val) => (type !== "password" ? val?.length : true),
                isValidPhone: (val) =>
                  type !== "tel" ? true : !val?.length ? true : phone(val),
              }}
              disabled={disabled}
            />
          </React.Fragment>
        )}
        {inputType === "customPhone" && (
          <React.Fragment>
            <div style={{ marginTop: "-2px" }} />
            <label style={labelStyle}>
              {label}
              {!ignore && <span className="requiredField">*</span>}
            </label>
            <PhoneInput
              className="formInputs poppinsLight hoverDelay"
              style={{ paddingBottom: inputPadding }}
              // placeholder="Enter phone number"
              onFocus={this.handleFocus}
              onBlur={(m, v) => this.handleBlur(m, v)}
              defaultCountry="US"
              value={value}
              onChange={(phone) => dispatch(actions.change(model, phone))}
            />
          </React.Fragment>
        )}
        {inputType === "date" && (
          <React.Fragment>
            <label style={labelStyle}>
              {label}
              {!ignore && <span className="requiredField">*</span>}
            </label>
            <Control.text
              className="formInputs poppinsLight hoverDelay"
              style={{
                paddingBottom: inputPadding,
                color: `${!isFocused ? "transparent" : "#000000"}`,
              }}
              model={model}
              type={type}
              min="1900-01-01"
              max="2030-12-31"
              onFocus={this.handleFocus}
              onBlur={(m, v) => this.handleBlur(m, v)}
              value={value}
              validators={{
                required: (val) => (type !== "password" ? val?.length : true),
                isValidDate: (val) => (!val?.length ? true : date(val)),
              }}
              disabled={disabled}
            />
          </React.Fragment>
        )}
        {inputType === "number" && (
          <React.Fragment>
            <label style={labelStyle}>
              {label}
              {!ignore && <span className="requiredField">*</span>}
            </label>
            <Control.text
              className="formInputs poppinsLight hoverDelay"
              style={{ paddingBottom: inputPadding }}
              model={model}
              type={type}
              value={value}
              onFocus={this.handleFocus}
              onBlur={(m, v) => this.handleBlur(m, v)}
              min={0}
              validators={{
                required: (val) => val?.length,
              }}
              disabled={disabled}
            />
          </React.Fragment>
        )}
        {inputType === "currency" && (
          <React.Fragment>
            <label style={labelStyle}>
              {label}
              {!ignore && <span className="requiredField">*</span>}
            </label>
            <Control.text
              className="formInputs poppinsLight hoverDelay"
              style={{ paddingBottom: inputPadding }}
              model={model}
              type={type}
              parser={this.formatCurrency}
              onFocus={this.handleFocus}
              onBlur={(m, v) => this.handleBlur(m, v, "currency")}
              validators={{
                required: (val) => (ignore ? true : val?.length),
              }}
              disabled={disabled}
            />
          </React.Fragment>
        )}
        {inputType === "textbox" && (
          <React.Fragment>
            {label ? (
              <label style={labelRelativeStyle}>
                {label}
                {!ignore && <span className="requiredField">*</span>}
              </label>
            ) : null}
            <Control.textarea
              className="formInputsTextArea poppinsLight hoverDelay"
              style={{ paddingBottom: inputPadding }}
              model={model}
              type={type}
              onFocus={this.handleFocus}
              onBlur={(m, v) => this.handleBlur(m, v)}
              placeholder={placeholder || "Please enter your comments here..."}
              disabled={disabled}
              validators={{
                required: (val) => (ignore ? true : val?.length),
              }}
            />
          </React.Fragment>
        )}
        {inputType === "select" && (
          <React.Fragment>
            {!customLabel && (
              <label style={labelStyle}>
                {label}
                {!ignore && <span className="requiredField">*</span>}
              </label>
            )}
            <Control.select
              className="formInputsSelect poppinsLight hoverDelay"
              style={{ paddingBottom: inputPadding }}
              model={model}
              type={type}
              value={value}
              validators={{
                required: (val) => (ignore ? true : val?.length),
              }}
              disabled={disabled}
              onChange={onChange}
            >
              {this.displayOptions(options)}
            </Control.select>
            {customLabel && (
              <label style={customLabelStyle}>
                {customLabel}
                <span className="" style={{ color: "#b5bbce" }}>
                  {" "}
                  {customValue?.toFixed(1)}
                </span>
              </label>
            )}
          </React.Fragment>
        )}
        {inputType === "checkbox" && (
          <React.Fragment>
            <label className="checkboxContainer">
              {checkboxLabel}
              <Control.checkbox
                id={value}
                // id="interest_receivable"
                model={model}
                value={value}
                style={{ paddingBottom: inputPadding }}
                disabled={disabled}
              />
              <span className="checkmark" />
            </label>
          </React.Fragment>
        )}
        {inputType === "radio" && (
          <React.Fragment>
            <label style={radioLabelStyle}>{mainLabel}</label>
            <Control.radio
              id={value}
              className="radio-custom"
              name="radio-group"
              model={model}
              value={value}
              style={{ paddingBottom: inputPadding }}
              disabled={disabled}
            />
            <label for={value} className={`radio-custom-label ${className}`}>
              {checkboxLabel}
            </label>
          </React.Fragment>
        )}
        <Errors
          className="formInputError robotoFontRegular"
          model={model}
          messages={{
            required: `Please provide a ${label}`,
            isValidEmail: "Please enter a valid email",
            isValidPhone: "Please enter a valid phone number",
            isValidPassword:
              "Your password is too short, must have at least 6 characters",
          }}
          show={{ touched: true, focus: false }}
        />
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.user,
  signup: state.signup,
});

export default connect(mapStateToProps)(InputFields);
