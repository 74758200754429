import React, { Component } from "react";
import { connect } from "react-redux";
import { Navbar } from "react-bootstrap";
import logo from "../../global/img/logo.png";
import "./style.css";

class NavbarHeader extends Component {
  render() {
    return (
      <div>
        <Navbar id="menu">
          <Navbar.Brand className="navbarBrand robotoFontBold" href="/">
            DEAL ROOM
          </Navbar.Brand>
          {/* <Nav className="mr-auto"></Nav> */}
          <Navbar.Collapse className="justify-content-end">
            <img src={logo} alt="Logo" className="logo" />
          </Navbar.Collapse>
        </Navbar>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  options: state.options,
  user: state.user,
  signup: state.signup,
});

export default connect(mapStateToProps)(NavbarHeader);
