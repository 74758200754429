/* eslint-disable react/jsx-pascal-case */
import React, { Component } from "react";
import { connect } from "react-redux";
import { Control, Form, actions } from "react-redux-form";
import { Col, Button } from "react-bootstrap";
import InputFields from "../InputFields";
import { Editor } from "react-draft-wysiwyg";
import {
  EditorState,
  convertToRaw,
  ContentState,
  convertFromHTML,
} from "draft-js";
import draftToHtml from "draftjs-to-html";
import { toTitleCase } from "../utils";
import Select from "react-select";
import CreatableSelect from "react-select/creatable";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import "./style.css";

class EditDeal extends Component {
  state = {
    editorState: EditorState.createEmpty(),
    ndaEditorState: EditorState.createEmpty(),
    documentName: null,
    subDocumentName: [],
    documentId: this.props.options?.document_types?.[0]?.value,
    subDocumentId: null,
    agentList: [],
    modifyAgent: false,
    addNewAgent: true,
  };

  onEditorStateChange: Function = (editorState) => {
    this.setState({
      editorState,
    });
    this.props.dispatch(
      actions.change(
        "editDeal.summary",
        draftToHtml(convertToRaw(editorState.getCurrentContent()))
      )
    );
  };

  onNdaEditorStateChange: Function = (ndaEditorState) => {
    this.setState({
      ndaEditorState,
    });
    this.props.dispatch(
      actions.change(
        "editDeal.nda_agreement",
        draftToHtml(convertToRaw(ndaEditorState.getCurrentContent()))
      )
    );
  };

  handleChange = (selectedOption, event, row) => {
    const { getDeals, dispatch, options } = this.props;

    // let selectedItems = row?.linked_document_categories?.split(",") || [];
    let selectedItems = row?.[event?.name]?.split(",") || [];
    selectedItems = selectedItems?.map((d) => parseInt(d, 10));

    const currentUserIndex = getDeals?.users
      ?.map((u) => {
        return u.id;
      })
      .indexOf(row?.id);

    if (event?.action?.toUpperCase() === "REMOVE-VALUE") {
      selectedItems = selectedItems?.filter(
        (d) => d !== event?.removedValue?.value
      );
      dispatch(
        actions.change(
          `getDeals.users[${currentUserIndex}].${[event?.name]}`,
          selectedItems?.join(",")
        )
      );

      // REMOVE SUBCATEGORY SECTION

      let subSelectedItems =
        row?.linked_sub_document_categories?.split(",") || [];
      subSelectedItems = subSelectedItems?.map((d) => parseInt(d, 10));
      const documentTypes = options?.document_types?.find(
        (d) => d.main_group_id === event?.removedValue?.value
      );
      if (documentTypes) {
        subSelectedItems = subSelectedItems?.filter(
          (s) => s !== documentTypes?.value
        );
        dispatch(
          actions.change(
            `getDeals.users[${currentUserIndex}].linked_sub_document_categories`,
            subSelectedItems?.join(",")
          )
        );
      }
    } else if (event?.action?.toUpperCase() === "SELECT-OPTION") {
      selectedOption?.map(
        (s) =>
          !selectedItems?.includes(s.value) &&
          !isNaN(s.value) &&
          selectedItems.push(s.value)
      );
      selectedItems = selectedItems?.filter((d) => !isNaN(d));
      dispatch(
        actions.change(
          `getDeals.users[${currentUserIndex}].${[event?.name]}`,
          selectedItems?.join(",")
        )
      );
    } else if (event?.action?.toUpperCase() === "CLEAR") {
      dispatch(
        actions.change(
          `getDeals.users[${currentUserIndex}].${[event?.name]}`,
          ""
        )
      );
    }
  };

  handleFileType(name, value) {
    this.setState({
      documentName: name,
      documentId: value,
    });
  }

  handleSubDirectory(name, value) {
    this.setState({
      documentName:
        this.state.documentName?.split("/")[1] === name
          ? this.state.documentName?.split("/")[0]
          : `${this.state.documentName}/${name}`,
      subDocumentId: this.state.subDocumentId === value ? null : value,
    });
  }

  selectDeal(dealID) {
    const { getDeals, dispatch } = this.props;
    if (dealID?.target?.value) {
      const dealObj = getDeals?.deals?.find(
        (x) => x.id === parseInt(dealID?.target?.value, 10)
      );
      if (dealObj) {
        dispatch(actions.change("editDeal", Object.assign(dealObj)));
        this.setState({
          editorState: EditorState.createWithContent(
            ContentState.createFromBlockArray(
              convertFromHTML(`${dealObj?.summary}`)
            )
          ),
          ndaEditorState: EditorState.createWithContent(
            ContentState.createFromBlockArray(
              convertFromHTML(`${dealObj?.nda_agreement}`)
            )
          ),
          agentList: dealObj?.agents,
        });
      }
    }
  }

  removeAgent = (id) => {
    const { agentList } = this.state;
    const { dispatch } = this.props;

    let agentArr = [...agentList];
    agentArr = agentArr?.map((a) => Object.assign({}, a));
    if (agentArr[id]?.id) {
      agentArr[id].remove = true;
    } else {
      agentArr.splice(id, 1);
    }
    this.setState({ agentList: agentArr });
    dispatch(actions.change(`editDeal.agents`, agentArr));
  };

  handleDocumentGroupChange = (newValue: any, actionMeta: any) => {
    // console.group("Value Changed");
    // console.log(newValue);
    // console.log(`action: ${actionMeta.action}`);
    // console.groupEnd();
    this.setState({
      documentName:
        // actionMeta.action?.toUpperCase() !== "CLEAR" ? newValue?.value : null,
        actionMeta.action?.toUpperCase() !== "CLEAR" ? newValue?.label : null,
    });
  };

  handleSubDocumentGroupChange = (
    selectedOption,
    event,
    type,
    mainDocument
  ) => {
    const selectedValues = [];
    selectedOption?.map(
      (s) => (s.main_document_name = mainDocument) && selectedValues?.push(s)
    );

    this.setState({
      subDocumentName: selectedValues,
    });
  };

  handlePublicCategory = (newValue: any, actionMeta: any) => {
    const { dispatch } = this.props;
    dispatch(actions.change("editDeal.public_groups.groups", newValue));
  };

  render() {
    const {
      editorState,
      ndaEditorState,
      documentName,
      subDocumentName,
      agentList,
      modifyAgent,
      addNewAgent,
    } = this.state;
    const {
      userActions,
      dispatch,
      options,
      getDeals,
      editDeal,
      handleEditDeal,
      removeImage,
      removeFile,
      removeExistingImage,
      removeExistingFile,
      ImageUploader,
      DocumentUploader,
      handleMultiSelectChange,
    } = this.props;

    const imageFiles = editDeal?.images_url?.length
      ? editDeal?.images_url?.split(",")
      : [];
    const documentFiles = editDeal?.documents;
    const documents = options?.document_types?.filter(
      (d) => d?.deal_id === editDeal?.id
    );

    const parseName = (name) => {
      const newName = name?.split("/");
      return newName[newName?.length - 1];
    };

    const showNewDocs = () => {
      let existingHtml = [];
      const typeArr = ["main", "sub"];
      for (let typeIndex = 0; typeIndex < typeArr.length; typeIndex++) {
        if (!editDeal?.files[typeArr[typeIndex]]) {
          continue;
        }
        for (const [key, value] of Object.entries(
          editDeal?.files[typeArr[typeIndex]]
        )) {
          existingHtml.push(
            <div key={key}>
              <Col lg={12}>
                <h4 className="existingFilesHeader robotoFontBold">
                  New {toTitleCase(key)} Files:
                </h4>
              </Col>
              {value?.map((v, i) => (
                <Col key={i} lg={6} className="fileCol">
                  <div className="fileButtonDiv robotoFontRegular">
                    <p className="fileButtonName">{parseName(v?.name)}</p>
                    <Button
                      className="fileCloseButton hoverDelay"
                      onClick={() => removeFile(v?.name, documentName)}
                    >
                      X
                    </Button>
                  </div>
                </Col>
              ))}
            </div>
          );
        }
      }
      return existingHtml;
    };

    const { users } = getDeals;

    let assignedUsers = [];
    // eslint-disable-next-line array-callback-return
    editDeal?.assigned_users?.split(",")?.map((u) => {
      const userID = parseInt(u, 10);
      const matchedUser = users?.find((e) => e?.id === userID);
      if (userID === 0) {
        const allUser = getDeals?.usersOptions?.find(
          (o) => parseInt(o?.value, 10) === userID
        );
        assignedUsers.push({ label: allUser?.label, value: allUser?.value });
      } else {
        assignedUsers.push({
          label: matchedUser?.email,
          value: matchedUser?.id,
        });
      }
    });

    const usersOptions = [];
    getDeals?.users?.map((x) =>
      usersOptions.push({
        label: `${x?.full_name} (${x?.email})`,
        value: x?.id,
      })
    );

    const selectedUser = getDeals?.users?.find(
      (u) =>
        parseInt(userActions?.assign_deals?.user_id, 10) ===
          parseInt(u?.id, 10) ||
        parseInt(userActions?.assign_groups?.user_id, 10) ===
          parseInt(u?.id, 10)
    );

    let assignedDocuments =
      selectedUser?.linked_document_categories?.split(",");
    let mainUserDocuments = [];
    if (assignedDocuments?.length && userActions?.assign_groups?.user_id) {
      assignedDocuments = assignedDocuments?.map((c) => parseInt(c, 10));
      mainUserDocuments = options?.document_types?.filter(
        (o) =>
          assignedDocuments?.includes(o?.value) &&
          o?.type?.toUpperCase() === "MAIN"
      );
    }

    let assignedSubDocuments =
      selectedUser?.linked_sub_document_categories?.split(",");
    let subUserDocuments = [];
    if (
      assignedDocuments?.length &&
      assignedSubDocuments?.length &&
      userActions?.assign_groups?.user_id
    ) {
      assignedSubDocuments = assignedSubDocuments?.map((c) => parseInt(c, 10));
      subUserDocuments = options?.document_types?.filter(
        (o) =>
          assignedSubDocuments?.includes(o?.value) &&
          o?.type?.toUpperCase() === "SUB" &&
          assignedDocuments?.includes(o?.main_group_id)
      );
    }

    return (
      <Col lg={12}>
        <h1 className="generalHeader">Edit Deal</h1>
        <span className="headerSpacer"></span>
        <Form model="editDeal" onSubmit={() => handleEditDeal()}>
          {getDeals?.dealsOptions && (
            <Col lg={12} className="inputCol">
              <p className="selectLabel">Select a deal name to edit</p>
              <InputFields
                label="Edit Deal"
                customLabel={true}
                type="select"
                inputType="select"
                defaultOption="Select an deal"
                options={getDeals?.dealsOptions}
                model="userActions.selectedDeal"
                ignore={true}
                onChange={(v) => this.selectDeal(v)}
                value={editDeal?.id}
              />
            </Col>
          )}
          {editDeal?.id && (
            <>
              <Col lg={12} className="inputCol">
                <InputFields
                  label="name"
                  type="text"
                  inputType="text"
                  model=".name"
                  value={editDeal?.name}
                  standardLabel={true}
                />
              </Col>
              <Col lg={12} className="inputCol">
                <InputFields
                  label="city"
                  type="text"
                  inputType="text"
                  model=".city"
                  value={editDeal?.city}
                  standardLabel={true}
                />
              </Col>
              <Col lg={12} className="inputCol">
                <p className="selectLabel">State</p>
                <InputFields
                  label="State"
                  defaultOption="Choose a state"
                  type="select"
                  customLabel={true}
                  inputType="select"
                  model=".state"
                  value={editDeal?.state}
                  options={options?.states || []}
                />
              </Col>
              <Col lg={12} className="inputCol">
                <InputFields
                  label="List Price"
                  type="text"
                  inputType="currency"
                  model=".list_price"
                  standardLabel={true}
                  value={editDeal?.list_price}
                  ignore={true}
                />
              </Col>
              <Col lg={12} className="inputCol">
                <InputFields
                  label="Property GLA"
                  type="text"
                  inputType="text"
                  model=".property_gla"
                  value={editDeal?.property_gla}
                  standardLabel={true}
                />
              </Col>
              <Col lg={12} className="inputCol">
                <p className="selectLabel">NDA Required?</p>
                <InputFields
                  label="NDA choice"
                  customLabel={true}
                  type="select"
                  inputType="select"
                  defaultOption="Select an option"
                  options={[
                    { label: "Yes", value: "1" },
                    { label: "No", value: "0" },
                  ]}
                  model=".nda_required"
                  value={editDeal?.nda_required}
                />
              </Col>
              {/* <Col lg={12} className="inputCol">
                <p className="selectLabel">Set to Active?</p>
                <InputFields
                  label="value"
                  customLabel={true}
                  type="select"
                  inputType="select"
                  defaultOption="Select an option"
                  options={[
                    { label: "Yes", value: "1" },
                    { label: "No", value: "0" },
                  ]}
                  model=".is_active"
                  value={editDeal?.is_active}
                />
              </Col> */}
              {editDeal?.nda_required === "1" ? (
                <Col lg={12} className="inputCol">
                  <p className="dropzoneLabel">NDA Agreement</p>
                  <Editor
                    editorState={ndaEditorState}
                    toolbarClassName="toolbarClassName"
                    wrapperClassName="wrapperClassName"
                    editorClassName="editorClassName"
                    onEditorStateChange={this.onNdaEditorStateChange}
                    toolbar={{
                      options: [
                        "inline",
                        "blockType",
                        "fontSize",
                        // "fontFamily",
                        "list",
                        "textAlign",
                        "history",
                        "image",
                      ],
                      inline: { inDropdown: true },
                      list: { inDropdown: true },
                      textAlign: { inDropdown: true },
                      link: { inDropdown: true },
                      history: { inDropdown: true },
                      // fontFamily: {
                      //   options: ["transat-textate"],
                      //   className: undefined,
                      //   component: undefined,
                      //   dropdownClassName: undefined,
                      // },
                      image: {
                        className: undefined,
                        component: undefined,
                        popupClassName: undefined,
                        urlEnabled: true,
                        uploadEnabled: true,
                        alignmentEnabled: true,
                        uploadCallback: undefined,
                        previewImage: true,
                        inputAccept:
                          "image/gif,image/jpeg,image/jpg,image/png,image/svg",
                        alt: { present: false, mandatory: false },
                        defaultSize: {
                          height: "auto",
                          width: "100%",
                        },
                      },
                    }}
                  />
                </Col>
              ) : null}
              <Col lg={12} className="inputCol">
                <p className="dropzoneLabel">Summary</p>
                <Editor
                  editorState={editorState}
                  toolbarClassName="toolbarClassName"
                  wrapperClassName="wrapperClassName"
                  editorClassName="editorClassName"
                  onEditorStateChange={this.onEditorStateChange}
                  toolbar={{
                    options: [
                      "inline",
                      "blockType",
                      "fontSize",
                      // "fontFamily",
                      "list",
                      "textAlign",
                      "history",
                      "image",
                    ],
                    inline: { inDropdown: true },
                    list: { inDropdown: true },
                    textAlign: { inDropdown: true },
                    link: { inDropdown: true },
                    history: { inDropdown: true },
                    // fontFamily: {
                    //   options: ["transat-textate"],
                    //   className: undefined,
                    //   component: undefined,
                    //   dropdownClassName: undefined,
                    // },
                    image: {
                      className: undefined,
                      component: undefined,
                      popupClassName: undefined,
                      urlEnabled: true,
                      uploadEnabled: true,
                      alignmentEnabled: true,
                      uploadCallback: undefined,
                      previewImage: true,
                      inputAccept:
                        "image/gif,image/jpeg,image/jpg,image/png,image/svg",
                      alt: { present: false, mandatory: false },
                      defaultSize: {
                        height: "auto",
                        width: "100%",
                      },
                    },
                  }}
                />
              </Col>
              <Col lg={12} className="inputCol">
                <p className="dropzoneLabel">Upload Images</p>
                <ImageUploader />
              </Col>
              {editDeal?.images?.length ? (
                <Col lg={12}>
                  <h4 className="existingFilesHeader robotoFontBold">
                    New Images:
                  </h4>
                </Col>
              ) : (
                ""
              )}
              {editDeal?.images?.map((file, i) => (
                <Col key={i} lg={6} className="fileCol">
                  <div className="fileButtonDiv robotoFontRegular">
                    <p className="fileButtonName">{file?.name}</p>
                    <Button
                      className="fileCloseButton hoverDelay"
                      onClick={() => removeImage(file?.name)}
                    >
                      X
                    </Button>
                  </div>
                </Col>
              ))}
              {imageFiles?.length ? (
                <Col lg={12}>
                  <h4 className="existingFilesHeader robotoFontBold">
                    Existing Images:
                  </h4>
                </Col>
              ) : (
                ""
              )}
              {imageFiles?.map((file, i) => (
                <Col key={i} lg={6} className="fileCol">
                  <div className="fileButtonDiv robotoFontRegular">
                    <p className="fileButtonName">
                      {decodeURI(file.split("/")[1])}
                    </p>
                    <Button
                      className="fileCloseButton hoverDelay"
                      onClick={() => removeExistingImage(file)}
                    >
                      X
                    </Button>
                  </div>
                </Col>
              ))}

              {/* File Category Section */}
              <Col lg={12} className="inputCol">
                <p className="dropzoneLabel">Upload Documents</p>
                <Col lg={6} className="inputCol">
                  <p className="robotoFontBold docTypeBtnGroupName">
                    Type in document group to upload files to:
                  </p>
                  <div
                    style={{
                      textAlign: "center",
                      cursor: "pointer",
                      lineHeight: "normal",
                      // width: "300px",
                    }}
                  >
                    <CreatableSelect
                      isClearable
                      name="document_name"
                      options={documents?.filter(
                        (d) => d?.type?.toUpperCase() === "MAIN"
                      )}
                      className="basic-multi-select"
                      classNamePrefix="select"
                      onChange={this.handleDocumentGroupChange}
                    />
                  </div>
                  {documentName ? (
                    <>
                      <br />
                      <p className="robotoFontBold docTypeBtnGroupName">
                        If wanted, type in sub-document group(s) to upload files
                        to:
                      </p>
                      <div
                        style={{
                          textAlign: "center",
                          cursor: "pointer",
                          lineHeight: "normal",
                          // width: "300px",
                        }}
                      >
                        <CreatableSelect
                          isMulti
                          isClearable
                          name={`files.${[documentName]}.sub_document_name`}
                          options={documents?.filter(
                            (d) =>
                              d?.type?.toUpperCase() === "SUB" &&
                              d?.main_group_name?.toUpperCase() ===
                                documentName?.toUpperCase()
                          )}
                          onChange={(a, b) =>
                            this.handleSubDocumentGroupChange(
                              a,
                              b,
                              "editDeal",
                              documentName
                            )
                          }
                          // onChange={this.handleChange}
                          // onInputChange={this.handleInputChange}
                          // styles={colourStyles}
                          styles={{
                            multiValueLabel: (base) => ({
                              ...base,
                              backgroundColor: "#FF7F32",
                              color: "white",
                              fontFamily: "transat-text",
                              fontWeight: 900,
                            }),
                          }}
                        />
                      </div>
                    </>
                  ) : null}
                </Col>

                {documentName ? (
                  <>
                    <br />
                    {DocumentUploader(documentName, subDocumentName)}
                  </>
                ) : null}
              </Col>

              {/* New Documents */}

              {editDeal?.files ? showNewDocs() : null}

              {/* Existing Documents */}

              {documentFiles?.length ? (
                <Col lg={12}>
                  <h4 className="existingFilesHeader robotoFontBold">
                    Existing Documents:
                  </h4>
                </Col>
              ) : (
                ""
              )}
              {documentFiles?.map((file, i) => (
                <Col key={i} lg={6} className="fileCol">
                  <div className="fileButtonDiv robotoFontRegular">
                    <p className="fileButtonName">
                      <b>
                        {`${
                          file?.category_name
                            ? toTitleCase(decodeURI(file?.category_name)) + ":"
                            : file?.main_document_name
                            ? file?.main_document_name + ":"
                            : toTitleCase(file?.name?.split("/")[0]) + ":"
                        }`}
                      </b>{" "}
                      {parseName(decodeURI(file?.name))}
                    </p>
                    <Button
                      className="fileCloseButton hoverDelay"
                      onClick={() => removeExistingFile(file, documentName)}
                    >
                      X
                    </Button>
                  </div>
                </Col>
              ))}
              <br />
              <Col lg={12} style={{ marginTop: "60px" }}>
                <hr />
                <br />
                <div className="adminHeaderDiv">
                  <h1 className="generalHeader">Assign users to deal</h1>
                </div>
                <Col lg={6} className="inputCol">
                  <div
                    style={{
                      textAlign: "center",
                      cursor: "pointer",
                      lineHeight: "normal",
                      // width: "300px",
                    }}
                  >
                    <Select
                      value={assignedUsers}
                      isMulti
                      name="assigned_users"
                      options={getDeals?.usersOptions}
                      className="basic-multi-select"
                      classNamePrefix="select"
                      onChange={(a, b) =>
                        handleMultiSelectChange(a, b, "editDeal")
                      }
                      // styles={colourStyles}
                      styles={{
                        multiValueLabel: (base) => ({
                          ...base,
                          backgroundColor: "#FF7F32",
                          color: "white",
                          fontFamily: "transat-text",
                          fontWeight: 900,
                        }),
                      }}
                    />
                  </div>
                </Col>
              </Col>
              {documents?.length ? (
                <>
                  <Col lg={12} style={{ marginTop: "60px" }}>
                    <div className="adminHeaderDiv">
                      <h1 className="generalHeader">
                        Assign document groups to user
                      </h1>
                    </div>
                    <Col lg={6} className="inputCol">
                      <p className="selectLabel">
                        Select a user below to begin assigning groups
                      </p>
                      <InputFields
                        label="Select a user below to begin assigning groups"
                        customLabel={true}
                        type="select"
                        inputType="select"
                        defaultOption="Select user"
                        options={usersOptions}
                        model="userActions.assign_groups.user_id"
                        ignore={true}
                      />
                    </Col>
                    {userActions?.assign_groups?.user_id && selectedUser ? (
                      <Col lg={6} className="inputCol">
                        <div
                          style={{
                            textAlign: "center",
                            cursor: "pointer",
                            lineHeight: "normal",
                            // width: "300px",
                          }}
                        >
                          <h4
                            className="subHeader robotoFontBold"
                            style={{ textAlign: "left" }}
                          >
                            Main group
                          </h4>
                          <Select
                            value={mainUserDocuments}
                            isMulti
                            name="linked_document_categories"
                            options={documents?.filter(
                              (d) =>
                                d?.type?.toUpperCase() === "MAIN" &&
                                d?.deal_id === editDeal?.id
                            )}
                            className="basic-multi-select"
                            classNamePrefix="select"
                            onChange={(a, b) =>
                              this.handleChange(a, b, selectedUser)
                            }
                            // styles={colourStyles}
                            styles={{
                              multiValueLabel: (base) => ({
                                ...base,
                                backgroundColor: "#FF7F32",
                                color: "white",
                                fontFamily: "transat-text",
                                fontWeight: 900,
                              }),
                            }}
                          />
                          <>
                            <h4
                              className="subHeader robotoFontBold"
                              style={{ marginTop: "30px", textAlign: "left" }}
                            >
                              Sub group
                            </h4>
                            <Select
                              value={subUserDocuments}
                              isMulti
                              name="linked_sub_document_categories"
                              options={documents?.filter(
                                (d) =>
                                  d?.type?.toUpperCase() === "SUB" &&
                                  assignedDocuments?.includes(d?.main_group_id)
                              )}
                              className="basic-multi-select"
                              classNamePrefix="select"
                              onChange={(a, b) =>
                                this.handleChange(a, b, selectedUser)
                              }
                              // styles={colourStyles}
                              styles={{
                                multiValueLabel: (base) => ({
                                  ...base,
                                  backgroundColor: "#FF7F32",
                                  color: "white",
                                  fontFamily: "transat-text",
                                  fontWeight: 900,
                                }),
                              }}
                            />
                          </>
                        </div>
                      </Col>
                    ) : null}
                    <Col lg={12} style={{ marginTop: "60px" }}>
                      <div className="adminHeaderDiv">
                        <h1 className="generalHeader">
                          Select groups to modify public status
                        </h1>
                      </div>
                      <br />
                      <Select
                        // value={editDeal?.public_groups}
                        isMulti
                        name="public_groups"
                        options={documents?.filter(
                          (d) =>
                            d?.type?.toUpperCase() === "MAIN" &&
                            d?.deal_id === editDeal?.id
                        )}
                        className="basic-multi-select"
                        classNamePrefix="select"
                        onChange={(a, b) => this.handlePublicCategory(a, b)}
                        // styles={colourStyles}
                        styles={{
                          multiValueLabel: (base) => ({
                            ...base,
                            backgroundColor: "#FF7F32",
                            color: "white",
                            fontFamily: "transat-text",
                            fontWeight: 900,
                          }),
                        }}
                      />
                      <br />
                      <InputFields
                        label="Make group public"
                        customLabel={true}
                        type="select"
                        inputType="select"
                        defaultOption="Make group public?"
                        options={[
                          { label: "Yes", value: "1" },
                          { label: "No", value: "0" },
                        ]}
                        model=".public_groups.is_public"
                        ignore={true}
                      />
                    </Col>
                  </Col>
                </>
              ) : null}
              <Col lg={12} style={{ marginTop: "60px" }}>
                <div className="adminHeaderDiv">
                  <h1 className="generalHeader">Modify listing agents</h1>
                </div>
                <div>
                  {/* <div className="adminHeaderDiv">
                      <h1 className="generalHeader">Add Listing Agents</h1>
                    </div> */}
                  <div>
                    <Col lg={6} className="inputCol">
                      {editDeal?.agents?.map(
                        (d, i) =>
                          !d?.remove && (
                            <div key={i}>
                              <p className="robotoFontBold">
                                <b>Agent {i + 1}</b>
                              </p>
                              <div className="agentList robotoFontRegular">
                                <p>
                                  <b>Name: </b>
                                  {d?.name}, <b>Email: </b>
                                  {d?.email}, <b>Phone: </b>
                                  {d?.phone}, <b>License: </b>
                                  {d?.license}
                                </p>
                                <Button
                                  className="fileCloseButton"
                                  onClick={() => this.removeAgent(i)}
                                >
                                  X
                                </Button>
                                <Button
                                  className="fileCloseButton"
                                  onClick={() => {
                                    dispatch(
                                      actions.change("userActions.agents", d)
                                    );
                                    this.setState({
                                      modifyAgent: true,
                                      addNewAgent: false,
                                    });
                                  }}
                                >
                                  Modify?
                                </Button>
                              </div>
                              <hr />
                            </div>
                          )
                      )}
                    </Col>
                    {
                      addNewAgent || modifyAgent ? (
                        <>
                          <Col lg={6} className="inputCol">
                            <InputFields
                              label="name"
                              type="text"
                              inputType="text"
                              model="userActions.agents.name"
                              value={userActions?.agents?.name}
                              standardLabel={true}
                            />
                          </Col>
                          <Col lg={6} className="inputCol">
                            <InputFields
                              label="title"
                              type="text"
                              inputType="text"
                              model="userActions.agents.title"
                              value={userActions?.agents?.title}
                              standardLabel={true}
                            />
                          </Col>
                          <Col lg={6} className="inputCol">
                            <InputFields
                              label="email"
                              type="email"
                              inputType="text"
                              model="userActions.agents.email"
                              value={userActions?.agents?.email}
                              standardLabel={true}
                            />
                          </Col>
                          <Col lg={6} className="inputCol">
                            <InputFields
                              label="phone"
                              type="tel"
                              inputType="customPhone"
                              model="userActions.agents.phone"
                              value={userActions?.agents?.phone}
                              standardLabel={true}
                            />
                          </Col>
                          <Col lg={6} className="inputCol">
                            <InputFields
                              label="License Number"
                              type="text"
                              inputType="text"
                              model="userActions.agents.license"
                              value={userActions?.agents?.license}
                              standardLabel={true}
                            />
                          </Col>
                          <Col
                            lg={12}
                            className="inputCol"
                            style={{ flexGrow: "1" }}
                          >
                            {modifyAgent ? (
                              <>
                                <Button
                                  className="generalButton robotoFontBold"
                                  style={{ marginRight: "15px" }}
                                  onClick={() => {
                                    let agentArr = [...agentList];
                                    // eslint-disable-next-line array-callback-return
                                    agentArr = agentArr.map((a) => {
                                      console.log(
                                        a?.id === userActions?.agents?.id
                                      );
                                      if (a?.id === userActions?.agents?.id) {
                                        a = userActions?.agents;
                                      }
                                      return a;
                                    });
                                    this.setState({
                                      agentList: agentArr,
                                    });
                                    dispatch(
                                      actions.change(
                                        `editDeal.agents`,
                                        agentArr
                                      )
                                    );
                                    dispatch(
                                      actions.change(`userActions.agents`, {})
                                    );
                                    this.setState({
                                      modifyAgent: false,
                                      addNewAgent: false,
                                    });
                                  }}
                                >
                                  MODIFY AGENT
                                </Button>
                                <Button
                                  className="generalButton robotoFontBold"
                                  onClick={() => {
                                    this.setState({
                                      modifyAgent: false,
                                    });
                                    setTimeout(() => {
                                      this.setState({
                                        addNewAgent: true,
                                      });
                                    }, 50);
                                    dispatch(
                                      actions.change(`userActions.agents`, {})
                                    );
                                    dispatch(
                                      actions.change(
                                        `userActions.agents.name`,
                                        null
                                      )
                                    );
                                  }}
                                >
                                  ADD AGENT?
                                </Button>
                              </>
                            ) : (
                              <Button
                                className="generalButton robotoFontBold"
                                onClick={() => {
                                  let agentArr = [...agentList];
                                  let newAgent = Object.assign(
                                    {},
                                    userActions.agents,
                                    { add: true }
                                  );
                                  agentArr.push(newAgent);
                                  this.setState({
                                    agentList: agentArr,
                                  });
                                  dispatch(
                                    actions.change(`editDeal.agents`, agentArr)
                                  );
                                  dispatch(
                                    actions.change(`userActions.agents`, {})
                                  );
                                }}
                              >
                                ADD AGENT
                              </Button>
                            )}
                          </Col>
                        </>
                      ) : null
                      // <Button
                      //   className="generalButton robotoFontBold"
                      //   onClick={() =>
                      //     this.setState({
                      //       modifyAgent: false,
                      //       addNewAgent: true,
                      //     })
                      //   }
                      // >
                      //   ADD NEW AGENT?
                      // </Button>
                    }
                  </div>
                </div>
              </Col>
              <Col lg={12} className="inputCol" style={{ flexGrow: "1" }}>
                <Control.button
                  model="editDeal"
                  className="generalButton submitButton"
                  type="submit"
                  // disabled={{ valid: false }}
                >
                  SUBMIT
                </Control.button>
              </Col>
            </>
          )}
        </Form>
      </Col>
    );
  }
}

const mapStateToProps = (state) => ({
  userActions: state.userActions,
  options: state.options,
  getDeals: state.getDeals,
  editDeal: state.editDeal,
});

export default connect(mapStateToProps)(EditDeal);
