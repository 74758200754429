import { put, takeLeading, all, call, select } from "redux-saga/effects";
import * as selectors from "./selectors";
import axios from "axios";
import { actions } from "react-redux-form";
import {
  // ERRORS,
  // NO_ERRORS,
  AUDIT,
  AUDIT_SUCCESS,
  AUDIT_ERROR,
  GET_AUDIT,
  GET_AUDIT_SUCCESS,
  GET_AUDIT_ERROR,
  CHECK_TOKEN,
  CHECK_TOKEN_SUCCESS,
  CHECK_TOKEN_ERROR,
  GET_OPTIONS,
  GET_OPTIONS_SUCCESS,
  GET_OPTIONS_ERROR,
  GET_IP,
  GET_IP_SUCCESS,
  GET_IP_ERROR,
  SIGNUP,
  // SIGNUP_SUCCESS,
  SIGNUP_ERROR,
  SEND_VERIFY_EMAIL,
  SEND_VERIFY_EMAIL_SUCCESS,
  SEND_VERIFY_EMAIL_ERROR,
  VERIFY_EMAIL,
  VERIFY_EMAIL_SUCCESS,
  VERIFY_EMAIL_ERROR,
  LOGIN,
  LOGIN_SUCCESS,
  LOGIN_ERROR,
  LOGOUT,
  LOGOUT_ERROR,
  SEND_PASSWORD_RESET,
  SEND_PASSWORD_RESET_SUCCESS,
  SEND_PASSWORD_RESET_ERROR,
  VERIFY_PASSWORD_RESET,
  VERIFY_PASSWORD_RESET_SUCCESS,
  VERIFY_PASSWORD_RESET_ERROR,
  PASSWORD_RESET,
  PASSWORD_RESET_SUCCESS,
  PASSWORD_RESET_ERROR,
  GET_DEAL,
  GET_DEAL_SUCCESS,
  GET_DEAL_ERROR,
  GET_DEALS,
  GET_DEALS_SUCCESS,
  GET_DEALS_ERROR,
  CREATE_DEAL,
  CREATE_DEAL_SUCCESS,
  CREATE_DEAL_ERROR,
  EDIT_DEAL,
  EDIT_DEAL_SUCCESS,
  EDIT_DEAL_ERROR,
  DELETE_DEAL,
  DELETE_DEAL_SUCCESS,
  DELETE_DEAL_ERROR,
  // VERIFY_SIGNATURE,
  // VERIFY_SIGNATURE_SUCCESS,
  // VERIFY_SIGNATURE_ERROR,
  SEND_INTEREST,
  SEND_INTEREST_SUCCESS,
  SEND_INTEREST_ERROR,
  APPROVE_USER,
  APPROVE_USER_SUCCESS,
  APPROVE_USER_ERROR,
  ADD_DOCUMENT_GROUP,
  ADD_DOCUMENT_GROUP_SUCCESS,
  ADD_DOCUMENT_GROUP_ERROR,
  REMOVE_DOCUMENT_GROUP,
  REMOVE_DOCUMENT_GROUP_SUCCESS,
  REMOVE_DOCUMENT_GROUP_ERROR,
  DENY_USER,
  DENY_USER_SUCCESS,
  DENY_USER_ERROR,
  UPDATE_USER,
  UPDATE_USER_SUCCESS,
  UPDATE_USER_ERROR,
  HANDLE_AGENT,
  HANDLE_AGENT_SUCCESS,
  HANDLE_AGENT_ERROR,
  HANDLE_NDA,
  HANDLE_NDA_SUCCESS,
  HANDLE_NDA_ERROR,
  CUSTOM_LINK,
  CUSTOM_LINK_SUCCESS,
  CUSTOM_LINK_ERROR,
  HANDLE_AUDIT_NOTES,
  HANDLE_AUDIT_NOTES_SUCCESS,
  HANDLE_AUDIT_NOTES_ERROR,
} from "./constants";
import { request } from "../components/utils/index.js";
// import { pickBy, keys, result } from "lodash";

function* audit(action) {
  const user = yield select(selectors.user);
  if (!user?.admin) {
    const signup = yield select(selectors.signup);
    // const ipAddr = yield select(selectors.ipAddr);

    const { user_action, description, deal_id, document_id, fileAudits } =
      action.payload;

    const user_id = user?.id;
    const username = user?.email || signup?.email || "guest";
    // const ip_address = ipAddr?.ip;

    let result;
    // if (!ip_address) {
    //   yield put({
    //     type: AUDIT_ERROR,
    //     payload: { error: result?.data?.error },
    //   });
    // } else {
    try {
      if (fileAudits?.length) {
        for (let f = 0; f < fileAudits?.length; f++) {
          result = yield call(request, "/audit", {
            method: "POST",
            cache: "no-cache",
            headers: {
              "cache-control": "no-cache",
              "Content-Type": "application/json",
              "x-access-token": `${user.token}`,
            },
            body: JSON.stringify({
              user_id,
              username,
              action: user_action,
              description: fileAudits[f]?.description,
              deal_id: fileAudits[f]?.deal_id,
              document_id: fileAudits[f]?.document_id,
            }),
          });
        }
      } else {
        result = yield call(request, "/audit", {
          method: "POST",
          cache: "no-cache",
          headers: {
            "cache-control": "no-cache",
            "Content-Type": "application/json",
            "x-access-token": `${user.token}`,
          },
          body: JSON.stringify({
            user_id,
            username,
            // ip_address,
            action: user_action,
            description,
            deal_id,
            document_id,
          }),
        });
      }
      if (result?.data?.message) {
        yield put({
          type: AUDIT_SUCCESS,
          payload: { message: result?.data?.message },
        });
      } else {
        yield put({
          type: AUDIT_ERROR,
          payload: { error: result?.data?.error },
        });
      }
    } catch (error) {
      console.log(error);
      yield put({
        type: AUDIT_ERROR,
        payload: { error: result?.data?.error },
      });
    }
  }
}

function* getAuditLog(action) {
  const user = yield select(selectors.user);

  let result;
  try {
    result = yield call(request, `/admin/auditlogs/${user.id}`, {
      method: "GET",
      cache: "no-cache",
      headers: {
        "cache-control": "no-cache",
        "Content-Type": "application/json",
        "x-access-token": `${user.token}`,
      },
    });
    if (result?.data?.message) {
      yield put({
        type: GET_AUDIT_SUCCESS,
        payload: { message: result?.data?.message, logs: result?.data?.logs },
      });
    } else {
      yield put({
        type: GET_AUDIT_ERROR,
        payload: { error: result?.data?.error },
      });
    }
  } catch (error) {
    console.log(error);
    yield put({
      type: GET_AUDIT_ERROR,
      payload: { error: result?.data?.error },
    });
  }
}

function* approveUser(action) {
  const user = yield select(selectors.user);
  const { id, email, verified } = action.payload;
  let result;
  try {
    result = yield call(request, "/approveUser", {
      method: "POST",
      cache: "no-cache",
      headers: {
        "cache-control": "no-cache",
        "Content-Type": "application/json",
        "x-access-token": `${user.token}`,
      },
      body: JSON.stringify({ id, email, verified }),
    });
    if (result?.data?.message) {
      yield put({
        type: !verified ? APPROVE_USER_SUCCESS : DENY_USER_SUCCESS,
        payload: { message: result?.data?.message },
      });
      yield put({
        type: GET_DEALS,
        payload: { getUsers: true },
      });
      if (!verified) {
        yield put({
          type: AUDIT,
          payload: {
            user_action: `APPROVE USER`,
            description: ``,
          },
        });
      } else {
        yield put({
          type: AUDIT,
          payload: {
            user_action: `DENY USER`,
            description: ``,
          },
        });
      }
      yield put(actions.change("alertMessage.message", result?.data?.message));
    } else {
      yield put({
        type: !verified ? APPROVE_USER_ERROR : DENY_USER_ERROR,
        payload: { error: result?.data?.error },
      });
      yield put(actions.change("alertMessage.error", result?.data?.error));
    }
  } catch (error) {
    console.log(error);
    yield put({
      type: !verified ? APPROVE_USER_ERROR : DENY_USER_ERROR,
      payload: { error: result?.data?.error },
    });
    yield put(actions.change("alertMessage.error", result?.data?.error));
  }
}

function* checkToken() {
  let user = yield select(selectors.user);
  let result;
  try {
    result = yield call(request, `/checkToken/${user?.id}`, {
      method: "GET",
      cache: "no-cache",
      headers: {
        "cache-control": "no-cache",
        "Content-Type": "application/json",
        "x-access-token": `${user.token}`,
      },
    });
    if (result?.data?.message) {
      user = { ...user, ...result?.data?.user };
      yield put({
        type: CHECK_TOKEN_SUCCESS,
        payload: { message: result?.data?.message, user },
      });
    } else {
      yield put({
        type: CHECK_TOKEN_ERROR,
        payload: { error: result?.data?.error },
      });
      yield put({ type: "LOGOUT" });
    }
  } catch (error) {
    console.log(error);
    yield put({
      type: CHECK_TOKEN_ERROR,
      payload: { error: result?.data?.error },
    });
  }
}

function* getOptions() {
  try {
    const data = yield call(request, "/options", {
      method: "GET",
      cache: "no-cache",
      headers: {
        "cache-control": "no-cache",
        "Content-Type": "application/json",
      },
    });
    yield put({
      type: GET_OPTIONS_SUCCESS,
      payload: data,
    });
  } catch (error) {
    yield put({
      type: GET_OPTIONS_ERROR,
    });
    console.log(error);
  }
}

function* getIP() {
  let result;
  try {
    result = yield call(request, "/getIP", {
      method: "GET",
      cache: "no-cache",
      headers: {
        "cache-control": "no-cache",
        "Content-Type": "application/json",
      },
    });
    if (result?.data?.message) {
      yield put({
        type: GET_IP_SUCCESS,
        payload: { ip: result.data.IP },
      });
    } else {
      yield put({
        type: GET_IP_ERROR,
        payload: { message: result?.data?.error },
      });
    }
  } catch (error) {
    console.log(error);
    yield put({
      type: GET_IP_ERROR,
      payload: { message: result?.data?.error },
    });
  }
}

function* signup() {
  const signup = yield select(selectors.signup);
  let result;
  try {
    result = yield call(request, "/signup", {
      method: "POST",
      cache: "no-cache",
      headers: {
        "cache-control": "no-cache",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(signup),
    });
    if (result?.data?.message) {
      yield put(actions.change("userActions.email", signup?.email));
      yield put(actions.change("userActions.password", signup?.password));
      // yield put({
      //   type: SIGNUP_SUCCESS,
      // });
      yield put({
        type: LOGIN,
      });
      yield put(actions.change("user.id", result?.data?.id));
      yield put({
        type: AUDIT,
        payload: {
          user_action: `NEW SIGNUP`,
          description: `${signup?.email}`,
        },
      });

      // COMMENT OUT EMAIL VERIFICATION TEMPORARILY

      // yield put(actions.change("alertMessage.message", result?.data?.message));
      // try {
      //   result = yield call(request, "/mail/sendVerifyEmail", {
      //     method: "POST",
      //     cache: "no-cache",
      //     headers: {
      //       "cache-control": "no-cache",
      //       "Content-Type": "application/json",
      //     },
      //     body: JSON.stringify(signup),
      //   });
      //   if (result?.data?.message) {
      //     yield put({
      //       type: SEND_VERIFY_EMAIL_SUCCESS,
      //       payload: { message: result?.data?.message },
      //     });
      //     yield put(
      //       actions.change("alertMessage.message", result?.data?.message)
      //     );
      //   }
      // } catch (error) {
      //   if (result?.data?.error) {
      //     yield put({
      //       type: SEND_VERIFY_EMAIL_ERROR,
      //       payload: { message: result?.data?.error },
      //     });
      //     yield put(actions.change("alertMessage.error", result?.data?.error));
      //   }
      // }
    } else {
      yield put({
        type: SIGNUP_ERROR,
        payload: { message: result?.data?.error },
      });
    }
    yield put(actions.change("alertMessage.error", result?.data?.error));
  } catch (error) {
    console.log(error);
    if (result?.data?.error) {
      yield put({
        type: SIGNUP_ERROR,
        payload: { message: result?.data?.error },
      });
      yield put(actions.change("alertMessage.error", result?.data?.error));
    }
  }
}

function* sendVerifyEmail() {
  const user = yield select(selectors.user);
  let result;
  try {
    result = yield call(request, "/mail/sendVerifyEmail", {
      method: "POST",
      cache: "no-cache",
      headers: {
        "cache-control": "no-cache",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(user),
    });
    if (result?.data?.message) {
      yield put({
        type: SEND_VERIFY_EMAIL_SUCCESS,
        message: result?.data?.message,
      });
      yield put(actions.change("alertMessage.message", result?.data?.message));
    } else {
      yield put({
        type: SEND_VERIFY_EMAIL_ERROR,
        payload: { message: result?.data?.error },
      });
      yield put(actions.change("alertMessage.error", result?.data?.error));
    }
  } catch (error) {
    console.log(error);
  }
}

function* verifyEmail(action) {
  const token = action.payload;
  let result;
  try {
    result = yield call(request, "/verifyEmail", {
      method: "POST",
      cache: "no-cache",
      headers: {
        "cache-control": "no-cache",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ token }),
    });
    if (result?.data?.message) {
      yield put({
        type: VERIFY_EMAIL_SUCCESS,
        message: result?.data?.message,
      });
      yield put(actions.change("alertMessage.message", result?.data?.message));
    } else {
      yield put({
        type: VERIFY_EMAIL_ERROR,
        payload: { message: result?.data?.error },
      });
      yield put(actions.change("alertMessage.error", result?.data?.error));
    }
  } catch (error) {
    console.log(error);
    yield put({
      type: VERIFY_EMAIL_ERROR,
      payload: { message: result?.data?.error },
    });
    yield put(actions.change("alertMessage.error", result?.data?.error));
  }
}

function* sendPasswordReset() {
  const passwordReset = yield select(selectors.passwordReset);
  let result;
  try {
    result = yield call(request, "/mail/sendPasswordReset", {
      method: "POST",
      cache: "no-cache",
      headers: {
        "cache-control": "no-cache",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ passwordReset }),
    });
    if (result?.data?.message) {
      yield put({
        type: SEND_PASSWORD_RESET_SUCCESS,
        payload: {
          message: result?.data?.message,
          email: passwordReset?.email,
        },
      });
      yield put(actions.change("alertMessage.message", result?.data?.message));
    } else {
      yield put({
        type: SEND_PASSWORD_RESET_ERROR,
        payload: { message: result?.data?.error },
      });
      yield put(actions.change("alertMessage.error", result?.data?.error));
    }
  } catch (error) {
    console.log(error);
    yield put({
      type: SEND_PASSWORD_RESET_ERROR,
      payload: { message: result?.data?.error },
    });
    yield put(actions.change("alertMessage.error", result?.data?.error));
  }
}

function* verifyPasswordReset(action) {
  const token = action.payload;
  let result;
  try {
    result = yield call(request, "/verifyPasswordReset", {
      method: "POST",
      cache: "no-cache",
      headers: {
        "cache-control": "no-cache",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ token }),
    });
    if (result?.data?.message) {
      yield put({
        type: VERIFY_PASSWORD_RESET_SUCCESS,
        payload: { message: result?.data?.message, email: result.data.email },
      });
      yield put(actions.change("alertMessage.message", result?.data?.message));
    } else {
      yield put({
        type: VERIFY_PASSWORD_RESET_ERROR,
        payload: { message: result?.data?.error },
      });
      yield put(actions.change("alertMessage.error", result?.data?.error));
    }
  } catch (error) {
    console.log(error);
    yield put({
      type: VERIFY_PASSWORD_RESET_ERROR,
      payload: { message: result?.data?.error },
    });
  }
}

function* passwordReset() {
  const passwordReset = yield select(selectors.passwordReset);
  let result;
  try {
    result = yield call(request, "/passwordReset", {
      method: "POST",
      cache: "no-cache",
      headers: {
        "cache-control": "no-cache",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ passwordReset }),
    });
    if (result?.data?.message) {
      yield put({
        type: PASSWORD_RESET_SUCCESS,
        payload: { message: result?.data?.message },
      });
      // yield put({
      //   type: AUDIT,
      //   payload: {
      //     user_action: `PASSWORD RESET: ${passwordReset?.email}`,
      //     description: ``,
      //   },
      // });
      yield put(actions.change("alertMessage.message", result?.data?.message));
    } else {
      yield put({
        type: PASSWORD_RESET_ERROR,
        payload: { message: result?.data?.error },
      });
      yield put(actions.change("alertMessage.error", result?.data?.error));
    }
  } catch (error) {
    console.log(error);
    yield put({
      type: PASSWORD_RESET_ERROR,
      payload: { message: result?.data?.error },
    });
    yield put(actions.change("alertMessage.error", result?.data?.error));
  }
}

function* login() {
  // const user = yield select(selectors.user);
  const userActions = yield select(selectors.userActions);
  const customLink = yield select(selectors.customLink);
  let result;
  try {
    result = yield call(request, "/login", {
      method: "POST",
      cache: "no-cache",
      headers: {
        "cache-control": "no-cache",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(userActions),
    });
    if (result?.data?.message) {
      yield put({
        type: LOGIN_SUCCESS,
        payload: {
          ...result?.data,
        },
      });
      if (
        customLink?.custom_url &&
        customLink?.token &&
        customLink?.custom_link &&
        result?.data?.id
      ) {
        window.location.href = customLink?.custom_url;
      } else {
        window.location.href = "/dashboard";
      }

      // yield put({
      //   type: AUDIT,
      //   payload: {
      //     user_action: `LOGIN: ${user?.email}`,
      //     description: ``,
      //   },
      // });
      if (!result.data.email_verified) {
        yield put({
          type: SEND_VERIFY_EMAIL,
        });
        // COMMENT OUT EMAIL VERIFICATION TEMPORARILY
        // window.location.href = "/thankyou";
      } else {
        if (
          customLink?.custom_url &&
          customLink?.token &&
          customLink?.custom_link &&
          result?.data?.id
        ) {
          try {
            result = yield call(assignDeal, result?.data, customLink?.token);
            window.location.href = customLink?.custom_url;
          } catch (error) {
            // console.log(error);
            window.location.href = "/dashboard";
          }
        } else {
          // COMMENT OUT EMAIL VERIFICATION TEMPORARILY
          // window.location.href = "/dashboard";
        }
      }
    } else if (result?.data?.error) {
      yield put({
        type: LOGIN_ERROR,
        payload: { message: result?.data?.error },
      });
      yield put(actions.change("alertMessage.error", result?.data?.error));
    }
  } catch (error) {
    console.log(error);
    yield put({
      type: LOGIN_ERROR,
      payload: { message: result?.data?.error },
    });
    yield put(actions.change("alertMessage.error", result?.data?.error));
  }
}

function* logout() {
  try {
    yield call(request, "/logout", {
      method: "GET",
    });
    window.location.href = "/";
  } catch (error) {
    yield put({
      type: LOGOUT_ERROR,
      payload: "Unable to logout",
    });
  }
}

async function uploadFiles(formData, user) {
  let result;
  const options = {
    headers: { "x-access-token": `${user.token}` },
  };
  await axios
    .post("/admin/upload/files", formData, options)
    .then(async (res) => {
      //Now do what you want with the response;
      if (res.data.message) {
        result = res?.data;
        return result;
      }
    });
  return result;
}

async function assignDeal(user, token) {
  const options = {
    method: "POST",
    cache: "no-cache",
    headers: {
      "cache-control": "no-cache",
      "Content-Type": "application/json",
      "x-access-token": `${user?.token}`,
    },
    body: JSON.stringify({
      user_id: user?.id,
      token,
    }),
  };
  return fetch("/deal/assign", options);
}

function* getDeal(action) {
  const user = yield select(selectors.user);
  const options = yield select(selectors.options);
  const customLink = yield select(selectors.customLink);
  const documentTypes = options?.document_types;
  const { dealID } = action.payload;
  let result;
  try {
    if (
      customLink?.custom_url &&
      customLink?.token &&
      customLink?.custom_link &&
      user?.login
    ) {
      try {
        result = yield call(assignDeal, user, customLink?.token);
      } catch (error) {
        console.log(error);
      }
    }
    result = yield call(request, `/getdeal/${dealID}`, {
      method: "POST",
      cache: "no-cache",
      headers: {
        "cache-control": "no-cache",
        "Content-Type": "application/json",
        "x-access-token": `${user?.login ? user?.token : customLink?.token}`,
      },
      body: JSON.stringify({ user, documentTypes }),
    });
    if (result?.data?.message) {
      yield put({
        type: GET_DEAL_SUCCESS,
        payload: {
          message: result?.data?.message,
          deal: result.data.deal,
          dsToken: result.data.dsToken,
        },
      });
      yield put({
        type: AUDIT,
        payload: {
          user_action: `VIEW DEAL`,
          deal_id: dealID,
        },
      });
    } else {
      yield put({
        type: GET_DEAL_ERROR,
        payload: { message: result?.data?.error },
      });
      // window.location.href = "/dashboard";
    }
  } catch (error) {
    console.log(error);
    yield put({
      type: GET_DEAL_ERROR,
      payload: { message: result?.data?.error },
    });
    // window.location.href = "/dashboard";
  }
}

function* getDeals(action) {
  const user = yield select(selectors.user);
  const options = yield select(selectors.options);
  const documentTypes = options?.document_types;
  const getUsers = action?.payload?.getUsers;
  let result;
  try {
    result = yield call(request, "/admin/deals/get", {
      method: "POST",
      cache: "no-cache",
      headers: {
        "cache-control": "no-cache",
        "Content-Type": "application/json",
        "x-access-token": `${user.token}`,
      },
      body: JSON.stringify({ user, getUsers, documentTypes }),
    });
    if (result?.data?.message) {
      yield put({
        type: GET_DEALS_SUCCESS,
        payload: {
          // message: result?.data?.message,
          // deals: result.data.deals,
          // dealsOptions: result.data.dealsOptions,
          // users: result.data.users,
          ...result?.data,
        },
      });
    } else {
      yield put({
        type: GET_DEALS_ERROR,
        payload: { message: result?.data?.error },
      });
    }
  } catch (error) {
    console.log(error);
  }
}

function* createDeals() {
  const user = yield select(selectors.user);
  const createDeal = yield select(selectors.createDeal);
  if (createDeal?.nda_required === "0") {
    createDeal.nda_agreement = null;
  }
  let formData = new FormData();
  let { images, files } = createDeal;
  let result;

  try {
    result = yield call(request, "/admin/deals/create", {
      method: "POST",
      cache: "no-cache",
      headers: {
        "cache-control": "no-cache",
        "Content-Type": "application/json",
        "x-access-token": `${user.token}`,
      },
      body: JSON.stringify({ createDeal, user }),
    });
    let createDealSuccess = !!result.data.id;
    if (images.length && createDealSuccess) {
      for (const file of images) {
        formData.append("files", file);
      }
      formData.append("projectName", JSON.stringify(createDeal.name));
      formData.append("id", JSON.stringify(result.data.id));
      formData.append("folder", JSON.stringify("images"));
      formData.append("email", JSON.stringify(user.email));
      yield call(uploadFiles, formData, user);
    }

    if (files && createDealSuccess) {
      let uploadedArr = [];
      const typeArr = ["main", "sub"];
      for (let typeIndex = 0; typeIndex < typeArr.length; typeIndex++) {
        if (!files[typeArr[typeIndex]]) {
          if (files?.sub) {
            for (const [key] of Object.entries(files?.sub)) {
              yield call(request, "/admin/documenttypes", {
                method: "POST",
                cache: "no-cache",
                headers: {
                  "cache-control": "no-cache",
                  "Content-Type": "application/json",
                  "x-access-token": `${user.token}`,
                },
                body: JSON.stringify({
                  addDoc: true,
                  name: key?.split("/")[0],
                  deal_id: result.data.id,
                  isSubDoc: false,
                  mainDocName: null,
                }),
              });
            }
          }
          continue;
        }
        for (const [key, value] of Object.entries(files[typeArr[typeIndex]])) {
          formData = new FormData();
          let documentIds = [];
          const uniqueDocs = [];
          let documentTypeResult;
          for (let f = 0; f < value?.length; f++) {
            if (!uniqueDocs?.includes(key)) {
              uniqueDocs.push(key);
              documentTypeResult = yield call(request, "/admin/documenttypes", {
                method: "POST",
                cache: "no-cache",
                headers: {
                  "cache-control": "no-cache",
                  "Content-Type": "application/json",
                  "x-access-token": `${user.token}`,
                },
                body: JSON.stringify({
                  addDoc: true,
                  name: key,
                  deal_id: result.data.id,
                  isSubDoc: value[f]?.sub,
                  mainDocName: value[f]?.main,
                }),
              });
            }
            formData.append("files", value[f]);
            documentIds.push({
              documentId: documentTypeResult?.data?.DocumentTypesModel?.id,
              // documentId: value[f]?.documentId,
              // subDocumentId: value[f]?.subDocumentId,
              isSubDoc: value[f]?.sub,
              mainDocName: value[f]?.main,
            });
          }
          formData.append("projectName", JSON.stringify(createDeal.name));
          formData.append("id", JSON.stringify(result.data.id));
          formData.append("folder", JSON.stringify(key));
          formData.append("email", JSON.stringify(user.email));
          formData.append("documentIds", JSON.stringify(documentIds));
          if (uploadedArr?.length) {
            formData.append("dataArr", JSON.stringify(uploadedArr));
          }
          const uploadResult = yield call(uploadFiles, formData, user);
          if (uploadResult?.dataArr?.length) {
            uploadedArr = uploadResult?.dataArr;
          }
        }
      }
    }

    if (result?.data?.message) {
      yield put({
        type: GET_DEALS,
        payload: { getUsers: true },
      });
      yield put({
        type: CREATE_DEAL_SUCCESS,
        payload: { message: result?.data?.message },
      });
      yield put(actions.change("alertMessage.message", result?.data?.message));
    } else {
      yield put({
        type: CREATE_DEAL_ERROR,
        payload: { message: result?.data?.error },
      });
      yield put(actions.change("alertMessage.error", result?.data?.error));
    }
  } catch (error) {
    console.log(error);
    yield put({
      type: CREATE_DEAL_ERROR,
      payload: { message: result?.data?.error },
    });
    yield put(actions.change("alertMessage.error", result?.data?.error));
  }
}

function* editDeals() {
  const user = yield select(selectors.user);
  const userActions = yield select(selectors.userActions);
  let editDeal = yield select(selectors.editDeal);
  if (editDeal?.nda_required === "0") {
    editDeal.nda_agreement = null;
  }
  let formData = new FormData();
  let { images, files } = editDeal;
  let result;
  try {
    result = yield call(request, "/admin/deals/edit", {
      method: "POST",
      cache: "no-cache",
      headers: {
        "cache-control": "no-cache",
        "Content-Type": "application/json",
        "x-access-token": `${user.token}`,
      },
      body: JSON.stringify({ editDeal, user }),
    });
    // this.props.dispatch({
    //   type: "UPDATE_USER",
    //   payload: {
    //     user_id: userActions?.assign_groups?.user_id,
    //   },
    // })
    if (userActions?.assign_groups?.user_id) {
      yield put({
        type: UPDATE_USER,
        payload: {
          user_id: userActions?.assign_groups?.user_id,
        },
      });
    }
    if (result?.data?.message) {
      if (images?.length) {
        for (const file of images) {
          formData.append("files", file);
        }
        formData.append("id", JSON.stringify(editDeal.id));
        formData.append("projectName", JSON.stringify(editDeal.name));
        formData.append("folder", JSON.stringify("images"));
        formData.append("email", JSON.stringify(user.email));
        formData.append("images_url", JSON.stringify(editDeal.images_url));
        yield call(uploadFiles, formData, user);
      }

      if (files) {
        let uploadedArr = [];
        const typeArr = ["main", "sub"];
        for (let typeIndex = 0; typeIndex < typeArr.length; typeIndex++) {
          if (!files[typeArr[typeIndex]]) {
            if (files?.sub) {
              for (const [key] of Object.entries(files?.sub)) {
                yield call(request, "/admin/documenttypes", {
                  method: "POST",
                  cache: "no-cache",
                  headers: {
                    "cache-control": "no-cache",
                    "Content-Type": "application/json",
                    "x-access-token": `${user.token}`,
                  },
                  body: JSON.stringify({
                    addDoc: true,
                    name: key?.split("/")[0],
                    deal_id: result.data.id,
                    isSubDoc: false,
                    mainDocName: null,
                  }),
                });
              }
            }
            continue;
          }
          for (const [key, value] of Object.entries(
            files[typeArr[typeIndex]]
          )) {
            formData = new FormData();
            let documentIds = [];
            const uniqueDocs = [];
            let documentTypeResult;
            for (let f = 0; f < value?.length; f++) {
              if (!uniqueDocs?.includes(key) && !value[f]?.existing) {
                uniqueDocs.push(key);
                documentTypeResult = yield call(
                  request,
                  "/admin/documenttypes",
                  {
                    method: "POST",
                    cache: "no-cache",
                    headers: {
                      "cache-control": "no-cache",
                      "Content-Type": "application/json",
                      "x-access-token": `${user.token}`,
                    },
                    body: JSON.stringify({
                      addDoc: true,
                      name: key,
                      deal_id: result.data.id,
                      isSubDoc: value[f]?.sub,
                      mainDocName: value[f]?.main,
                    }),
                  }
                );
              }
              formData.append("files", value[f]);
              documentIds.push({
                documentId:
                  documentTypeResult?.data?.DocumentTypesModel?.id ||
                  value[f]?.id,
                // documentId: value[f]?.documentId,
                // subDocumentId: value[f]?.subDocumentId,
                isSubDoc: value[f]?.sub,
                mainDocName: value[f]?.main,
              });
            }
            formData.append("projectName", JSON.stringify(editDeal.name));
            formData.append("id", JSON.stringify(result.data.id));
            formData.append("folder", JSON.stringify(key));
            formData.append("email", JSON.stringify(user.email));
            formData.append("documentIds", JSON.stringify(documentIds));
            if (uploadedArr?.length) {
              formData.append("dataArr", JSON.stringify(uploadedArr));
            }
            const uploadResult = yield call(uploadFiles, formData, user);
            if (uploadResult?.dataArr?.length) {
              uploadedArr = uploadResult?.dataArr;
            }
          }
        }
      }
      yield put({
        type: GET_DEALS,
        payload: { getUsers: true },
      });
      yield put({
        type: EDIT_DEAL_SUCCESS,
        payload: { message: result?.data?.message },
      });
      yield put(actions.change("alertMessage.message", result?.data?.message));
    } else {
      yield put({
        type: EDIT_DEAL_ERROR,
        payload: { message: result?.data?.error },
      });
      yield put(actions.change("alertMessage.error", result?.data?.error));
    }
  } catch (error) {
    console.log(error);
    yield put({
      type: EDIT_DEAL_ERROR,
      payload: { message: result?.data?.error },
    });
    yield put(actions.change("alertMessage.error", result?.data?.error));
  }
}

function* deleteDeals() {
  const user = yield select(selectors.user);
  const deleteDeal = yield select(selectors.deleteDeal);
  let result;
  try {
    result = yield call(request, "/admin/deals/delete", {
      method: "POST",
      cache: "no-cache",
      headers: {
        "cache-control": "no-cache",
        "Content-Type": "application/json",
        "x-access-token": `${user.token}`,
      },
      body: JSON.stringify({ dealID: deleteDeal.id, user }),
    });
    if (result?.data?.message) {
      yield put({
        type: DELETE_DEAL_SUCCESS,
        payload: { message: result?.data?.message },
      });
      yield put(actions.change("alertMessage.message", result?.data?.message));
      yield put({
        type: GET_DEALS,
      });
    } else {
      yield put({
        type: DELETE_DEAL_ERROR,
        payload: { message: result?.data?.error },
      });
      yield put(actions.change("alertMessage.error", result?.data?.error));
    }
  } catch (error) {
    console.log(error);
    yield put({
      type: DELETE_DEAL_ERROR,
      payload: { message: result?.data?.error },
    });
    yield put(actions.change("alertMessage.error", result?.data?.error));
  }
}

function* sendInterest(action) {
  const user = yield select(selectors.user);
  const deal = action.payload;
  let result;
  try {
    result = yield call(request, "/mail/sendInterest", {
      method: "POST",
      cache: "no-cache",
      headers: {
        "cache-control": "no-cache",
        "Content-Type": "application/json",
        "x-access-token": `${user.token}`,
      },
      body: JSON.stringify({ deal: deal, user }),
    });
    if (result?.data?.message) {
      yield put({
        type: SEND_INTEREST_SUCCESS,
        payload: { message: result?.data?.message },
      });
      yield put(actions.change("alertMessage.message", result?.data?.message));
    } else {
      yield put({
        type: SEND_INTEREST_ERROR,
        payload: { message: result?.data?.error },
      });
      yield put(actions.change("alertMessage.error", result?.data?.error));
    }
  } catch (error) {
    console.log(error);
    yield put({
      type: SEND_INTEREST_ERROR,
      payload: { message: result?.data?.error },
    });
    yield put(actions.change("alertMessage.error", result?.data?.error));
  }
}

function* handleDocumentGroups(action) {
  const user = yield select(selectors.user);
  const { deleteDoc, modifyDoc, documentId, document, addSubDocOnly, subDoc } =
    action.payload;
  let result;
  try {
    result = yield call(request, "/admin/documentgroups", {
      method: "POST",
      cache: "no-cache",
      headers: {
        "cache-control": "no-cache",
        "Content-Type": "application/json",
        "x-access-token": `${user.token}`,
      },
      body: JSON.stringify({
        deleteDoc,
        modifyDoc,
        documentId,
        document,
        addSubDocOnly,
        subDoc,
      }),
    });
    if (result?.data?.message) {
      if (!deleteDoc) {
        yield put({
          type: ADD_DOCUMENT_GROUP_SUCCESS,
          payload: { message: result?.data?.message },
        });
      } else {
        yield put({
          type: REMOVE_DOCUMENT_GROUP_SUCCESS,
          payload: { message: result?.data?.message },
        });
      }
      yield put({
        type: "GET_OPTIONS",
      });
      yield put(actions.change("alertMessage.message", result?.data?.message));
    } else {
      if (!deleteDoc) {
        yield put({
          type: ADD_DOCUMENT_GROUP_ERROR,
          payload: { message: result?.data?.error },
        });
      } else {
        yield put({
          type: REMOVE_DOCUMENT_GROUP_ERROR,
          payload: { message: result?.data?.error },
        });
      }
      yield put(actions.change("alertMessage.error", result?.data?.error));
    }
  } catch (error) {
    console.log(error);
    if (!deleteDoc) {
      yield put({
        type: ADD_DOCUMENT_GROUP_ERROR,
        payload: { message: result?.data?.error },
      });
    } else {
      yield put({
        type: REMOVE_DOCUMENT_GROUP_ERROR,
        payload: { message: result?.data?.error },
      });
    }
    yield put(actions.change("alertMessage.error", result?.data?.error));
  }
}

function* updateUser(action) {
  const user = yield select(selectors.user);
  let { users } = yield select(selectors.getDeals);
  const { user_id } = action.payload;
  const updateUser = users?.find((u) => u?.id === parseInt(user_id, 10));
  let result;
  try {
    if (updateUser) {
      result = yield call(request, "/admin/user/update", {
        method: "POST",
        cache: "no-cache",
        headers: {
          "cache-control": "no-cache",
          "Content-Type": "application/json",
          "x-access-token": `${user.token}`,
        },
        body: JSON.stringify({
          user: updateUser,
        }),
      });
    }
    if (result?.data?.message) {
      yield put({
        type: UPDATE_USER_SUCCESS,
        payload: { message: result?.data?.message },
      });
      yield put({
        type: GET_DEALS,
        payload: { getUsers: true },
      });
      yield put(actions.change("alertMessage.message", result?.data?.message));
    } else {
      yield put({
        type: UPDATE_USER_ERROR,
        payload: { message: result?.data?.error },
      });
      yield put(actions.change("alertMessage.error", result?.data?.error));
    }
  } catch (error) {
    console.log(error);
    yield put({
      type: UPDATE_USER_ERROR,
      payload: { message: result?.data?.error },
    });
    yield put(actions.change("alertMessage.error", result?.data?.error));
  }
}

function* handleAgent(action) {
  const user = yield select(selectors.user);
  const { deal_agents } = yield select(selectors.userActions);
  const { deal_id, agent_id, add, remove, modify } = action.payload;
  let result;
  try {
    result = yield call(request, "/admin/agent", {
      method: "POST",
      cache: "no-cache",
      headers: {
        "cache-control": "no-cache",
        "Content-Type": "application/json",
        "x-access-token": `${user.token}`,
      },
      body: JSON.stringify({
        deal_agents,
        agent_id,
        deal_id,
        add,
        remove,
        modify,
      }),
    });
    if (result?.data?.message) {
      yield put({
        type: HANDLE_AGENT_SUCCESS,
        payload: { message: result?.data?.message },
      });
      yield put({
        type: GET_DEALS,
        payload: { getUsers: true },
      });
      yield put(actions.change("alertMessage.message", result?.data?.message));
    } else {
      yield put({
        type: HANDLE_AGENT_ERROR,
        payload: { message: result?.data?.error },
      });
      yield put(actions.change("alertMessage.error", result?.data?.error));
    }
  } catch (error) {
    console.log(error);
    yield put({
      type: HANDLE_AGENT_ERROR,
      payload: { message: result?.data?.error },
    });
    yield put(actions.change("alertMessage.error", result?.data?.error));
  }
}

function* handleNDA(action) {
  const user = yield select(selectors.user);
  const { nda_agreement } = yield select(selectors.userActions);
  const { nda_file } = nda_agreement;
  const { populate, signed, deal, ndaSigned } = action.payload;
  let formData = new FormData();
  let result;
  try {
    result = yield call(request, "/deal/nda", {
      method: "POST",
      cache: "no-cache",
      headers: {
        "cache-control": "no-cache",
        "Content-Type": "application/json",
        "x-access-token": `${user.token}`,
      },
      body: JSON.stringify({
        nda_agreement,
        user_id: user?.id,
        deal_id: deal?.id,
        nda_id: ndaSigned?.id,
        populate,
        signed,
      }),
    });
    if (result?.data?.message) {
      let ndaUpload;
      if (nda_file) {
        formData.append(
          "files",
          new File(
            [nda_file],
            `nda_${ndaSigned?.first_name
              ?.toLowerCase()
              ?.replaceAll(" ", "_")}_${ndaSigned?.last_name
              ?.toLowerCase()
              ?.replaceAll(" ", "_")}_${ndaSigned?.email
              ?.toLowerCase()
              ?.replaceAll(".", "_")
              ?.replaceAll("@", "_")}.pdf`
          )
        );
        formData.append("projectName", JSON.stringify(deal?.name));
        formData.append("id", JSON.stringify(ndaSigned?.id));
        formData.append("folder", JSON.stringify("nda"));
        formData.append("email", JSON.stringify(user.email));
        ndaUpload = yield call(uploadFiles, formData, user);
      }
      let nda_documents = result?.data?.nda_documents;
      // eslint-disable-next-line array-callback-return
      nda_documents?.map((n) => {
        if (n?.id === ndaSigned?.id) {
          n.nda_url = ndaUpload.dataArr[0];
        }
      });
      yield put({
        type: HANDLE_NDA_SUCCESS,
        payload: {
          message: result?.data?.message,
          nda_documents,
        },
      });
      if (signed) {
        yield put({
          type: AUDIT,
          payload: {
            user_action: `NDA SIGNED`,
            deal_id: deal?.id,
          },
        });
      }
      // yield put(actions.change("alertMessage.message", result?.data?.message));
    } else {
      yield put({
        type: HANDLE_NDA_ERROR,
        payload: { message: result?.data?.error },
      });
      yield put(actions.change("alertMessage.error", result?.data?.error));
    }
  } catch (error) {
    console.log(error);
    yield put({
      type: HANDLE_NDA_ERROR,
      payload: { message: result?.data?.error },
    });
    yield put(actions.change("alertMessage.error", result?.data?.error));
  }
}

function* handleCustomLink(action) {
  const user = yield select(selectors.user);
  let result;
  try {
    result = yield call(request, "/deal/assign", {
      method: "POST",
      cache: "no-cache",
      headers: {
        "cache-control": "no-cache",
        "Content-Type": "application/json",
        "x-access-token": `${user.token}`,
      },
      body: JSON.stringify({
        user_id: user?.id,
        token: action.payload?.token,
      }),
    });
    if (result?.data?.message) {
      yield put({
        type: CUSTOM_LINK_SUCCESS,
        payload: {
          message: result?.data?.message,
        },
      });
      // yield put(actions.change("alertMessage.message", result?.data?.message));
    } else {
      yield put({
        type: CUSTOM_LINK_ERROR,
        payload: { message: result?.data?.error },
      });
      // yield put(actions.change("alertMessage.error", result?.data?.error));
    }
  } catch (error) {
    console.log(error);
    yield put({
      type: CUSTOM_LINK_ERROR,
      payload: { message: result?.data?.error },
    });
    // yield put(actions.change("alertMessage.error", result?.data?.error));
  }
}

function* handleAuditLogNotes(action) {
  const user = yield select(selectors.user);
  const { auditNotes } = action.payload;
  let result;
  try {
    result = yield call(request, "/admin/auditlogsnotes", {
      method: "POST",
      cache: "no-cache",
      headers: {
        "cache-control": "no-cache",
        "Content-Type": "application/json",
        "x-access-token": `${user.token}`,
      },
      body: JSON.stringify({
        auditNotes,
      }),
    });
    if (result?.data?.message) {
      yield put({
        type: HANDLE_AUDIT_NOTES_SUCCESS,
        payload: {
          message: result?.data?.message,
        },
      });
      // yield put(
      //   actions.change("alertMessage.message", "Notes saved successfully")
      // );
      yield put({
        type: GET_AUDIT,
        payload: {
          reload: false,
        },
      });
    } else {
      yield put({
        type: HANDLE_AUDIT_NOTES_ERROR,
        payload: { message: result?.data?.error },
      });
      // yield put(actions.change("alertMessage.error", result?.data?.error));
    }
  } catch (error) {
    yield put({
      type: HANDLE_AUDIT_NOTES_ERROR,
      payload: { message: result?.data?.error },
    });
    // yield put(actions.change("alertMessage.error", result?.data?.error));
  }
}

function* actionWatcher() {
  yield takeLeading(AUDIT, audit);
  yield takeLeading(GET_AUDIT, getAuditLog);
  yield takeLeading(CHECK_TOKEN, checkToken);
  yield takeLeading(APPROVE_USER, approveUser);
  yield takeLeading(DENY_USER, approveUser);
  yield takeLeading(GET_OPTIONS, getOptions);
  yield takeLeading(GET_IP, getIP);
  yield takeLeading(SIGNUP, signup);
  yield takeLeading(SEND_VERIFY_EMAIL, sendVerifyEmail);
  yield takeLeading(VERIFY_EMAIL, verifyEmail);
  yield takeLeading(LOGIN, login);
  yield takeLeading(LOGOUT, logout);
  yield takeLeading(SEND_PASSWORD_RESET, sendPasswordReset);
  yield takeLeading(VERIFY_PASSWORD_RESET, verifyPasswordReset);
  yield takeLeading(PASSWORD_RESET, passwordReset);
  yield takeLeading(GET_DEAL, getDeal);
  yield takeLeading(GET_DEALS, getDeals);
  yield takeLeading(CREATE_DEAL, createDeals);
  yield takeLeading(EDIT_DEAL, editDeals);
  yield takeLeading(DELETE_DEAL, deleteDeals);
  yield takeLeading(SEND_INTEREST, sendInterest);
  yield takeLeading(ADD_DOCUMENT_GROUP, handleDocumentGroups);
  yield takeLeading(REMOVE_DOCUMENT_GROUP, handleDocumentGroups);
  yield takeLeading(UPDATE_USER, updateUser);
  yield takeLeading(HANDLE_AGENT, handleAgent);
  yield takeLeading(HANDLE_NDA, handleNDA);
  yield takeLeading(CUSTOM_LINK, handleCustomLink);
  yield takeLeading(HANDLE_AUDIT_NOTES, handleAuditLogNotes);
}

export default function* rootSaga() {
  yield all([actionWatcher()]);
}
