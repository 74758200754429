import React, { Component } from "react";
import { connect } from "react-redux";
import { Row, Col, Button } from "react-bootstrap";
// import DealImage from "./img/dealImg.png";
import { formatCurrency } from "../utils";
import { AWS_S3_URL } from "../config";
import logo from "../../global/img/logo.png";
import "./style.css";

class Dashboard extends Component {
  render() {
    const { deal } = this.props;

    let image = deal?.images_url?.split(",") || [];

    // let folderName = deal?.creator_email.split(".");
    // folderName = folderName[folderName.length - 2];
    // folderName = folderName.replace("@", "_");
    // folderName = `${folderName.toLowerCase()}/project_name_${deal?.name.toLowerCase()}`;
    // folderName = folderName.replace(" ", "+");

    const folderName = `project_name_${deal?.name?.toLowerCase()}`;

    return (
      <div className="dealCardDiv">
        {image?.length ? (
          <img
            src={`${AWS_S3_URL}/${folderName}/${image[0]}`}
            alt="Deal"
            className="dealCardImg"
          />
        ) : (
          <div
            className="dealCardImg"
            style={{
              background: `url('${logo}')`,
              backgroundSize: image ? "cover" : "50%",
              backgroundRepeat: "no-repeat",
              backgroundPosition: "center",
            }}
          ></div>
        )}

        <h3 className="dealCardHeader robotoFontBold">{deal?.name}</h3>
        <p className="robotoFontRegular">
          {deal?.city}, {deal?.state}
        </p>
        <div className="dealCardInfoDiv">
          <Row className="dealCardInfoRow">
            <Col lg={12} className="dealCardInfoCol">
              <span className="dealCardInfoText dealCardInfoTextLeft robotoFontRegular">
                List Price
              </span>
              <span className="dealCardInfoText dealCardInfoTextRight">
                {deal?.list_price
                  ? formatCurrency(deal?.list_price)
                  : "Unpriced"}
              </span>
            </Col>
          </Row>
          <Row className="dealCardInfoRow">
            <Col lg={12} className="dealCardInfoCol">
              <span className="dealCardInfoText dealCardInfoTextLeft robotoFontRegular">
                Property GLA
              </span>
              <span className="dealCardInfoText dealCardInfoTextRight">
                {deal?.property_gla}
              </span>
            </Col>
          </Row>
          <Row className="dealCardInfoButtonRow">
            <Col lg={12} className="dealCardInfoButtonCol">
              <Button
                href={`/dashboard/deal/${deal?.id}`}
                className="generalButton dealCardInfoBtn"
              >
                LEARN MORE
              </Button>
              {/* <p
                className={`nonDisclosureText robotoFontBold ${
                  parseInt(deal?.nda_required) ? "" : "invisible"
                }`}
              >
                NON-DISCLOSURE AGREEMENT
              </p> */}
            </Col>
          </Row>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  options: state.options,
});

export default connect(mapStateToProps)(Dashboard);
