/* eslint-disable react/jsx-pascal-case */
import React, { Component } from "react";
import { connect } from "react-redux";
import { Control, Form, actions } from "react-redux-form";
import { Row, Col } from "react-bootstrap";
import InputFields from "../InputFields";
// import ThankYou from "../ThankYou";
import NavbarHeader from "../Navbar";
import Loading from "../Loading";
import PrivacyPolicy from "../../global/pdf/privacy-policy.pdf";
// import ConfidentialityAgreement from "../../global/pdf/confidentiality-agreement.pdf";
import "./style.css";

class Signup extends Component {
  handleSignUp() {
    const { signup, dispatch } = this.props;
    let agreementsValid = false;
    for (var s in signup?.agreements) {
      if (signup?.agreements[s] === true) {
        agreementsValid = true;
      } else {
        agreementsValid = false;
      }
    }
    dispatch(actions.change("signup.agreement_form_signed", agreementsValid));
    dispatch({ type: "SIGNUP" });
  }
  render() {
    const { user, signup, options } = this.props;
    if (user?.login && user?.email_verified && user?.user_verified) {
      window.location.href = "/dashboard";
    } else if (user?.login && (!user?.email_verified || !user?.user_verified)) {
      window.location.href = "/thankyou";
    }
    let agreementsValid = false;
    for (var s in signup?.agreements) {
      if (
        signup?.agreements[s] === true &&
        Object.keys(signup?.agreements).length === 1
      ) {
        agreementsValid = true;
      } else {
        agreementsValid = false;
      }
    }

    if (signup?.isLoading) {
      return <Loading />;
    } else if (signup?.success) {
      // return <ThankYou signupPage={true} />;
    }

    const passwordValid =
      signup?.password &&
      signup?.confirmPassword &&
      signup?.password === signup?.confirmPassword;

    const disable = !agreementsValid || !signup?.phone || !passwordValid;

    return (
      <div className="mainContainer registrationMainDiv">
        <div id="redBorderLine"></div>
        <NavbarHeader />
        <Form model="signup" onSubmit={() => this.handleSignUp()}>
          <Row className="registrationRow">
            <Col lg={6}>
              <h1 className="generalHeader">Investor Registration</h1>
            </Col>
            <Col lg={6}>
              <a
                href="/login"
                className="registrationLoginLink robotoFontRegular"
              >
                Already have an account?
              </a>
            </Col>
          </Row>
          <Row className="registrationRow">
            <Col lg={6} className="registrationCol registrationCol1">
              <Col lg={12} className="inputCol">
                <InputFields
                  label="first name"
                  type="text"
                  inputType="text"
                  model=".first_name"
                />
              </Col>
              <Col lg={12} className="inputCol">
                <InputFields
                  label="last name"
                  type="text"
                  inputType="text"
                  model=".last_name"
                />
              </Col>
              <Col lg={12} className="inputCol">
                <InputFields
                  label="company"
                  type="text"
                  inputType="text"
                  model=".company"
                />
              </Col>
              <Col lg={12} className="inputCol">
                <InputFields
                  label="title"
                  type="text"
                  inputType="text"
                  model=".title"
                  ignore={true}
                />
              </Col>
              <Col lg={12} className="inputCol">
                <InputFields
                  label="street"
                  type="text"
                  inputType="text"
                  model=".street"
                />
              </Col>
              <Col lg={12} className="inputCol">
                <InputFields
                  label="city"
                  type="text"
                  inputType="text"
                  model=".city"
                />
              </Col>
              <Col lg={12} className="inputCol">
                <p className="selectLabel">
                  State<span className="requiredField">*</span>
                </p>
                <InputFields
                  label="State"
                  defaultOption="Choose a state"
                  type="select"
                  customLabel={true}
                  inputType="select"
                  model=".state"
                  options={options?.states || []}
                />
              </Col>
              <Col lg={12} className="inputCol">
                <InputFields
                  label="zipcode"
                  type="text"
                  inputType="text"
                  model=".zipcode"
                />
              </Col>
            </Col>
            <Col lg={6} className="registrationCol registrationCol2">
              <Col lg={12} className="inputCol">
                <p className="selectLabel">
                  I am...<span className="requiredField">*</span>
                </p>
                <InputFields
                  label="answer"
                  defaultOption="Choose an option"
                  type="select"
                  customLabel={true}
                  inputType="select"
                  model=".investor_type"
                  options={options?.investment_types || []}
                />
              </Col>
              <Col lg={12} className="inputCol">
                <InputFields
                  label="country"
                  type="text"
                  inputType="text"
                  model=".country"
                />
              </Col>
              <Col lg={12} className="inputCol">
                <InputFields
                  label="website"
                  type="text"
                  inputType="text"
                  model=".website"
                  ignore={true}
                />
              </Col>
              <Col lg={12} className="inputCol">
                <InputFields
                  label="phone"
                  type="tel"
                  inputType="customPhone"
                  model="signup.phone"
                  // standardLabel={true}
                />
              </Col>
              <Col lg={12} className="inputCol">
                <InputFields
                  label="email"
                  type="email"
                  inputType="text"
                  model=".email"
                />
              </Col>
              <Col lg={12} className="inputCol">
                <InputFields
                  label="password"
                  type="password"
                  inputType="text"
                  model=".password"
                />
              </Col>
              <Col lg={12} className="inputCol">
                <InputFields
                  label="confirm password"
                  type="password"
                  inputType="text"
                  model=".confirmPassword"
                />
                {signup?.password?.length >= 6 &&
                signup?.confirmPassword?.length >= 6 &&
                signup?.password !== signup?.confirmPassword ? (
                  <p className="robotoFontRegular" style={{ color: "red" }}>
                    Passwords do not match
                  </p>
                ) : null}
              </Col>
            </Col>
            <Col lg={12}>
              <h1 className="generalHeader agreementHeader">Agreement</h1>
            </Col>
            <Col lg={12} className="agreementCol robotoFontRegular">
              <InputFields
                label="I accept the terms of this agreement, the Terms of Use, the Privacy Policy, and the Confidentiality Agreement"
                inputType="checkbox"
                model=".agreements.agree3"
                checkboxLabel={
                  <>
                    I accept the terms of this agreement and the{" "}
                    <a
                      href={PrivacyPolicy}
                      className="anchorLink robotoFontBold"
                      title="Privacy Policy"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Privacy Policy
                    </a>
                    {/* , and the{" "}
                    <a
                      href={ConfidentialityAgreement}
                      className="anchorLink robotoFontBold"
                      title="Privacy Policy"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Confidentiality Agreement
                    </a> */}
                  </>
                }
                value="agree3"
                standardLabel={true}
              />
            </Col>
            <Col lg={12}>
              <Control.button
                model=".signup"
                className="generalButtonInverted"
                style={{ marginTop: "30px" }}
                type="submit"
                disabled={disable || { valid: false }}
              >
                SUBMIT
              </Control.button>
            </Col>
          </Row>
        </Form>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  options: state.options,
  user: state.user,
  signup: state.signup,
});

export default connect(mapStateToProps)(Signup);
